.findings-risk-page-main-container {
  background-color: #fff;
  padding: 0.8rem 2rem;

  .findings-risk-page-main-table {
    height: 350px;

    .findings-table-view {
      height: 260px;
    }
  }
}
