.dashboard-business-priority-chip-container {
  font-family: 'HEX Franklin v0.2';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  border: 1px solid $green-5;
  color: $green-3;
  background: $green-6;
  padding: 5px 6px;
  border-radius: 4px;
}

.dashboard-business-priority-chip-Low-container {
  border: none !important;
  color: $green-3;
  background: $green-6;
}

.dashboard-business-priority-chip-Moderate-container {
  border: none !important;
  color: $orange-3;
  background: $orange-6;
}

.dashboard-business-priority-chip-High-container {
  border: none !important;
  color: $red-3;
  background: $red-6;
}

.dashboard-business-priority-chip-Urgent-container {
  color: $red-2;
  background: $red-6;
  border: none !important;
}
