.icon-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  .icon-box-text {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;

    .icon-box-label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: $grey-1;
    }

    .icon-box-helper-text {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: $grey-3;
    }
  }
}
