.risk_assessment_surface_table_widget {
  height: 387px !important;
  min-height: 322px !important;
  margin-bottom: 20px;

  .widgetArea {
    padding: 0;
  }
  .widgetContainer {
    padding: 17px 20px;
  }
  .cell-chart-widget {
    // margin: 0 20px;
    .base-chart-main-container {
      height: 50px;
      width: 242px;
    }
  }
}
