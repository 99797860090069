.add-comment {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid $grey-4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  min-height: 84px;
  font-size: 13px;
  line-height: 16px;
}

.add-comment::placeholder {
  color: $grey-4;
}

.activity-container {
  height: 100%;
  max-width: 720px;
}
.add-comment-container {
  position: relative;
}

.comment-indicator:not(.default-indicator) {
  width: 8px;
  height: 8px;
  background: #ffffff;
  border: 1px solid $grey-4;
  border-radius: 50%;
  position: absolute;
  left: -20px;
  top: 28px;
}
.default-indicator {
  top: 8px;
  width: 8px;
  height: 8px;
  background: #ffffff;
  border: 1px solid $grey-4;
  border-radius: 50%;
  position: absolute;
  left: -20px;
}

.activity-item {
  position: relative;
  padding-top: 20px;
}

.event-content {
  margin-left: 10px;
}

.event-date {
  color: $grey-3 !important;
  font-size: 13px !important;
  margin-left: 8px !important;
}

.actor-logo-container {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  object-position: center;
}

.system-logo-image {
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.event-text {
  font-size: 13px !important;
  color: $grey-1 !important;
  font-weight: 500 !important;
}

.user-comment {
  display: inline-block;
  background: $grey-6;
  border-radius: 0 8px 8px 8px;
  padding: 10px;
  margin-top: 16px !important;
  white-space: pre-wrap;
}
