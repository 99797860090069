.campaign-work-breakdown-widget-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .campaign-work-breakdown-widget-title {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      text-align: center;
      color: #5c5c5c;
    }
  }

  .campaign-label {
    text-align: initial;
  }

  .campaign-work-breakdown-widget-body {
    width: 100%;
    text-align: center;
    align-items: center;
    display: flex;

    .icon-widget-breakdown {
      fill: black;
    }

    .campaign-work-breakdown-widget-body-item {
      margin-bottom: 0px;
      border: 1px solid #e2e2e2;
      border-radius: 8px;
      padding: 12px;
      height: 160px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:not(:last-of-type) {
        margin-right: 10px;
      }

      .campaign-widget-item-icon {
        text-align: initial;
        width: 28px;
        height: 28px;
        background: #f5f5f5;
        border-radius: 8px;
        align-items: center;
        display: flex;
        justify-content: center;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      .campaign-widget-item-data {
        text-align: initial;
      }

      .campaign-info {
        margin-top: 9px;
        line-break: strict;
      }
    }
  }
}
