.dashboard-table-widget {
  .widgetContainer {
    height: inherit;
    width: 100% !important;

    .title {
      font-weight: 800;
      font-size: 16px;
      line-height: 16px;
    }
  }

  p {
    font-family: 'HEX Franklin v0.2';
    font-style: normal;
    color: $grey-1;
  }

  .MuiDataGrid-row--lastVisible {
    .MuiDataGrid-cell {
      border-bottom: 0 !important;
    }
  }

  .owner-details {
    display: inline-flex;
    align-items: center;

    .avatar-container {
      width: 28px;
      height: 28px;
      margin-right: 10px;
      padding-left: 1.3px;
      background: $white;
      border: 1px solid #e2e2e2;
      border-radius: 8px;
    }
  }

  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .data-grid-table-column-title {
    font-family: 'HEX Franklin v0.2';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $grey-1;
  }

  .chip-cell {
    .risk-score-chip {
      width: 24px;
      height: 24px;

      .MuiChip-label {
        padding: 0;
      }
    }
  }

  .action-button {
    font-family: 'HEX Franklin v0.2';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    padding-right: 0 !important;
    height: 55px;
  }

  .data-grid-table-container {
    border: 0 !important;

    .data-grid-table-column-header-container {
      background: $grey-7 !important;
      border-top-left-radius: 12px !important;
      border-bottom-left-radius: 0 !important;
      border-top-right-radius: 12px !important;
      border-bottom-right-radius: 0 !important;
      border: 1px solid $grey-5 !important;

      .data-grid-table-column-title {
        font-family: 'HEX Franklin v0.2';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        color: $grey-1;
      }
    }
  }
}
