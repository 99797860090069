.risk-assessment-dashboard-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 20px;

  .widget-divider {
    width: 100%;
    position: relative;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiDivider-root {
      height: 193px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin: 0 !important;
    }
  }
  .widget-container {
    display: flex;
    justify-content: space-between;
  }

  .risk-assessment-dashboard-card {
    padding: 22px 20px;
    min-height: 160px;

    .risk-assessment-dashboard-card-container {
      row-gap: 22px;

      .title {
        cursor: pointer;
      }

      .applications-list {
        min-height: 52px;
        display: flex;
        align-items: center;
      }

      .risk-assessment-card:last-child {
        margin-right: 0;
      }

      .title-icon {
        svg {
          path {
            fill: $grey-1;
          }
        }
      }
      .more-app-button {
        width: 24px;
        height: 22px;
        border-radius: 8px;
        border: 1px solid $grey-5;
        color: $grey-2;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .application-logo-container {
        width: 40px;
        height: 40px;
        padding: 3px !important;
        border-radius: 16px !important;
        display: flex;
        align-items: center;
        justify-content: center;

        .application-logo {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.risk-app-tooltip {
  margin: 10px;
  row-gap: 8px;
  display: flex;
  max-width: 180px;
  .risk-app-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    p {
      color: $grey-1;
    }

    .application-logo {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
