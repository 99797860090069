.common-form-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .common-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .common-form-label-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #3d3d3d;
    }

    .common-form-error-text {
      color: $red-3;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }

    .common-form-name-label {
      margin-bottom: 0.5rem;
    }

    .common-form-checkbox-control {
      display: flex;
      flex-direction: row;
      align-items: center;

      * {
        font-family: 'HEX Franklin v0.2';
        color: $grey-1;
      }
    }

    .common-form-input {
      input {
        padding: 11px 14px !important;
        background: #ffffff !important;
        border: 1px solid $grey-4 !important;
        border-radius: 4px !important;

        &::placeholder {
          font-style: normal !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 14px !important;
          color: $grey-3 !important;
        }
      }

      fieldset {
        display: none !important;
      }
    }

    .common-form-select {
      .MuiInputBase-root {
        background: #ffffff !important;
        border: 1px solid $grey-4 !important;
        border-radius: 4px !important;
      }

      fieldset {
        display: none !important;
      }

      .MuiChip-root {
        height: 24px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: $grey-1;

        img {
          height: 16px;
          width: 16px;
          border-radius: 50%;
        }
      }

      input {
        padding: 6px !important;
      }
    }

    .common-form-date-picker {
      .MuiTypography-body1 {
        font-family: 'HEX Franklin v0.2';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: $grey-1;
      }

      .MuiLink-root {
        color: $grey-3;
      }

      input {
        padding: 11px 14px !important;
        background: #ffffff !important;
        border: 1px solid $grey-4 !important;
        border-radius: 4px !important;

        &::placeholder {
          font-style: normal !important;
          font-weight: 400 !important;
          font-size: 14px !important;
          line-height: 14px !important;
          color: $grey-3 !important;
        }
      }

      .date-picker-input-outline {
        display: none !important;
      }

      .date-picker-input-icon {
        padding-right: 0.5rem;
      }
    }
  }

  .common-form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    .common-form-primary-button {
      background: $purple-3 !important;
      border: 1px solid $purple-3 !important;
      box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05) !important;
      border-radius: 6px !important;
      font-family: 'HEX Franklin v0.2' !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      color: #ffffff !important;
    }
  }
}
