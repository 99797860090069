.sidebar {
  background-color: #2b1470;
  /* width: 5rem; */
  display: flex;
  align-items: flex-start;
}

.sidebarMenu {
  /* align-items: center; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.sidebarContainer {
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  position: sticky !important;
  top: 0 !important;
  height: 100vh !important;
  width: 81px !important;
  min-width: 81px !important;
}
