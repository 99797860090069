.avatar-chip-container {
  background: #ffffff;
  border: 1px solid $grey-8;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  min-height: 24px;
  padding: 0px 8px;

  .avatar-chip-icon {
    height: 16px;
    width: 16px;
    background: #d9d9d9;
    border-radius: 50%;
    overflow: hidden;

    img {
      height: 16px;
      width: 16px;
    }
  }

  .avatar-chip-label {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: #101828;
  }
}
