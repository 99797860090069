.integration-item {
  width: auto;
  height: 148px !important;
  position: relative;
  padding: 15px !important;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .active-integrations {
    width: 47px;
    height: 27px;
    margin: 0;
    border-color: $purple-6 !important;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2px 4px;
    gap: 3px;
    p {
      color: $purple-3;
    }

    path {
      fill: $purple-3;
    }
  }

  .integration-logo-container {
    width: 72px;
    height: 72px;
    border-radius: 30% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .integration-name {
    .header-6 {
      font-weight: 588;
    }
  }
}

.integration-item.integration-disabled {
  background: $grey-7;
  cursor: not-allowed;

  .integration-logo-container img {
    filter: grayscale(100%);
  }

  .active-integrations {
    border-color: $grey-5 !important;
    p {
      color: $grey-3 !important;
    }

    path {
      fill: $grey-3 !important;
    }
  }
}

.integrations-container {
  padding: 35px 40px !important;
  width: 100%;
}
