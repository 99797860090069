.integrations-page-box {
  padding: 30px;

  * {
    font-family: 'HEX Franklin v0.2';
  }

  .integrations-page-container {
    display: flex;
    gap: 30px;
    flex-direction: row;
    flex-wrap: wrap;

    .integrations-page-side-menu-container {
      width: 310px;

      .side-menu-item {
        &.active {
          p {
            color: $purple-3 !important;
          }
        }
        max-height: 56px;

        .title {
          font-size: 14px;
          font-style: normal;
          font-weight: 588;
          line-height: 16px;
        }
      }
    }

    .integrations-page-items-container {
      overflow: auto;
      flex: 1;

      .integrations-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .category-section {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .category-section-header {
            color: #3d3d3d;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            margin: 0;
          }

          .category-section-applications {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
          }

          .category-section-application-card {
            width: 232px;
          }
        }
      }
    }
  }
}
