.input-label-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .input-label-text {
    display: flex;
    gap: 0.2rem;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    align-items: center;

    .input-label {
      color: $grey-1;
    }
  }

  .input-label-end-icon {
    margin-left: 0.5rem;
  }

  .input-label-helper-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $grey-3;
  }

  .input-label-body {
    display: flex;
    gap: 0.4rem;
    flex-direction: column;
    .auto-complete-container {
      p {
        display: none !important;
      }
    }
  }
}

.input-label-optional {
  color: $grey-3;
  font-size: 14px;
}
