.history-flows-page-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .history-flows-page-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 24px;
    gap: 22px;

    .history-flows-page-body-filters {
      border-radius: 8px;
      border: 1px solid #e2e2e2;
      background: var(--white, #fff);
      padding: 12px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .history-flows-page-body-table {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 27px;
    }
  }
}
