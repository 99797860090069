.multi-select-configuration {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .multi-select-configuration-add-button {
    height: 20px;
    display: flex;
    align-items: center;
  }

  .multi-select-configuration-row {
    display: flex;
    align-items: center;
    gap: 1rem;

    .multi-select-field-1 {
      flex: 1;
    }

    .multi-select-configuration-button {
      align-self: flex-end;
    }
  }
}

.multi-select-configuration-button {
  display: flex;
  gap: 0.3rem;
  padding: 0 !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 14px !important;
  line-height: 14px;
  align-items: center;

  svg {
    height: 14px;
    width: 14px;
  }

  &:disabled {
    color: $grey-4 !important;

    svg {
      path {
        fill: $grey-4 !important;
      }
    }
  }
}

.delete-button {
  border-radius: 6px !important;
  border: 1px solid var(--Grey-5, #ebeaea) !important;
  background: var(--White, #fff) !important;
  display: flex !important;
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
  padding: 10px 16px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px;
  flex-shrink: 0;

  svg {
    height: 13px;
    width: 13px;

    path {
      fill: var(--Grey-1, #3d3d3d) !important;
    }
  }

  &:disabled {
    background: #f5f5f5 !important;
    svg {
      path {
        fill: #c7c4c4 !important;
      }
    }
  }
}
