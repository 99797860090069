.finding-widget-container {
  background: #ffffff;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 400px;

  .owner-widget-logo {
    width: 100px;
  }

  .finding-widget-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;

    .finding-widget-header-title {
      color: #0a0b50;
    }
  }

  .finding-widget-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    .copyButton {
      position: absolute;
      right: 0;
    }

    .cm-editor,
    .cm-theme {
      max-width: 95%;
      min-width: 90%;
    }

    .cm-scroller,
    .cm-gutters {
      min-height: 265px !important;
      max-width: 25vw;
    }

    .cm-line {
      font-size: 14px;
    }

    .cm-content {
      flex-basis: auto !important;
    }

    .finding-widget-body-details {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 1rem;
      min-height: 150px;

      .finding-widget-body-cell {
        background: #ffffff;
        border: 1px solid #cdd7e2;
        border-radius: 5px;
        padding: 0.7rem 0.5rem;
        min-width: 140px;
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }

    .finding-widget-body-profile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      min-width: 30%;

      .finding-widget-body-profile-avatar {
        width: 82px;
        height: 82px;
      }

      .finding-widget-body-profile-title {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #101828;
        margin-top: 15px;
      }

      .finding-widget-body-profile-subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #0a0b50;
      }
    }

    .finding-widget-body-table-container {
      min-width: 60%;

      .finding-widget-body-table {
        td {
          border-bottom: none;
          position: relative;
          padding-left: 0rem;

          img {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(100%);
          }
        }

        .finding-widget-body-table-bold-cell {
          font-weight: 700;
          color: #0a0b50 !important;
        }

        .finding-widget-body-table-text-cell {
          font-style: normal;
          font-size: 14px;
          color: #101828;
          max-width: 130px;
        }
      }
    }
  }
}

.finding-widget-grid {
  flex-basis: 50%;
  max-width: 50%;
}

@media screen and (min-width: 1200px) and (max-width: 1800px) {
  .finding-widget-grid {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }

  .finding-widget-body-table-text-cell {
    max-width: 60vw !important;
  }
}
