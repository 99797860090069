#menuButton {
  height: 32px;
  width: 32px;
  min-width: 32px;
  padding: 0;
  border: 1px solid #ebeaea;
  border-radius: 6px;
}

#buttonText {
  color: #3d3d3d;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  min-width: 100px;
}
