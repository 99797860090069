.dashboard-page-header {
  @include page-header;
}

.dashboard-page-header-side-element {
  @include page-header;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
