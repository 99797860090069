.finding-workflow-panel-container {
  padding: 20px !important;
  padding-right: 9px !important;
  position: absolute;
  right: 10px;
  bottom: 11px;
  z-index: 200;
  width: 35% !important;
  max-height: 80% !important;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05) !important;
  border: 1px solid var(--Grey-5, #ebeaea);
  display: flex;
  flex-direction: column;

  .finding-workflow-panel-header {
    margin-bottom: 1rem;
    width: 100%;

    h2 {
      color: var(--Grey-1, #3d3d3d);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin: 0px;
    }

    .finding-workflow-panel-header-close-icon {
      padding-right: 0.3rem;
    }

    .finding-workflow-crossroad-icon {
      path {
        fill: #5c5c5c;
      }
    }
  }

  .finding-workflow-panel-action-details {
    padding: 10px;
    background: var(--Grey-7, #f9f9f9);
    border: 1px solid var(--Grey-5, #ebeaea);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.4rem;
    width: 100%;

    .finding-workflow-action-details-parameters {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .finding-workflow-action-details-parameters-logo {
        border-radius: 12px;
        border: 1px solid var(--Grey-5, #ebeaea);
        background: #fff;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 24px;
        }
      }

      .finding-workflow-action-details-parameters-text {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;

        .active-tool-title {
          color: var(--Grey-1, #3d3d3d);
          font-size: 14px;
          font-style: normal;
          font-weight: 588;
          line-height: 14px;
        }

        .active-tool-content {
          color: var(--Grey-3, #7f7f7f);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px;
        }
      }
    }

    .finding-workflow-action-details-indicator {
      border-radius: 4px;
      border: 1px solid var(--Grey-5, #ebeaea);
      background: #fff;
      color: var(--Grey-1, #3d3d3d);
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 13px;
      padding: 5px 6px;
    }
  }
  .finding-workflow-panel-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1rem;
    overflow: auto;
    width: 100%;

    .finding-workflow-panel-body-parameters {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .finding-workflow-panel-body-parameter {
        display: grid;
        grid-template-columns: 150px auto;

        .finding-workflow-panel-body-parameter-title {
          color: var(--Grey-1, #3d3d3d);
          font-size: 14px;
          font-style: normal;
          font-weight: 588;
          line-height: 14px;
        }

        .finding-workflow-panel-body-parameter-value {
          word-break: break-all;
          color: #3d3d3d;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }
      }
    }

    .finding-workflow-panel-results > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      p {
        color: var(--Grey-1, #3d3d3d);
        font-size: 14px;
        font-style: normal;
        font-weight: 588;
        line-height: 14px;
      }
    }

    .finding-workflow-panel-body-crossroad {
      display: flex;
      flex-direction: column;
      gap: 1.3rem;

      .finding-workflow-panel-body-crossroad-path {
        border: 1px solid var(--Grey-5, #ebeaea) !important;
        box-shadow: none !important;
        border-radius: 10px !important;
        width: 100%;
        overflow: hidden;

        &::before {
          display: none !important;
        }
      }

      .finding-workflow-panel-body-crossroad-summary {
        .MuiAccordionSummary-content {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        .finding-workflow-panel-body-crossroad-summary-title {
          color: var(--Grey-1, #3d3d3d);
          font-size: 16px;
          font-style: normal;
          font-weight: 588;
          line-height: 16px;
        }
      }

      .MuiAccordion-region {
        display: flex;
        justify-content: center;
      }

      .MuiCollapse-wrapper {
        background: #ffffff;
      }

      .finding-workflow-panel-body-crossroad-details {
        display: flex;
        flex-direction: column;
        width: 100%;

        .finding-workflow-crossroad-condition-sub-list {
          display: flex;
          flex-direction: column;
          gap: 13px;

          .finding-workflow-crossroad-condition-parameter {
            color: #3d3d3d;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
          }

          .finding-workflow-crossroad-condition-operator {
            color: #3d3d3d;
            font-size: 14px;
            font-style: normal;
            font-weight: 588;
            line-height: 14px;
          }

          .finding-workflow-crossroad-condition-and-operator {
            border-radius: 4px;
            width: 100%;
            background-color: var(--Grey-6, #f5f5f5);
            padding: 4px;
            color: var(--Grey-1, #3d3d3d);
            font-size: 13px;
            font-style: normal;
            font-weight: 588;
            line-height: 16px;
            text-align: center;
          }

          .finding-workflow-crossroad-condition-or-operator {
            border-radius: 4px;
            width: 100%;
            background-color: var(--Grey-6, #f5f5f5);
            padding: 4px 10px;
            color: var(--Grey-1, #3d3d3d);
            font-size: 13px;
            font-style: normal;
            font-weight: 588;
            line-height: 16px;
            text-align: center;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;

            .finding-workflow-crossroad-condition-or-operator-line {
              height: 1px;
              background-color: var(--Grey-4, #c7c4c4);
              flex: 1;
            }
          }
        }
      }
    }
  }
}
