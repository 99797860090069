.category-dropdown {
  .category-dropdown-title-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .category-dropdown-back-button {
      justify-self: start;
    }

    .category-dropdown-title {
      color: var(--Gray-6, #3d3d3d);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }
}

.filter-chip-group-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  .add-filter-button {
    color: var(--Grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: var(--rounded-md, 6px);
    border: 1px solid var(--Grey-5, #ebeaea);
    background: var(--Grey-7, #f9f9f9);
    box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 36px;

    &:hover {
      border-radius: var(--rounded-md, 6px);
      border: 1px solid var(--Grey-5, #ebeaea);
      background: var(--Grey-5, #ebeaea);
      box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
    }

    .MuiTouchRipple-root {
      display: none !important;
    }

    svg {
      width: 10px;
      path {
        fill: var(--Grey-1, #3d3d3d);
      }
    }
  }

  .filter-chip {
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid var(--Grey-5, #ebeaea);
    background: var(--Grey-7, #f9f9f9);
    box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
    cursor: pointer;

    &:hover {
      border-radius: var(--rounded-md, 6px);
      border: 1px solid var(--Grey-5, #ebeaea);
      background: var(--Grey-5, #ebeaea);
      box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
    }

    .filter-chip-text {
      display: flex;
      align-items: center;
      gap: 2px;

      .filter-chip-label {
        color: var(--Grey-3, #7f7f7f);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .filter-chip-value {
        color: var(--Grey-1, #3d3d3d);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        max-width: 75px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .filter-chip-icons {
      gap: 4px;
      display: flex;
      align-items: center;

      .filter-chip-remove-icon,
      .filter-chip-dropdown-icon {
        svg {
          width: 10px;
          path {
            fill: var(--Grey-3, #7f7f7f);
          }
        }
      }
    }
  }
}

.category-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 8px;
  max-height: 44px;
  cursor: pointer;

  .category-dropdown-item-start-area {
    display: flex;
    align-items: center;
    gap: 8px;

    .common-checkbox {
      padding: 0 !important;
    }
  }

  .category-dropdown-item-end-area {
    display: flex;
    align-items: center;
    gap: 20px;

    .category-dropdown-item-selection-counter {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      border: 1px solid var(--Purple-6, #ece7f9);
      background: var(--Purple-6, #ece7f9);
      color: var(--Purple-3, #7547dc);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 588;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:hover {
    background: var(--Grey-7, #f9f9f9) !important;
  }
}

.category-dropdown-item-active {
  background: var(--Grey-6, #f5f5f5) !important;
}

.category-dropdown-title-container {
  position: relative;
  text-align: center;
  padding: 12px;

  .category-dropdown-back-button {
    position: absolute;
    left: 2px;
  }
}

.category-dropdown {
  .MuiPopover-paper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 4px !important;
    background: var(--White, #fff);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    padding: 8px !important;
    min-width: 300px;
    max-height: 400px;
    overflow: auto;
  }
}

.filter-content-dropdown {
  .MuiPopover-paper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 4px !important;
    background: var(--White, #fff) !important;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    padding: 8px !important;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    max-height: 400px;
    overflow: auto;
  }
}

.filter-content-key-value-dropdown {
  .MuiPopover-paper {
    min-width: 500px;
  }
}

.option-list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .filter-input {
    width: 100%;

    .MuiInputBase-root {
      width: 100%;
    }
  }

  .option-list-loading,
  .option-list-no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 240px;
  }

  .option-list-item {
    padding: 12px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: var(--Grey-7, #f9f9f9) !important;
    }

    .option-list-item-label {
      width: 100%;
      .common-checkbox {
        padding: 0;
      }
      display: flex;
      align-items: center;
      gap: 8px;
      max-width: 300px;

      .item-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .option-list-item-text {
          max-width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .option-list-item-count {
          color: var(--Grey-3, #7f7f7f);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }
      }
    }
  }
}

.filter-title-container {
  position: relative;
  text-align: center;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  .filter-go-back-button {
    position: absolute;
    left: 2px;
  }

  .filter-title {
    color: var(--Gray-6, #3d3d3d);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
}

.date-picker-container {
  .date-picker-body {
    .flatpickr-input {
      width: 100%;
    }
  }
}

.number-range-input {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .number-range-input-slider {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    gap: 20px;

    .number-range-input-value {
      border-radius: 4px;
      border: 1px solid var(--Grey-5, #ebeaea);
      min-width: 32px;
      max-width: 32px;
      min-height: 32px;
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      &:focus {
        border: 1px solid var(--Grey-5, #ebeaea);
        outline: none !important;
      }
    }
  }
}

.range-slider {
  padding: 0 !important;
  height: 8px !important;

  .rc-slider-rail,
  .rc-slider-track,
  .rc-slider-step {
    height: 8px;
    border-radius: 4px;
  }

  .rc-slide-rail {
    background-color: #ebebef !important;
  }

  .rc-slider-track {
    background: var(--Purple-3, #7547dc);
  }

  .range-slider-handle {
    width: 22px;
    height: 22px;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.08));
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid var(--Neutral-200, #ebebef);
    position: absolute;
    transform: translateY(-7px) translateX(-50%) !important;
  }
}
