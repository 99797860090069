.business-unit-view-panel-loading {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.business-unit-view-panel-header-menu-button {
  border-radius: 6px;
  border: 1px solid #ebeaea;
  min-height: 32px;
  min-width: 32px;
  padding: 0;
}

.business-unit-view-panel {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: $white;
  overflow: auto;
  height: 100%;

  .business-unit-view-panel-content-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-bottom: 1px solid $grey-5;
    padding-bottom: 1rem;
  }

  .business-unit-view-panel-icon-container {
    border: 1px solid $grey-5;
    border-radius: 8px;
    padding: 0.3em 0.5rem;

    .business-unit-view-panel-icon {
      height: 14px;
      width: auto;
    }
  }

  .business-unit-view-panel-header {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: start;
    align-items: center;
    gap: 0.4rem;

    .business-unit-view-panel-title {
      display: flex;
      align-items: center;
      gap: 1rem;

      .business-unit-view-panel-title-text {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: $grey-1;
      }

      .business-unit-view-panel-impact {
        .MuiGrid-item {
          svg {
            color: $grey-3;

            path {
              fill: $grey-3;
            }
          }

          &::before {
            content: '\2022';
            color: $grey-4;
            margin-left: 9px;
          }
        }
      }
    }

    .business-unit-view-panel-title-helper-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $grey-1;
    }

    .custom-root {
      height: 34px;
      width: 34px;
    }
  }

  .business-unit-view-panel-body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .business-unit-view-panel-section {
    gap: 0.5rem;

    .business-unit-view-panel-section-icon {
      background: $grey-5;
      width: 24px;
      height: 24px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .content-section-header {
      gap: 0.5rem;

      .content-section-title {
        font-style: normal;
        font-weight: 588;
        font-size: 16px;
        line-height: 16px;
        color: $grey-1;
      }
    }

    .content-section-body {
      padding: 1rem;
      border: 1px solid $grey-5;
      border-radius: 8px;
    }

    .business-unit-view-panel-section-body {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .business-unit-view-panel-sub-section {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    .content-section-header {
      .content-section-title {
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 13px;
        color: $grey-1;
      }
    }

    .content-section-body {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
    }
  }

  .business-unit-view-panel-sub-section-disabled {
    .content-section-header {
      .content-section-title {
        color: $grey-3;
      }
    }
  }

  .business-unit-view-panel-sub-section-icon-disabled {
    svg {
      path {
        fill: $grey-3;
      }
    }
  }

  .business-unit-view-panel-remove-body {
    .content-section-body {
      display: none;
    }
  }

  .business-unit-view-panel-sub-section-no-border {
    .content-section-body {
      padding: 0;
      border: none;
    }
  }

  .business-unit-view-panel-sub-section-bottom-border {
    .content-section-body {
      padding-bottom: 1rem;
      border-bottom: 1px solid $grey-5;
      border-radius: 0px;
    }
  }

  .business-unit-view-panel-code-repo {
    .business-unit-view-panel-code-repo-container {
      width: 100%;
      word-break: break-all;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      flex-wrap: wrap;

      .interactive-label {
        width: max-content !important;
      }
    }
  }

  .business-unit-view-panel-cloud-environments {
    .business-unit-view-panel-cloud-environments-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }

  .business-unit-view-panel-sub-section-risk-attributes {
    .content-section-body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      column-gap: 20px;
    }
  }

  .business-unit-view-panel-stakeholders {
    .business-unit-view-panel-stakeholders-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;

      .icon-box:not(:last-child) {
        border-right: 1px solid $grey-5;
        margin-right: 7px;
        padding-right: 15px;
      }
    }
  }

  .business-unit-view-panel-sub-section-related-team {
    .business-unit-view-panel-sub-section-related-team-container {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: $grey-1;
    }
  }

  .business-unit-view-panel-sub-section-linked-domains {
    .content-section-body {
      padding: 0.5rem 1rem;
    }

    .business-unit-view-panel-sub-section-linked-domains-container {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: $grey-1;

      .business-unit-view-panel-sub-section-linked-domain-text {
        text-decoration: underline;
      }
    }
  }
}

.business-unit-view-panel-sub-section-tags-section {
  .business-unit-view-panel-sub-section-tags-container {
    border-radius: 4px !important;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    border: 1px solid var(--grey-5, #ebeaea) !important;
    padding: 4px 6px !important;
    width: 100%;

    .business-unit-view-panel-sub-section-tag {
      border-radius: 4px !important;
      border: 1px solid var(--gray-2, #e2e2e2) !important;
      padding: 6px 10px !important;
      color: var(--grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }
}
