.dashboard-carousel-wrapper {
  max-width: 92vw;
}

@mixin dashboard-control-button {
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.7rem;
}

.dashboard-carousel-caret-icon {
  path {
    fill: $grey-1;
  }
}

.dashboard-previous-control-button {
  left: 2px;
  @include dashboard-control-button;
}

.dashboard-next-control-button {
  right: 2px;
  @include dashboard-control-button;
}

.dashboard-control-button-hidden {
  display: none;
}

.dashboard-carousel-list-item-container {
  height: 100px;
  background: #ffffff;
  border: 1.5px solid #7547dc;
  border-radius: 8px;
}
