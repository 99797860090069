.findingWorkflowContainer {
  width: 100%;
  height: 70vh;
  position: relative;
}

.findingWorkflowContainer p {
  margin: 0;
}

.findingWorkflowPanel {
  height: 70vh;
  width: 26rem;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(204, 210, 227, 0.45);
  border-radius: 8px;
  position: absolute;
  z-index: 1000;
  right: 2rem;
  top: 1rem;
  overflow: auto;
}

/* width */
.findingWorkflowPanel::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.findingWorkflowPanel::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.findingWorkflowPanel::-webkit-scrollbar-thumb {
  background: #cdd7e2;
  border-radius: 6px;
}

.findingWorkflowPanelTitle {
  margin-bottom: 2rem;
}

.findingWorkflowPanelTitle h2 {
  margin: 0;
}

.findingWorkflowPanelCloseIcon {
  cursor: pointer;
}

.findingWorkflowPanelActionDetails {
  background: rgba(102, 100, 237, 0.12);
  border: 0.986425px dashed #6664ed;
  border-radius: 9.86425px;
  padding: 0.5rem 3rem;
  width: 90%;
  margin-bottom: 2rem;
  width: 100%;
  padding: 1rem;
}

.findingWorkflowPanelActionDetailsId {
  align-items: center;
  text-align: center;
  color: #0a0b50;
  width: 52px;
  height: 24px;
  background: #d2d4f7;
  border-radius: 4px;
}

.findingWorkflowPanelActionDetails img {
  height: 30px;
}

.findingWorkflowPanelActionDetails p {
  margin: 0;
}

.findingWorkflowPanelActionDetails p:first-child {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  /* Dark Blue */

  color: #0a0b50;
}

.findingWorkflowPanelActionDetails p:nth-child(2) {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  /* Dark Blue */
}

.findingWorkflowPanelParameters {
  word-break: break-word;
}

.findingWorkflowPanelParametersTitle {
  min-width: 30%;
  margin-right: 1rem;
}

.findingWorkflowPanelParametersTitle p {
  font-weight: 700;
  color: #0a0b50;
  font-size: 16px;
}

.findingWorkflowPanelParametersValue p {
  font-weight: 500;
  color: #0a0b50;
  font-size: 14px;
}

.findingWorkflowPanelResults {
  margin-top: 2rem;
}

.findingWorkflowPanelResults p {
  font-weight: 700;
  color: #0a0b50;
  font-size: 16px;
}

.findingWorkflowPanelResultsJson {
  background-color: #f5f8fd !important;
  border-radius: 8px 8px 0px 0px !important;
  padding: 0.5rem;
}

.findingWorkflowPanelResultsJson ul {
  background-color: transparent !important;
}
