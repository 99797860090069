.opus-header-1 {
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 26px !important;
  line-height: 31px !important;
  color: #000000 !important;
}

.opus-body-text-1 {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #101828 !important;
  opacity: 0.6;
}

.header-1 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 32px !important;
  line-height: 32px !important;
  color: #404040;
  @include remove-header-default-margin;
}

.header-2 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 28px !important;
  line-height: 28px !important;
  color: #404040;
  @include remove-header-default-margin;
}

.header-3 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 24px !important;
  line-height: 24px !important;
  color: #404040;
  @include remove-header-default-margin;
}

.header-4 {
  font-variant-numeric: lining-nums proportional-nums;
  font-family: 'HEX Franklin v0.2';
  font-size: 24px;
  font-style: normal;
  font-weight: 588;
  line-height: normal;
  @include remove-header-default-margin;
}
.label-3 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 24px !important;
  color: #3d3d3d;
  @include remove-header-default-margin;
}

.header-4 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 20px !important;
  line-height: 20px !important;
  color: #404040;
  @include remove-header-default-margin;
}

.header-5 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 16px !important;
  @include remove-header-default-margin;
}

.header-6 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #404040;
  @include remove-header-default-margin;
}

.header-7 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 13px !important;
  color: #404040;
  @include remove-header-default-margin;
}

.header-8 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  color: #7f7f7f;
}

.header-9 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 20px !important;
  line-height: 24px !important;
}

.body-1 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.body-2 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 13px !important;
}

.body-3 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 12px !important;
  color: #404040;
}

.body-4 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 11px !important;
  color: #404040;
}

.body-5 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #7f7f7f;
}

.body-6 {
  color: #3d3d3d !important;
  font-family: 'HEX Franklin v0.2' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 14px !important;
}

.label-1 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}
.label-2 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 17px !important;
}

.label-4 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 588 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}
.label-5 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
}
