.dropdown-picker-container {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  .dropdown-button {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    height: 24px;
    padding: 4px;
    justify-content: flex-end;
  }

  .person-item-container {
    display: flex;
    align-items: center;
  }
}

.dropdown-menu-item {
  padding: 12px 8px !important;
  display: flex !important;
  align-items: center;
}

.dropdown-menu-item.active {
  background: $grey-5;
}

.menu-chip-text {
  font-size: 13px;
  line-height: 13px;
  font-weight: 400;
  margin: 0 10px !important;
  overflow: clip;
  color: #101828 !important;
}

.menu-item-text {
  font-size: 13px !important;
  line-height: 13px !important;
  font-weight: 400;
  margin: 0px 10px !important;
  color: var(--Gray-6, #3d3d3d) !important;
}

.profile-picture {
  height: 16px;
  border-radius: 50%;
  margin-left: 4px;
}

.status-icon {
  height: 16px;
  margin-left: 4px;
}

.chip-status-icon {
  height: 16px;
  margin-left: 4px;

  path {
    fill: var(--Grey-1, #3d3d3d);
  }
}
.input-label-wrapper {
  .dropdown-picker-container {
    max-width: inherit;
    height: 40px;
    justify-content: center;
    .dropdown-button {
      height: 40px !important;
      font-family: 'HEX Franklin v0.2';
      font-size: 14px;
      font-style: normal;
      color: $grey-1;
      font-weight: 400;
      line-height: 14px;
      padding: 13px !important;
      display: flex;
      padding: 13px !important;
      justify-content: space-between !important;

      .placeholder {
        color: $grey-3;
      }
    }
  }
}
