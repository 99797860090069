.crossroad-panel-form {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  .auto-complete-input {
    .MuiInputBase-root {
      border: 1px solid var(--Grey-5, #ebeaea);
      border-radius: 8px;
      min-height: 42px !important;
    }
  }

  .auto-complete-label-select {
    display: none !important;
  }
}
