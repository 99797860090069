.widgetContainer {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 20px;
  min-height: 35vh;

  .title {
    font-family: 'HEX Franklin v0.2';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    color: #3d3d3d;
  }

  .widgetArea {
    height: 100%;
    padding-top: 25px;
    display: flex;
    align-items: center;
  }

  .paramTitle {
    color: rgba(74, 83, 146, 0.6) !important;
    font-weight: 700 !important;
    font-size: 16px !important;
  }

  .paramValue {
    font-size: 32px !important;
    color: #2b1470 !important;
    font-weight: 800 !important;
  }
}
