.score-chip {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 5px 8px;
  gap: 10px;
  border-radius: 5px;
  &.bordered {
    padding: 5px 11px;
  }

  &.score-chip-low {
    background: $green-6;
    color: $green-3;
    &.bordered {
      border: 1px solid $green-3;
    }
  }

  &.score-chip-medium {
    background: $orange-6;
    color: $orange-3;
    &.bordered {
      border: 1px solid $orange-3;
    }
  }

  &.score-chip-high {
    background: $red-6;
    color: $red-3;
    &.bordered {
      border: 1px solid $red-3;
    }
  }

  &.score-chip-critical {
    background: $red-6;
    color: $red-2;
    &.bordered {
      border: 1px solid $red-2;
    }
  }
}
