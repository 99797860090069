.operational-dashboard-section-timeline-filter-listbox {
  li {
    cursor: pointer;
    user-select: none;

    p {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $grey-1;
    }
  }
}

.operational-dashboard-section-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .ag-root-wrapper {
    padding: 0 !important;
    border: none !important;
  }

  .operational-dashboard-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    svg {
      height: 16px;
      width: 16px;

      path {
        fill: $grey-1;
      }
    }

    h5 {
      color: $grey-3;
    }

    .auto-complete-container {
      width: 10%;

      p {
        display: none;
      }
    }
  }
}
