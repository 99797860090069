.date-range-container {
  margin-top: 15px;
  display: flex;
  align-items: end;

  .date-range-text {
    color: #3d3d3d;
    margin-left: 5px;
  }
}
