$white: #ffffff;
$black: #000000;

$grey-1: #3d3d3d;
$grey-2: #5c5c5c;
$grey-3: #7f7f7f;
$grey-4: #c7c4c4;
$grey-5: #ebeaea;
$grey-6: #f5f5f5;
$grey-7: #f9f9f9;
$grey-8: #e2e2e2;
$grey-900: #111928;

$purple-1: #31156f;
$purple-2: #562abb;
$purple-3: #7547dc;
$purple-4: #9670ef;
$purple-5: #c3aff2;
$purple-6: #ece7f9;

$red-1: #761727;
$red-2: #ab2039;
$red-3: #e02b4b;
$red-4: #fd5b78;
$red-5: #ffb3c0;
$red-6: #ffe1e6;

$orange-1: #9f5a1a;
$orange-2: #c77a32;
$orange-3: #ed923c;
$orange-4: #fc9c5a;
$orange-5: #ffcfae;
$orange-6: #ffe9da;

$yellow-1: #8d751f;
$yellow-2: #cdad37;
$yellow-3: #e4cd47;
$yellow-4: #ffdb53;
$yellow-5: #fce89c;
$yellow-6: #fef7dc;

$blue-1: #013393;
$blue-2: #024ad4;
$blue-3: #0c5ffd;
$blue-4: #5992fe;
$blue-5: #bdd4ff;
$blue-6: #f0f5ff;

$green-1: #0f6a50;
$green-2: #0fae7f;
$green-3: #22cc9a;
$green-4: #61d8b6;
$green-5: #acecda;
$green-6: #dffef5;
