.canvas {
  flex: 1;
  position: relative;
}

.bottomMenu {
  position: absolute;
  z-index: 4;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
}

.addToolPanel {
  position: absolute;
  z-index: 4;
  top: 1rem;
  right: 1rem;
}

.crossroadPanel {
  position: absolute;
  z-index: 4;
}
