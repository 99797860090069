.finding-intelligence-content-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.finding-intelligence-content {
  .finding-intelligence-risks {
    .finding-content-section-risks {
      .risk-metadata-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        .metadata-row {
          display: flex;
          flex-direction: row;
          gap: 12px;
          align-items: center;
          width: 100%;

          .indicators-container {
            display: flex;
            align-items: center;
            gap: 5px;
          }

          .body-1 {
            width: auto !important;
          }
        }
      }
    }

    .finding-content-risks-tags {
      .content-section-body {
        margin: 0;
        padding: 0;
      }

      .risk-tags-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .risk-tag-row {
          display: grid;
          grid-template-columns: 1fr 10fr;
          align-items: center;
          .title {
            p {
              color: var(--Grey-3, #7f7f7f);
            }
          }
          .tag-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }

    .finding-intelligence-metrics {
      .metric-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;

        .body-1 {
          width: auto !important;
        }
      }
      .finding-intelligence-metrics-row {
        display: flex;
        flex-direction: column;

        .metrics-data {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 2rem;
          margin-top: 10px;
        }
      }
    }
  }

  .finding-intelligence-vendors {
    .finding-vendors-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
    }
  }

  .finding-discussions-container {
    .finding-discussions-item-container {
      padding-bottom: 20px;
      .title {
        padding-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7px;

        .month {
          color: $grey-3;
        }
        .hours {
          color: $grey-1;
        }
      }
      .discussions-details {
        display: flex;
        flex-direction: column;
        gap: 6px;
        .row {
          display: inline-grid;
          grid-template-columns: 2fr 8fr;
          align-items: center;
        }

        .body-2 {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 20px !important;
        }
      }
    }
  }

  .finding-threats-container {
    .content-section-body {
      max-height: 700px;
      overflow: auto;
    }
  }
  .finding-threat-actors-container {
    .content-section-body {
      max-height: 430px;
      overflow: auto;
    }
  }

  .finding-intelligence-weaknesses {
    .content-section-body {
      max-height: 400px;
      overflow: auto;
    }
  }
  .finding-discussions-container {
    .content-section-body {
      max-height: 430px;
      overflow: auto;
    }
  }

  .finding-threats-container,
  .finding-threat-actors-container,
  .finding-discussions-container {
    .timeline-container {
      padding-left: 12px;
    }
    .children-item {
      padding-left: 22px !important;
    }
    p {
      margin: 0;
    }
    .finding-threats-item-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-bottom: 20px;

      .indicator {
        position: absolute;
        left: -12px;
        width: 24px;
        height: 24px;
        top: -2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--rounded-md, 6px);
        border: 1px solid var(--Grey-5, #ebeaea);
        background: var(--White, #fff);
        box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
      }
      .threat-details {
        display: flex;
        flex-direction: column;
        border: 1px solid var(--Grey-5, #ebeaea);
        border-radius: 10px;
        padding: 10px 20px;
        width: 385px;
        gap: 10px;

        .aliases-container {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 2px;
        }
        .row {
          display: inline-grid;
          grid-template-columns: 3fr 8fr;

          align-items: center;

          .tag-list,
          .sources-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .finding-intelligence-weaknesses,
  .finding-threat-actors-container,
  .finding-discussions-container {
    .comment-indicator:not(.default-indicator) {
      width: 12px;
      height: 12px;
      background: #ffffff;
      border: 2px solid $purple-4;
      border-radius: 50%;
      position: absolute;
      left: -6px;
      top: 0;
    }

    .timeline-container {
      padding-left: 12px;
    }
    .children-item {
      padding-left: 22px !important;
    }
  }

  .finding-threat-actors-container {
    p {
      margin: 0;
    }
    .finding-threat-actors-item-container {
      .threat-actors-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 10px;
        .threat-actors-details {
          display: grid;
          flex-direction: row;
          align-items: start;
          grid-template-columns: 2fr 9fr;
          max-height: 70px;

          .description {
            color: $grey-1 !important;
            opacity: 1 !important;
          }
        }
      }
    }
  }
  .finding-intelligence-references {
    .content-section-body {
      display: grid !important;
      grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
      gap: 32px;
      column-gap: 32px;
    }

    .finding-content-intelligence-references-section {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 14px;

      .finding-content-intelligence-references-section-body {
        display: flex;
        flex-direction: column;
        padding: 14px;
        border: 1px solid var(--Grey-5, #ebeaea);
        border-radius: 6px;
        height: 112px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;

        a {
          display: inline-block;
          color: var(--Purple-3, #7547dc);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          min-height: 20px;
          white-space: nowrap;
          text-decoration: none;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
      }
    }
  }

  .warning-tag {
    border-radius: 6px;
    border: 1px solid var(--Purple-6, #ece7f9);
    color: var(--Purple-3, #7547dc);
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    path {
      fill: var(--Purple-3, #7547dc) !important;
    }
    p {
      color: var(--Purple-3, #7547dc) !important;
    }
  }
  .secondary-text {
    color: var(--Grey-3, #7f7f7f);
  }
  .black-text {
    color: $black !important;
  }

  .primary-tag {
    border-radius: 4px;
    border: 1px solid var(--Gray-2, #e2e2e2);
    background: var(--White, #fff);
    display: flex;
    padding: 3px 5px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    path {
      fill: var(--Grey-1, #3d3d3d);
    }
  }
  .bold {
    font-weight: 500 !important;
  }
  .widget-divider {
    margin: 30px 0 16px 0;
    border-color: $grey-5;
  }
  .threat-tag {
    border-radius: var(--rounded-md, 6px);
    border: 1px solid var(--Grey-5, #ebeaea);
    background: var(--White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
    padding: 3px 12px;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .interactive-label .interactive-label-text a {
    color: $purple-3 !important;
    text-decoration: none !important;
  }
  .interactive-label {
    path {
      fill: $purple-3;
    }
  }
}

.intelligence-weakness-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 22px;
  width: 100%;

  .intelligence-weakness-item-title {
    color: var(--Grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 588;
    line-height: 16px;
  }

  .intelligence-weakness-item-properties {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .intelligence-weakness-item-properties-row {
      display: flex;
      gap: 10px;
      align-items: center;

      .intelligence-weakness-item-properties-row-key {
        color: var(--Grey-3, #7f7f7f);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
      }

      .intelligence-weakness-item-properties-row-value {
        color: var(--Grey-1, #3d3d3d);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}
