.advanced-filter-container {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 12px 16px !important;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .divider {
    border-bottom: 1px solid $grey-5;
  }

  .filter-favourites {
    min-width: 36px;
    min-height: 36px;
    padding: 12px 11px !important;

    svg {
      color: $purple-3;
    }
  }

  .filter-favourites.active {
    border: 1px solid $purple-5 !important;
    border-radius: 8px !important;
  }
}

.filter-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.filter-main-input {
  margin-right: 10px !important;
}

.filter-main-input {
  margin-right: 5px;
}

.filter-item {
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  align-items: center;
  display: flex;
  padding: 12px 8px;
  margin: 1px 0;

  &:hover {
    background: $grey-7;
  }
}

.item-text {
  flex: 1;
  color: $grey-1 !important;
}

.filter-data {
  display: flex;
  align-items: center;
}

.clear-text {
  margin: 0 13px;
  color: $purple-3;
  cursor: pointer;
  align-self: center;
}
.save-text {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $grey-5;
  border-radius: 6px;
  height: 32px;
  padding: 8px 14px;
  cursor: pointer;
  align-self: center;
  box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
}

.chip-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.add-icon {
  font-size: 15px;
  color: $purple-3 !important;
}

.filter-info {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-wrap: wrap;
  gap: 20px;
}

.quick-filter-group-label {
  padding: 0 8px;
}

.advance-filter-button-area {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.quick-filter-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.ai-filter-group {
  display: flex;
  align-items: center;
  gap: 10px;

  .ai-filter-beta-tag {
    color: var(--Purple-3, #7547dc);
    font-size: 12px;
    font-style: normal;
    font-weight: 588;
    line-height: 14px;
    text-transform: uppercase;
    padding: 3px 6.5px;
    border-radius: 4px;
    border: 1px solid var(--Purple-3, #7547dc);
    background: #f9f9f9;
    box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
  }

  button {
    padding: 0 !important;
    background: transparent !important;
    color: var(--Grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 6px;

    .MuiTouchRipple-root {
      display: none !important;
    }

    .ai-filter-dropdown-icon {
      width: 10px;

      path {
        fill: var(--Grey-1, #3d3d3d);
      }
    }
  }
}

.advance-filter-separator {
  width: 1px;
  height: 100%;
  background: #ebeaea;
  margin-left: 14px;
  margin-right: 14px;
}
