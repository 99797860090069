.business-unit-table-wrapper {
  padding: 0rem !important;
  background-color: transparent !important;
  height: 65vh !important;
  border: 0 !important;

  .business-unit-table-loading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .business-unit-table {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .business-unit-table-body {
    height: 90%;
    overflow: auto;
  }
}

.business-unit-row-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .business-unit-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    background: $white;
    border: 1px solid $grey-8;
    border-radius: 8px;
    padding: 1rem;
    padding-right: 2rem;
    gap: 2rem;

    .business-unit-row-area-title {
      font-style: normal;
      font-weight: 588;
      font-size: 14px;
      line-height: 14px;
      color: $grey-1;
      flex: 1;
    }

    .business-unit-row-title-area {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .business-unit-row-title-area-title {
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 26px;
        color: $grey-1;
        max-width: 150px;
      }

      .business-unit-row-title-area-impact {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        .business-unit-row-title-area-impact-helper-text {
          font-style: normal;
          font-weight: 588;
          font-size: 14px;
          line-height: 14px;
          color: $grey-1;
        }
      }
    }

    .business-unit-row-content-section {
      gap: 1rem;

      .content-section-header {
        .content-section-title {
          font-style: normal;
          font-weight: 588;
          font-size: 14px;
          line-height: 14px;
          color: $grey-1;
        }
      }
    }

    .business-unit-row-related-workspaces-content-section {
      .content-section-body {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }
    }

    .business-unit-row-risk-attributes-content-section {
      flex: 1;

      .content-section-body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        column-gap: 1rem;
      }
    }

    .business-unit-row-stakeholders-content-section {
      .content-section-body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
}
