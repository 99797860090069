.workflow-builder-condition-edge-container {
  position: relative;
  overflow: visible;

  .workflow-builder-condition-edge-label {
    background: #f2f7fc;
    text-align: center;
    border: 1.5px solid #c3aff2;
    border-radius: 20px;
    font-weight: bold;
    color: #7547dc;
    overflow: hidden;
    padding: 0rem 0.5rem;
    white-space: pre-wrap;
    word-wrap: break-word;
    user-select: none;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
  }

  .workflow-builder-condition-edge-delete-button {
    position: absolute !important;
    right: -10px;
    top: -5px;
    background-color: white !important;
    border: 1px solid #7547dc !important;
    height: 18px;
    width: 18px;

    svg {
      path {
        fill: #7547dc;
      }
    }
  }

  .workflow-builder-plus-edge-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background: #ffffff !important;
    text-align: center;
    border: 1px dashed #7547dc;
    border-radius: 20px;
    font-weight: bold;
    color: #7547dc;
    overflow: hidden;
    padding: 0rem 0.1rem;

    .workflow-builder-plus-button {
      box-shadow: none !important;
    }
  }

  .workflow-builder-condition-edge-not-active {
    color: #cdd7e2 !important;
    border-color: #cdd7e2 !important;
  }

  .workflow-builder-condition-edge-active {
    background: #d2d4f7;
    border: 2px solid #6664ed;
    color: #6664ed;
    border-radius: 67px;
  }
}
