.workflow-builder-panel-trigger-finding-list-no-data {
  padding: 1rem;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.workflow-builder-panel-trigger-finding-list {
  margin-top: 16px;
  margin-bottom: 16px;

  .workflow-builder-panel-trigger-finding-list-item {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 8px 10px;
    border-bottom: 1px solid #f5f5f5;
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }

    .workflow-builder-panel-trigger-finding-list-item-icon {
      min-width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--3, 12px);
      border: 1px solid var(--Grey-5, #ebeaea);
      background: #fff;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .workflow-builder-panel-trigger-finding-list-item-label-group {
      flex: 1;
      overflow: hidden;

      .workflow-builder-panel-trigger-finding-list-item-label-primary {
        color: var(--Grey-1, #3d3d3d);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
      }

      .workflow-builder-panel-trigger-finding-list-item-label-secondary {
        color: var(--Grey-3, #7f7f7f);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: nowrap;
      }
    }
  }
}
