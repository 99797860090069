.common-simple-chip {
  border-radius: 6px !important;
  border: 1px solid $grey-5 !important;
  background: $grey-7 !important;
  box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);

  .common-simple-chip-icon,
  .common-simple-chip-delete-icon {
    path {
      fill: $grey-3;
    }
  }

  .common-simple-chip-label {
    color: $grey-1;
    font-family: 'HEX Franklin v0.2';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .common-simple-chip-delete-icon {
    height: 10px;
    width: 10px;
    margin: auto;
  }
}
