.plus-button {
  width: 1.6rem;
  min-width: 1.6rem;
  height: 1.6rem;
  background: var(--Purple-3, #7547dc) !important;
  box-shadow: 0px 4px 20px 0px rgba(117, 71, 220, 0.6);
  padding: 4px;

  svg {
    width: 13px;
    height: 16px;
    path {
      fill: #ffffff;
    }
  }
}

.plus-button-border {
  border: 3px solid var(--Purple-6, #ece7f9) !important;
}

.plus-button-readonly {
  width: 0.7rem;
  height: 0.7rem;
}
