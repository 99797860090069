.date-picker-container {
  overflow: visible;

  .date-picker-clear-text {
    user-select: none;
    cursor: pointer;
    text-decoration: none !important;
    font-size: 14px;
  }

  .date-picker-input-container {
    position: relative;

    .date-picker-input {
      position: inherit;
      z-index: 0;
    }

    .date-picker-input-icon {
      position: absolute;
      right: 2%;
      top: 50%;
      transform: translateY(-40%);
      z-index: 2;
    }

    .date-picker-input-outline {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 8px;
      z-index: 1;
      cursor: pointer;
    }

    .date-picker-input-outline:hover,
    .date-picker-input:focus + .date-picker-input-outline {
      border: 1px solid #7547dc;
    }
  }
}

.flatpickr-calendar {
  width: 325px !important;
  border-radius: 8px !important;

  &.arrowBottom:after {
    border-top-color: $purple-3 !important;
    // background: $purple-3;
  }
}

.flatpickr-prev-month,
.flatpickr-next-month {
  margin: 19px;
  top: 0;
  align-items: center;
  display: flex;
  padding: 0 !important;
  height: auto !important;

  &:hover {
    svg {
      fill: $grey-1 !important;
      height: 16px !important;
      width: 16px !important;
    }
  }

  svg {
    fill: $grey-1;
    height: 16px !important;
    width: 16px !important;
  }
}

.flatpickr-innerContainer {
  border-bottom: none !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .flatpickr-weekdays {
    background: white;

    .flatpickr-weekdaycontainer {
      background: white;
      padding: 0 19px;

      .flatpickr-weekday {
        background: white;
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 12px;
        color: $grey-1;
      }
    }
  }

  .flatpickr-days {
    width: 330px;
    border-left: none;
    border-right: none;
    align-items: center;
    justify-content: center;

    .dayContainer {
      padding: 0 19px;
      width: 330px;
      max-width: 330px !important;

      .flatpickr-day {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        color: $grey-1;
        height: 32px;
        width: 32px;
        align-items: center;
        display: flex;
        border-radius: 4px;
        margin: 2px 1px;

        &:hover {
          background: $purple-6;
          color: $purple-3;
          border-color: $purple-6;
          font-weight: bold;
        }

        &.prevMonthDay,
        &.nextMonthDay {
          color: $grey-5;
        }

        &.startRange,
        &.endRange {
          background: $purple-3;
          border-color: $purple-3;
          color: white;
          font-weight: bold;
        }

        &.inRange {
          background: $purple-6;
          border-color: $purple-6;
          border-radius: 0;
          -webkit-box-shadow: -5px 0 0 $purple-6, 5px 0 0 $purple-6;
          box-shadow: -5px 0 0 $purple-6, 5px 0 0 $purple-6;
        }

        &.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
        &.flatpickr-day.startRange.startRange
          + .endRange:not(:nth-child(7n + 1)),
        &.flatpickr-day.endRange.startRange
          + .endRange:not(:nth-child(7n + 1)) {
          -webkit-box-shadow: -10px 0 0 $purple-6;
          box-shadow: -10px 0 0 $purple-6;
        }

        &.today {
          border-radius: 4px;
        }

        &.selected {
          background: $purple-3;
          border-color: $purple-3;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
}

.flatpickr-month {
  background: white !important;
  height: 50px !important;
  border-radius: 8px 8px 0 0 !important;

  .flatpickr-current-month {
    padding: 14px 0 0 0;

    .numInputWrapper span.arrowUp:after {
      border-bottom-color: $grey-1;
    }

    .numInputWrapper span.arrowDown:after {
      border-top-color: $grey-1;
    }

    .flatpickr-monthDropdown-months {
      background-color: white;
      margin: -1px 0 0 -60px;
      padding: 0;
      color: $grey-1;
      font-weight: 700;

      &:hover {
        background-color: white;
      }
    }

    .numInputWrapper {
      left: 55px;
      color: $grey-1;

      input.cur-year {
        color: $grey-1;
        font-weight: 700;
      }

      &:hover {
        background-color: white;
      }
    }
  }
}

// .flatpickr-months {

//   &.flatpickr-month {
//     background: white;
//   }
// }
