.findingSummary {
  .logoBlock1 {
    width: 2rem;
    height: 1.5rem;
  }

  .timelineApplication {
    width: 2.5rem;
    height: 2.5rem;
  }

  .logoBlock2 {
    width: 2rem;
    height: 1.25rem;
  }

  .logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  .subText {
    color: #bababa;
    font-style: normal;
    font-weight: 400;
  }

  .findingSidebarWrapper {
    background-color: #ffffff;
    position: sticky;
    height: 97vh;
    top: 0;
    overflow: auto;
    width: 410px;
  }

  .findingTimeline {
    padding: 1.5rem 1.5rem 1.5rem 0;
    position: sticky;
    height: 100vh;
    top: 0;
  }

  .popover {
    margin-top: 0.5rem;
    width: 235px;
    border-radius: 8px !important;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
  }

  .infoDisplay {
    .MuiBox-root {
      display: flex;
      align-items: flex-end;
    }
  }
}

.action-menu-list-button.finding-action-menu-list-button {
  min-width: 150px !important;
  background-color: #5754e5 !important;
  padding: 1rem 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-around;

  span {
    color: #fff !important;
    text-align: center !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    margin-left: 1.5rem;
  }

  svg {
    path {
      fill: #fff !important;
    }
  }
}

.finding-summary-external-id-tag {
  overflow: hidden;
  text-align: center;

  .finding-summary-external-id-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 1rem;
  }
}
