.ps-collapsed {
  z-index: 10;
}

.menu-item {
  display: flex;
  margin: 10px 0;

  .ps-menu-root {
    // background-color: pink;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.iconStyle {
  color: #9571ee !important;
}

.textStyle {
  color: #9571ee !important;
}

.second-menu {
  margin-top: 80px;
}

.user-menu-item {
  width: 81px;
  height: 70px;
}

.bottomMenu {
  width: 81px;
  position: absolute;
  bottom: 10px;
}

.ps-menuitem-root {
  height: 100% !important;
  // background: aquamarine !important;
  align-items: center !important;
  display: flex !important;
}

.ps-menu-button {
  padding: 0 !important;
  min-height: 70px !important;
  height: 70px !important;
}

.ps-menuitem-root > .ps-menu-button:first-of-type:hover {
  background-color: #2b1470;
  /* Add any other desired hover styles */
}

.menuItem > .ps-menu-button:first-of-type:hover {
  background-color: #f4f7fa;
  /* Add any other desired hover styles */
}

.menuItem > .ps-menu-button {
  width: 100%;
  padding: 0 20px !important;
}

.ps-submenu-expand-icon {
  display: none;
}

.ps-menu-label {
  //overflow: unset;
  white-space: break-spaces !important;
  //width: 100px;
}

.selector {
  border-left: 2px solid white;
  height: 70px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.menu-item.active {
  position: relative;
}

.menu-item.active:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  /* Adjust the width of the vertical line */
  background-color: white;
  /* Adjust the color of the vertical line */
}

.active {
  position: relative;

  .ps-menu-label a svg path {
    fill: white !important;
  }

  .ps-menu-label a span {
    color: white !important;
  }
}

.ps-menu-label {
  a:hover {
    background-color: transparent;
  }
}
