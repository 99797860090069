.risk-lifecycle-cell-container {
  display: flex;
  flex-direction: row;
  gap: 0.7rem;

  .risk-lifecycle-box {
    padding: 0.3rem;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    min-width: 24px;
    min-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 13px;
  }

  .risk-lifecycle-box-active {
    border: none;
    background-color: $purple-6;
    color: $purple-3;

    svg {
      path {
        fill: $purple-3;
      }
    }
  }
}
