.dashboard-selected-filter-row-container {
  padding: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  background-color: #f9fbff;
}

.dashboard-selected-filter-row-container-no-padding {
  padding-top: 0rem;
}
