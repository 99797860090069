.chip-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f9f9f9;
  padding: 12px;
  height: 41px;
  margin-right: 4px;
  /* Grey/5 */

  border: 1px solid #ebeaea;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05);
  border-radius: 6px;
  margin-top: 2px;
}

.chip-type {
  color: $grey-3;
}

.chip-selected {
  color: $grey-1;
}

.chip-popover-icon {
  cursor: pointer;
  color: $grey-3;
}

.chip-close-icon {
  cursor: pointer;
  font-size: 18px !important;
  color: $grey-3;
}
