.search-input {
  .MuiInputBase-root {
    max-height: 40px;
    border-radius: 4px !important;
    border: 1px solid $grey-5 !important;
    background: #fff;
    align-items: center;

    input {
      &::placeholder {
        color: var(--Grey-3, #7f7f7f) !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }

  .MuiInputAdornment-root {
    font-size: 15px;
    color: $grey-3;
  }

  fieldset {
    border: none;
  }
}
