.status-container {
  min-height: 24px;

  p {
    font-family: 'HEX Franklin v0.2' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    color: $grey-1;
    width: max-content;
  }

  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  background: $white;
  border: 1px solid $grey-8;
  border-radius: 4px;
}
