.side-menu-container {
  width: 100%;
  background-color: #fff !important;
  flex-direction: column;
  border: 1px solid $grey-5;
  border-radius: 12px;

  .side-menu-item {
    padding: 20px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    .icon-container {
      border-radius: 12px;
      border: 1px solid $grey-5;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      path {
        color: $grey-1;
      }
    }
  }

  .side-menu-item:not(:last-child) {
    border-bottom: 1px solid $grey-5;
  }

  .side-menu-item.active {
    .icon-container {
      border: none;
      background-color: $purple-6;

      path {
        fill: $purple-3;
      }
    }
  }
}
