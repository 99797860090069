.form-input-container {
  .form-input-body {
    .form-input-control {
      position: relative;

      input {
        padding-right: 4rem;
      }

      .form-input-control-end-icon {
        position: absolute;
        top: 50%;
        right: 2%;
        transform: translateY(-50%);
      }
    }
  }

  .form-input-title {
    margin-bottom: 0.6rem;
  }
}

.form-input-error {
  color: var(--red-3, #e02b4b);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
