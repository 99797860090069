.list-panel-container {
  width: 30rem;
  padding: 1rem 1.5rem;
  min-height: 88vh;
  background: #ffffff !important;
  box-shadow: 0px 0px 14px rgba(158, 140, 233, 0.24) !important;
  border-radius: 19px !important;
}

.list-panel-container .list-panel-input {
  background: #f4f7fa;
  border-radius: 7px;
}

.active-tool-container {
  background: rgba(102, 100, 237, 0.12);
  /* Purple */

  border: 0.986425px dashed #6664ed;
  border-radius: 9.86425px;
  padding: 0.5rem 1.5rem;
  width: 100%;
  margin: auto;
}

.active-tool-container img {
  height: 30px;
}

.active-tool-container p {
  margin: 0;
}

.active-tool-container p:first-child {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  /* Dark Blue */

  color: #0a0b50;
}

.active-tool-container p:nth-child(2) {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;

  /* Dark Blue */

  color: #0a0b50;
}

.node-panel-form-buttons {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 1rem 1.5rem;
  border-top: 1px solid #cdd7e2;
}

.active-tool-id {
  background: #d2d4f7;
  border-radius: 4px;
  padding: 3px 5px;
}

.node-panel-form-remove-button {
  border-color: #cdd7e2 !important;
  padding: 0.6rem 1rem !important;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 16px !important;
}

.node-panel-form-remove-button path {
  fill: #6664ed;
}

.node-panel-form-remove-button p {
  margin: 0;
}
