.integration-modal-container {
  .integration-modal-icon {
    height: 26px;
    margin-bottom: 0;
  }

  .common-modal-header-title-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .common-modal-header-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .common-modal-header-title-icon {
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 16px;
      border: 1px solid var(--grey-5, #ebeaea);
    }
  }

  .integration-modal-content-wrapper {
    display: flex;
    flex-direction: column;

    gap: 1rem;

    .integration-modal-content-description {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      max-width: 90%;
    }

    .integration-modal-content-tutorial-link {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 14px;
      font-style: normal;
      font-weight: 588;
      line-height: 14px;
      text-decoration-line: underline;
      margin-bottom: 0.5rem;

      a {
        color: var(--purple-3, #7547dc) !important;
      }
    }

    .integration-modal-content {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .integration-modal-content-body {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-height: 50vh;
        overflow: auto;
      }

      .integration-modal-form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .deploy-container {
          border: 1px solid $grey-5;
          border-radius: 12px;
          gap: 1rem;
          padding: 10px;

          .deploy-link {
            a {
              text-decoration: none;
              color: $purple-3;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }
        }

        .integration-modal-form-main-inputs {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .input-label-text {
            gap: 0.7rem;

            .MuiCheckbox-root {
              padding: 0 !important;
            }
          }
        }

        .integration-modal-form-additional-inputs {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .MuiSwitch-thumb {
            transform: translateY(-4%);
          }

          .visibility-control-content-container {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            .visibility-control-content-header {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              color: var(--grey-1, #3d3d3d);
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 14px;
            }

            .visibility-control-content-body {
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
            }

            .integration-modal-form-additional-content {
              display: flex;
              flex-direction: column;
              gap: 1rem;
            }

            .integration-modal-form-button-area {
              display: flex;
              flex-direction: column;
              align-items: start;
              gap: 1rem;
              color: var(--grey-1, #3d3d3d);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              padding: 10px;
              border: 1px solid var(--grey-5, #ebeaea);
              border-radius: 6px;
            }

            .integration-modal-form-main-content {
              display: flex;
              flex-direction: column;
              gap: 2rem;
            }

            .wiz-integration-modal-form-main-content {
              .form-input-container:first-child {
                input {
                  width: 20%;
                }
              }
            }
          }
        }
      }
    }
  }
  .polling-content {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
}

.integration-modal-button-row {
  display: flex;
  justify-content: space-between;

  .integration-modal-connection-buttons {
    display: flex;
    gap: 1rem;
  }
}

.integration-repository-table-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 10px 14px;
  border: 1px solid var(--grey-5, #ebeaea);
  border-radius: 6px;
  min-height: 50vh;

  .integration-repository-table-title {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: #3d3d3d;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }

  .integration-repository-table-description {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .integration-repository-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .integration-repository-table-selection-count {
      color: #3d3d3d;
      font-size: 14px;
      font-style: normal;
      font-weight: 588;
      line-height: 14px;
    }
  }

  .integration-repository-table-body {
    flex: 1;
    display: flex;
    align-items: stretch;

    .common-simple-data-grid-container {
      width: 100%;
      height: 26vh;
    }

    .ag-root-wrapper {
      border: none !important;
      padding: 0 !important;
    }

    .integration-repository-table-row-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.cloud-integration-modal-content {
  .cloud-integration-modal-form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .cloud-integration-modal-form-area {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      max-height: 50vh;
      overflow: auto;

      .cloud-integration-modal-form {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }
    }
  }

  .cloud-integration-modal-form-deploy-stack {
    border: 1px solid var(--grey-5, #ebeaea);
    padding: 0.6rem;
    color: var(--grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    border-radius: 6px;
    gap: 1rem;
    align-items: center;

    .cloud-integration-modal-form-deploy-stack-text {
      flex: 6;
    }
  }
}

.integration-modal-accordion {
  border: 1px solid var(--grey-5, #ebeaea) !important;
  border-radius: 6px !important;
  box-shadow: none !important;

  &::before {
    display: none;
  }

  .integration-modal-accordion-title {
    color: var(--grey-1, #3d3d3d);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 13px;
  }

  .integration-modal-appliation-accordion-summary-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .integration-modal-accordion-configure-block {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: var(--grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 1rem;

    .integration-modal-accordion-configure-block-icon {
      svg {
        path {
          fill: var(--purple-3, #7547dc);
        }
      }
    }
  }

  .integration-modal-accordion-title-block {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    color: var(--grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;

    .integration-modal-accordion-title-icon {
      width: 28px;
      height: 28px;
      overflow: hidden;
      border-radius: 8px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .integration-modal-accordion-details {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    .integration-modal-accordion-description {
      color: var(--grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .integration-modal-accordion-button-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    .integration-modal-accordion-connection-button {
      display: flex;
      gap: 0.6rem;
    }
  }
}

.integration-modal-arn-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 0.5rem;

  .integration-modal-arn-information {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .integration-modal-arn-information-text {
    flex: 6;
  }
}

.cloud-integration-deploy-button {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  text-decoration: none;
  color: var(--purple-3, #7547dc);
}

.cloud-integration-bold-input-title {
  font-weight: 588;
}

.cloud-integration-text-area {
  textarea {
    color: var(--grey-3, #7f7f7f);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.cloud-integration-input-title {
  display: flex;
  gap: 0.2rem;
  color: var(--grey-1, #3d3d3d);
  font-size: 14px;
  font-style: normal;
  font-weight: 588;
  line-height: 14px;

  a {
    color: var(--purple-3, #7547dc) !important;
  }
}

.cloud-integration-input-title-with-helper-text {
  flex-direction: column;
  gap: 0.5rem;

  span {
    display: flex;
    gap: 0.2rem;
  }
}

.cloud-integration-input-helper-text {
  color: var(--grey-3, #7f7f7f);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.integration-modal-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .integration-modal-content-body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-height: 50vh;
    overflow: auto;
  }

  .integration-modal-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .deploy-container {
      border: 1px solid $grey-5;
      border-radius: 12px;
      gap: 1rem;
      padding: 10px;

      .deploy-link {
        a {
          text-decoration: none;
          color: $purple-3;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }

    .integration-modal-form-main-inputs {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .input-label-text {
        gap: 0.7rem;

        .MuiCheckbox-root {
          padding: 0 !important;
        }
      }
    }

    .integration-modal-form-additional-inputs {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .MuiSwitch-thumb {
        transform: translateY(-4%);
      }

      .visibility-control-content-container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .visibility-control-content-header {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          color: var(--grey-1, #3d3d3d);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 14px;
        }

        .visibility-control-content-body {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
        }

        .integration-modal-form-additional-content {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        .integration-modal-form-button-area {
          display: flex;
          flex-direction: column;
          align-items: start;
          gap: 1rem;
          color: var(--grey-1, #3d3d3d);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          padding: 10px;
          border: 1px solid var(--grey-5, #ebeaea);
          border-radius: 6px;
        }

        .integration-modal-form-main-content {
          display: flex;
          flex-direction: column;
          gap: 2rem;
        }

        .wiz-integration-modal-form-main-content {
          .form-input-container:first-child {
            input {
              width: 20%;
            }
          }
        }
      }
    }
  }
}

.cloud-integration-modal-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .cloud-integration-modal-form-area {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-height: 50vh;
    overflow: auto;

    .cloud-integration-modal-form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
}
