.advanced-data-grid-parent-box-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  & > .advanced-data-grid-table-container {
    padding: 1.5rem;
    padding-bottom: 0;
    box-sizing: border-box;
    background: $white;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
  }

  & > .advanced-data-grid-pagination-container {
    padding: 0rem 1rem;
  }
}

.advanced-data-grid-child-box-container {
  padding-left: 3rem;

  .advanced-data-grid-pagination-container {
    padding-right: 1rem;
    margin: 1rem 0;
  }
}

.advanced-data-grid-box-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .advanced-data-grid-table-container {
    height: 100%;
  }

  .advanced-data-grid-container {
    .MuiDataGrid-columnHeader,
    .MuiDataGrid-iconButtonContainer {
      visibility: visible !important;
      outline: none !important;
    }

    .MuiDataGrid-cell {
      outline: none !important;
    }

    border: none !important;

    .advanced-data-grid-row-container {
      text-decoration: none;
      color: #404040;
      display: flex;
      flex-direction: column;
      background-color: $white;

      .advanced-data-grid-row {
        position: relative;

        &:hover {
          background-color: #f9f9f9 !important;
        }

        .advanced-data-grid-sub-table-toggle {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 4rem;
          z-index: 1;
          cursor: pointer;
        }
      }

      .Mui-selected {
        background-color: #f9f9f9 !important;
      }
    }

    .advanced-data-grid-row-sub-table {
      height: 40vh;
    }

    .advanced-data-grid-column-header {
      background: $grey-7;
      border: 1px solid $grey-5;
      padding: 1.2rem 0rem;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;

      .MuiDataGrid-columnHeaderCheckbox {
        &:focus {
          outline: none !important;
        }
      }

      .advanced-data-grid-column-header-inner {
        width: 100%;

        .MuiDataGrid-iconButtonContainer {
          visibility: visible !important;

          button:hover {
            background-color: transparent !important;
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }
      }

      .advanced-data-grid-column-header-title {
        color: $grey-1;
      }

      .advanced-data-grid-column-separator {
        display: none;
      }
    }

    .advanced-data-grid-footer-container {
      flex-direction: row-reverse;
      justify-content: flex-end;

      &:first-child {
        width: 100%;
      }
    }

    //   Sub table styling

    .advanced-data-grid-sub-table-container {
      border: none !important;

      .MuiDataGrid-columnHeaderCheckbox {
        visibility: hidden !important;
      }

      .advanced-data-grid-sub-table-row-container {
        display: flex;
        flex-direction: column;
        background-color: $white;

        .advanced-data-grid-sub-table-row {
          position: relative;

          &:hover {
            background-color: #f9f9f9 !important;
          }

          .advanced-data-grid-sub-table-toggle {
            position: absolute;
            top: 1rem;
            left: 2.5rem;
            z-index: 200;
          }
        }

        .Mui-selected {
          background-color: #f9f9f9 !important;
        }
      }

      .advanced-data-grid-sub-table-column-header {
        background: $white;
        border: none;
        border-bottom: 1px solid $grey-5;

        .MuiDataGrid-columnHeaderCheckbox {
          visibility: hidden;
        }

        .advanced-data-grid-sub-table-column-header-title {
          color: $grey-1;
        }

        .advanced-data-grid-sub-table-column-separator {
          display: none;
        }

        .advanced-data-grid-sub-table-column-header-inner {
          width: 100%;

          button:hover {
            background-color: transparent !important;
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }

        .advanced-data-grid-sub-table-visibility-control {
          position: absolute;
          cursor: pointer;
          right: 0;
        }
      }
    }
  }
}

// Pagination style

.advanced-data-grid-pagination-root {
  * {
    font-family: 'HEX Franklin v0.2' !important;
  }

  .advanced-data-grid-pagination-results {
    p {
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 13px !important;
      line-height: 13px !important;
      color: $grey-1 !important;
    }
  }

  .advanced-data-grid-pagination-rows-per-page-text {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 13px !important;
    color: $grey-1 !important;
  }

  .advanced-data-grid-pagination-rows-per-page-select {
    width: 74px !important;
    height: 32px !important;
    border: 1px solid $grey-5 !important;
    border-radius: 8px !important;
    padding-right: 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: $grey-1;

    .MuiSelect-select {
      display: flex;
      align-items: center;
      width: 74px !important;
      padding-right: 0 !important;
    }

    &:hover {
      outline: none !important;
    }

    fieldset {
      display: none;
    }
  }

  .advanced-data-grid-pagination-pages {
    .MuiPaginationItem-page {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      color: $grey-3;
      width: 37px;
      height: 31px;
    }

    .MuiPaginationItem-page.Mui-selected {
      font-weight: 700;
      color: $grey-1;
      background: $grey-5 !important;
    }

    .MuiPaginationItem-previousNext {
      width: 40px;
      height: 32px;
      border-radius: 4px;
      background-color: transparent;

      svg {
        fill: $grey-1;

        path {
          height: 30px;
        }
      }
    }

    .MuiPaginationItem-previousNext.Mui-disabled {
      border: none !important;

      svg {
        fill: $grey-4;
      }
    }
  }
}

.advanced-data-grid-pagination-rows-per-page-select-item {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 13px !important;
  color: $grey-1 !important;
}
