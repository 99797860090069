.resourceIconWrapper {
  width: 15px;
  height: 17px;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
