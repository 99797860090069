.workflow-builder-trigger-node-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;

  .workflow-builder-trigger-node-title {
    color: var(--Grey-1, #3d3d3d);
    font-family: 'HEX Franklin v0.2';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    padding: 0;
    margin: 0;
  }

  .workflow-builder-trigger-node-icon {
    border-radius: 16px;
    border: 1px solid var(--Purple-5, #c3aff2);
    background: var(--Purple-6, #ece7f9);
    width: 48px !important;
    height: 48px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      path {
        fill: var(--Purple-3, #7547dc);
      }
    }
  }
}

.workflow-builder-trigger-node-plus-button {
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
}

.workflow-builder-trigger-node-items-counter {
  background: var(--Purple-6, #ece7f9);
  border-radius: 40px;
  width: 29px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workflow-builder-trigger-node-empty {
  padding: 8px;
  background: #ffffff;
  border: 1px dashed #7547dc;
  gap: 8px;
}

.workflow-builder-trigger-node-selected {
  padding: 8px;
  background: #ffffff;
  border: none;
  gap: 8px;

  .workflow-builder-trigger-node-icon {
    border: none;
  }
}
