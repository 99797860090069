.import-findings-modal {
  .dialog-content {
    .import-findings-modal-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .description {
        margin: 0;
      }
      .dropzone-uploader-container {
        margin-top: 10px;
      }

      .validation-error-text {
        text-transform: capitalize;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
  .dialog-actions {
    justify-content: space-between !important;
  }
}

.select-form-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  img {
    width: 25px;
    height: 25px;
    object-fit: fill;
  }
  p {
    margin: 0;
  }
}
