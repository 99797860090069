.common-severity-chip-container {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  min-height: 24px;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 6px;
  border-radius: 4px;
}

.common-severity-chip-low-container {
  color: $green-3;
  background-color: $green-6;
}

.common-severity-chip-info-container {
  color: $blue-3;
  background-color: $blue-6;
}

.common-severity-chip-medium-container {
  color: $orange-3;
  background-color: $orange-6;
}

.common-severity-chip-high-container {
  color: $red-3;
  background-color: $red-6;
}

.common-severity-chip-critical-container {
  color: $red-2;
  background-color: $red-6;
}

.severity-breakdown-container {
  display: flex;
  align-items: center;
  gap: 6px;
  .common-severity-chip-container {
    height: 22px;
    width: 22px;
  }
  .severity-container {
    display: flex;
    padding: 4px 11px 4px 5px !important;
    align-items: center;
    gap: 7px;
    cursor: pointer;

    &.disabled {
      .common-severity-chip-container {
        background-color: $grey-6 !important;
        border: none !important;
        color: $grey-3 !important;
      }
    }
  }
}

.severity-container {
  display: flex;
  padding: 4px 5px 4px 11px !important;
  align-items: center;
  gap: 7px;
  cursor: pointer;

  &.disabled {
    .common-severity-chip-container {
      background-color: $grey-6 !important;
      border: none !important;
      color: $grey-3 !important;
    }
  }
}
