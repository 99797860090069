.dialogTitle {
  font-weight: 800 !important;
  font-size: 20px !important;
  color: #525252 !important;
}

.inputLabel {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #0a0b50 !important;
  line-height: 32px !important;
}

.input {
  background-color: #f5f8fd;
  border-radius: 8px;
  color: #0a0b50;
  padding: 14px;
}

.input input:focus {
  border: '1px solid #6664ED';
}

.input input[type='text'] {
  padding: 0;
}

.input input::placeholder {
  color: #748495;
  opacity: 1;
}

.selectInput {
  padding: 0;
}

.selectInput div[role='button'] {
  padding: 14px !important;
}

.selectInput fieldset {
  border: 0 !important;
}
