.workflow-builder-tool-node-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 10px 12px;
  max-width: 17rem;
  border: 1px solid var(--Grey-5, #ebeaea);
  border-radius: 25px;
  background-color: white;
  z-index: 1;
  position: relative;

  .workflow-builder-tool-node-status-icon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(10%, -30%);
  }

  .workflow-builder-tool-node-label {
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 0;
    margin: 0;
  }

  .workflow-builder-tool-node-logo-container {
    width: 32px;
    height: 32px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ebeaea;

    .workflow-builder-tool-node-logo {
      width: 60%;
      height: 60%;
      object-fit: contain;
      object-position: center;
    }
  }
}

.workflow-builder-tool-node-plus-button {
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
}

.workflow-builder-tool-node-active {
  border: 1px solid var(--Purple-3, #7547dc);
  box-shadow: 0px 2px 8px rgba(117, 71, 220, 0.25);
}
