.dashboard-reports-container {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  * {
    font-family: 'HEX Franklin v0.2' !important;
  }

  .dashboard-reports-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dashboard-reports-header-icon-container {
      display: flex;
      align-items: center;
      gap: 5px;

      .dashboard-reports-header-icon {
        img {
          height: 35px;
        }
      }

      .dashboard-reports-header-icon-text {
        font-weight: 700;
        font-size: 25px;
        color: #31156f;
        transform: translateY(-10%);
      }
    }

    .dashboard-reports-header-date-container {
      color: var(--purple-1, #31156f);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }

  .dashboard-reports-description {
    background: var(--purple-6, #ece7f9);
    padding: 11px 14px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    margin-bottom: 16px;

    .dashboard-reports-description-title {
      color: var(--purple-1, #31156f);
      font-size: 16px;
      font-style: normal;
      font-weight: 588;
      line-height: 16px;
    }

    .dashboard-reports-description-body {
      color: var(--purple-1, #31156f);
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .dashboard-reports-description-configuration {
      ul {
        padding: 0px 14px;
        display: flex;
        flex-direction: row;
        gap: 1.5rem;

        li {
          color: #31156f;
          font-size: 15px;
        }
      }
    }
  }

  .dashboard-reports-widgets {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .dashboard-reports-widgets-loading {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .dashboard-reports-widget-container {
      padding: 17px 13px;
      border: 1px solid var(--grey-5, #ebeaea);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .dashboard-reports-widget-title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: var(--grey-1, #3d3d3d);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }
    }
  }
}

.dashboard-reports-risk-description {
  border: 1px solid var(--Grey-5, #ebeaea);
  background-color: #f9f9f9 !important;
  color: var(--Grey-1, #3d3d3d) !important;

  .dashboard-reports-description-configuration {
    margin-top: 2rem;
    ul {
      margin: 0 !important;
    }
  }
}

.dashboard-reports-risk-container {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .no-data-container {
    max-height: 300px !important;
  }

  .dashboard-chart-card-container {
    height: 370px !important;
    max-height: 370px !important;
    width: 100%;

    canvas {
      display: block !important;
      height: 300px !important;
      max-height: 300px !important;
      max-width: 100% !important;
    }
  }

  .dashboard-reports-risk-widget-container {
    display: flex;
    flex-direction: column;
    gap: 13px;

    .dashboard-reports-risk-widget-title {
      display: flex;
      align-items: center;
      gap: 9px;

      .dashboard-reports-risk-widget-title-text {
        color: var(--Grey-1, #3d3d3d);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }

      .dashboard-reports-risk-widget-title-icon {
        svg {
          path {
            fill: var(--Grey-1, #3d3d3d) !important;
          }
        }
      }
    }

    .dashboard-reports-risk-widget-body {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    .dashboard-reports-risk-metrics-widget-body {
      .operational-widget-card-container,
      .dashboard-widget-card-container {
        min-width: 270px !important;

        &:hover {
          border: 1px solid var(--Grey-5, #ebeaea) !important;
          outline: none !important;
        }
      }

      .dashboard-widget-card-container {
        height: 110px !important;
        justify-content: space-between;
      }
    }

    .dashboard-reports-risk-active-campaigns-widget-body {
      .active-campaign-carousel-card-container {
        min-width: 270px !important;
        flex: 1;
      }
    }

    .dashboard-reports-risk-trends-widget-body {
      .dashboard-chart-card-container {
        height: 390px !important;
        max-height: 390px !important;
      }
    }
  }
}
