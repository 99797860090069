.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  * {
    font-family: 'HEX Franklin v0.2' !important;
  }

  .dashboard-page-header-side-element {
    @include page-header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 69px;
  }

  .page-body-container {
    flex: 1;
  }
}
