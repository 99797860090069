.filter-input {
  border: 1px solid $grey-5 !important;
  border-radius: 4px !important;
  height: 36px;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 1px solid black;
  }
}

.filter-input input {
  padding: 13px 4px 13px 0px !important;
  height: 40px;
}

.search-filter-input {
  height: 36px;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0 12px !important;

  * {
    font-family: 'HEX Franklin v0.2' !important;
  }

  fieldset {
    display: none !important;
  }
}

.input-clear {
  color: $purple-3;
  cursor: pointer;
}
