.button {
  border-radius: 8px !important;
  padding: 10px 16px !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1 !important;
  cursor: pointer !important;
}

.actionButton {
  background: #6664ed !important;
  border: 1px solid #6664ed !important;
  color: #ffffff !important;
}

.success {
  background: #5cc597 !important;
  border: 1px solid #5cc597 !important;
  color: #ffffff !important;
}

.success:disabled {
  background: #d4dbe2 !important;
  border-color: #d4dbe2 !important;
}

.fail {
  background: #d4dbe2 !important;
  border: 1px solid red !important;
  color: #ffffff !important;
}

.regular {
  border: 1px solid #d4dbe2 !important;
  background: #ffffff !important;
  color: #000000 !important;
}

.applyButton {
  background-color: #6664ed !important;
  border-radius: 7px !important;
  color: white !important;
  width: 80 !important;
  height: 42 !important;
  border: 1px solid #6664ed !important;
}

.applyButton:hover {
  background-color: white !important;
  color: #6664ed !important;
  border: 1px solid #6664ed !important;
  width: 80 !important;
  height: 42 !important;
}
