.opus-workflow-trigger-node-trigger-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  h3 {
    color: var(--Grey-1, #3d3d3d);
    font-family: 'HEX Franklin v0.2';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }

  .workflow-trigger-node-icon-container {
    border-radius: 16px;
    border: 1px solid var(--Purple-5, #c3aff2);
    background: var(--Purple-6, #ece7f9);
    width: 48px !important;
    height: 48px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      path {
        fill: var(--Purple-3, #7547dc);
      }
    }
  }
}

.opus-workflow-trigger-node-empty-trigger-container {
  padding: 8px;
  background: #ffffff;
  border: 1px dashed #7547dc;
  border-radius: 30px;
  gap: 8px;
}

.opus-workflow-trigger-node-selected-trigger-container {
  padding: 8px;
  background: #ffffff;
  border: none;
  border-radius: 30px;
  gap: 8px;
}

.opus-workflow-trigger-node-plus-button-container {
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
}

.opus-workflow-trigger-node-selected-items-counter {
  background: #ceeee0;
  border-radius: 40px;
  width: 29px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
