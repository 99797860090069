.alert-box-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--grey-1, #3d3d3d);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 6px;

  .alert-box-close-icon {
    position: absolute;
    right: 2%;
    top: 20%;
    cursor: pointer;
  }
}

.alert-box-error {
  background: rgba(255, 225, 230, 0.5);
}

.alert-box-success {
  background: var(--green-6, #dffef5);
}

.alert-box-warning {
  background: var(--yellow-6, #fef7dc);
}

.alert-expand-box-container {
  padding: 1rem;
  margin: 1rem 0;
  position: relative;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .alert-expand-box-title-area {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    .alert-expand-box-title-text {
      flex: 1;
    }
  }

  .alert-expand-box-title-expand {
    color: var(--purple-3, #7547dc);
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 588 !important;
    line-height: 18px !important;
    svg {
      path {
        fill: var(--purple-3, #7547dc);
      }
    }
  }

  .alert-expand-box-expanded-area {
    padding: 0 1.4rem;
    color: var(--grey-1, #3d3d3d);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .alert-expand-box-close-icon {
    position: absolute;
    right: 1%;
    top: 7%;
    cursor: pointer;
  }
}
