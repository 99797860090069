.campaign-findings-by-ticket-status-widget {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 4rem;

  .campaign-finding-by-ticket-status-widget-render-body {
    justify-self: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    border-radius: 24px;

    .campaign-finding-by-ticket-status-widget-render-body-cell {
      min-height: 37px;
      position: relative;
      z-index: 2;
      border-radius: 3px;
      margin-top: 80px;

      .campaign-finding-by-ticket-status-widget-render-body-cell-text {
        position: absolute;
        z-index: 100;
        top: 110%;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        color: $grey-1;
      }
    }
  }

  .campaign-finding-by-ticket-status-widget-legend {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .campaign-finding-by-ticket-status-widget-legend-cell {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      margin: 0px;
      padding: 0px;
      cursor: pointer;
      user-select: none;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $grey-1;

      .campaign-finding-by-ticket-status-widget-legend-cell-indicator {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
    }
  }
}
