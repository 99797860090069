.findings-table-view {
  height: inherit;

  .findings-table-pagination-container {
    border-top: 1px solid #e2ecf5;
    width: 100%;
    padding: 1rem;
  }

  .data-grid-table-container {
    border: 0;
  }

  .findings-risk-table-column-title-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
  }

  .data-grid-table {
    .findings-risk-table-row {
      .findings-risk-table-cell {
        min-width: 220px;
      }
    }
  }
}
