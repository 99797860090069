.dashboard-widget-card-container {
  background: $white;
  border: 1px solid $grey-5;
  border-radius: 8px;
  min-height: 110px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  user-select: none;
  flex: 1;
  cursor: pointer;

  h3,
  h5 {
    margin: 0;
  }

  .dashboard-widget-card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.7rem;

    .dashboard-widget-card-header-icon {
      background: $grey-6;
      border-radius: 8px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .dashboard-widget-card-header-text {
      .dashboard-widget-card-header-title {
        font-family: 'HEX Franklin v0.2';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: $grey-1;
      }
    }
  }

  .dashboard-widget-card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
