@import './workflow-builder-step-panel.scss';
@import './workflow-builder-crossroad-panel.scss';
@import './workflow-builder-trigger-panel.scss';

.workflow-builder-panel {
  .drawer-paper {
    width: 30% !important;
  }

  .drawer-paper-body {
    padding: 20px;
    border: 1px solid var(--Grey-5, #ebeaea);
  }

  .workflow-builder-panel-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .workflow-builder-panel-header {
      display: flex;
      align-items: center;
      gap: 15px;

      .workflow-builder-panel-header-icon {
        height: 48px;
        width: 48px;
        border-radius: 16px;
        border: 1px solid #ebeaea;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .workflow-builder-panel-header-text {
        padding: 0;
        margin: 0;
        color: var(--Grey-1, #3d3d3d);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .workflow-builder-panel-search-container {
      margin-top: 30px;
    }

    .workflow-builder-panel-input {
      width: 100%;

      .MuiInputBase-root {
        height: 40px;

        input {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          margin-left: 6px;

          &::placeholder {
            color: var(--Grey-3, #7f7f7f);
          }
        }

        fieldset {
          border-radius: 8px !important;
          border: 1px solid var(--Grey-5, #ebeaea) !important;
        }
      }
    }

    .workflow-builder-panel-body {
      flex: 1;
      overflow: auto;

      .workflow-builder-panel-body-loading {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .workflow-builder-panel-list {
        margin-top: 16px;

        .MuiListItemButton-root {
          .MuiListItemIcon-root {
            min-width: 25px !important;
          }
        }

        .workflow-builder-panel-list-item {
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          border-bottom: 1px solid #f5f5f5;

          &:hover {
            background: var(--Grey-7, #f9f9f9);
          }

          .workflow-builder-panel-list-item-counter {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--Grey-2, #5c5c5c);
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: 12px;
            border-radius: 8px;
            border: 1px solid var(--Grey-5, #ebeaea);
            background: var(--white, #fff);
            width: 24px;
            height: 24px;
          }

          .MuiListItemIcon-root {
            width: 32px !important;
            min-width: 32px !important;
            height: 32px;
            border-radius: 12px;
            border: 1px solid var(--Grey-5, #ebeaea);
            background: var(--white, #fff);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .MuiListItemText-root {
            color: var(--Gray-6, #3d3d3d);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            margin-left: 10px;
          }

          .MuiSvgIcon-root {
            fill: var(--Grey-1, #3d3d3d);
          }
        }
      }
    }

    .workflow-builder-panel-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .workflow-builder-panel-footer-end {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      .workflow-builder-panel-close-button {
        border: 1px solid var(--Grey-5, #ebeaea) !important;
        background: var(--White, #fff) !important;
        color: var(--Grey-1, #3d3d3d) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
      }

      button {
        padding: 10px 22px !important;
      }
    }
  }
}
