.workflow-builder-crossroad-panel {
  .workflow-builder-panel-header {
    .workflow-builder-panel-header-icon {
      svg {
        path {
          fill: var(--Grey-2, #5c5c5c);
        }
      }
    }

    .workflow-builder-panel-header-id {
      margin-left: auto;
      padding: 6px;
      border-radius: 4px;
      border: 1px solid var(--Grey-5, #ebeaea);
      background: #fff;
      color: var(--Grey-1, #3d3d3d);
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 13px;
    }

    .workflow-builder-panel-header-text-input-container {
      display: flex;
      gap: 6px;
      align-items: center;

      button {
        height: 25px;
        width: 25px;
      }

      svg {
        path {
          fill: var(--Grey-2, #5c5c5c);
        }
      }
    }
  }

  .workflow-builder-panel-body {
    padding-top: 30px;

    .crossroad-condition-route-list {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .crossroad-condition-route-list-add-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 14px;
        border: 1px dashed var(--Purple-5, #c3aff2);
        border-radius: 12px;
        cursor: pointer;
        user-select: none;

        .crossroad-condition-route-list-add-button-text {
          margin: 0;
          color: var(--Grey-1, #3d3d3d);
          font-size: 16px;
          font-style: normal;
          font-weight: 588;
          line-height: 20px;
        }

        .crossroad-condition-route-list-add-button-icon {
          width: 32px;
          height: 32px;
          border-radius: 6px;
          border: 1px solid var(--Grey-5, #ebeaea);
          background: var(--White, #fff);
          box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);

          svg {
            path {
              fill: var(--Grey-1, #3d3d3d);
            }
          }
        }
      }
    }

    .crossroad-condition-route {
      .crossroad-condition-route-accordion {
        width: 100%;
        border: 1px solid var(--Grey-5, #ebeaea);
        box-shadow: none !important;
        border-radius: 10px;

        .crossroad-condition-route-accordion-summary {
          &.Mui-focusVisible {
            background-color: #ffffff !important;
          }

          .MuiAccordionSummary-content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 20px;
          }

          .crossroad-condition-route-accordion-summary-text {
            display: flex;
            align-items: center;
            gap: 10px;

            .crossroad-condition-route-accordion-summary-text-title {
              color: var(--Grey-1, #3d3d3d);
              font-size: 16px;
              font-style: normal;
              font-weight: 588;
              line-height: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              text-wrap: nowrap;
              max-width: 150px !important;
            }

            .crossroad-condition-route-accordion-summary-text-default {
              color: var(--Purple-3, #7547dc);
              font-size: 13px;
              font-style: normal;
              font-weight: 588;
              line-height: 14px;
              padding: 5px 9px;
              border: 1px solid var(--Grey-5, #ebeaea);
              border-radius: 4px;
            }

            .crossroad-condition-route-accordion-summary-text-input {
              border-radius: var(--rounded, 4px) !important;
              border: 1px solid var(--Grey-5, #ebeaea) !important;
              background: var(--white, #fff) !important;
              height: 40px !important;
              max-width: 70% !important;
              color: var(--Grey-1, #3d3d3d);
              font-size: 16px;
              font-style: normal;
              font-weight: 588;
              line-height: 16px;
            }
          }

          .crossroad-condition-route-accordion-summary-button {
            width: 32px;
            height: 32px;
            border-radius: 6px;
            border: 1px solid var(--Grey-5, #ebeaea);
            background: var(--White, #fff);
            box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
          }
        }

        .crossroad-condition-list {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .crossroad-condition-sub-list {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }

          .crossroad-panel-condition-list-add-buttons {
            display: flex;
            gap: 10px;

            button {
              padding: 8px 14px !important;
              border-radius: 6px !important;
              border: 1px solid var(--Grey-5, #ebeaea) !important;
              background: var(--White, #fff) !important;
              box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05) !important;
              color: var(--Grey-1, #3d3d3d) !important;
              font-size: 13px;
              font-style: normal;
              font-weight: 588;
              line-height: normal;
              display: flex;
              gap: 3px;

              .crossroad-panel-condition-list-add-button-highlight-text {
                color: var(--Purple-3, #7547dc);
              }
            }
          }

          .crossroad-condition-and-operator {
            border-radius: 4px;
            width: 100%;
            background-color: var(--Grey-6, #f5f5f5);
            padding: 4px;
            color: var(--Grey-1, #3d3d3d);
            font-size: 13px;
            font-style: normal;
            font-weight: 588;
            line-height: 16px;
            text-align: center;
          }

          .crossroad-condition-or-operator {
            border-radius: 4px;
            width: 100%;
            background-color: var(--Grey-6, #f5f5f5);
            padding: 4px 10px;
            color: var(--Grey-1, #3d3d3d);
            font-size: 13px;
            font-style: normal;
            font-weight: 588;
            line-height: 16px;
            text-align: center;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;

            .crossroad-condition-or-operator-line {
              height: 1px;
              background-color: var(--Grey-4, #c7c4c4);
              flex: 1;
            }
          }

          .crossroad-condition-list-default {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .crossroad-condition-list-default-label {
              color: var(--Grey-1, #3d3d3d);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px;
            }
          }
        }

        .crossroad-panel-condition {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .crossroad-panel-condition-form {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .crossroad-panel-condition-form-input-group {
              display: flex;
              flex-direction: column;
              gap: 20px;

              .crossroad-panel-condition-form-input-label {
                color: var(--Grey-1, #3d3d3d);
                font-size: 14px;
                font-style: normal;
                font-weight: 588;
                line-height: 16px;
              }
            }

            .crossroad-panel-condition-form-select-group {
              display: flex;
              align-items: center;
              gap: 4px;

              .crossroad-panel-condition-form-select-label {
                color: var(--Grey-1, #3d3d3d);
                font-size: 14px;
                font-style: normal;
                font-weight: 588;
                line-height: 16px;
              }

              .crossroad-panel-condition-form-select {
                flex: 1;
                .auto-complete-container {
                  .MuiAutocomplete-root {
                    display: flex;
                    align-items: center;
                  }

                  .auto-complete-label-select {
                    display: none !important;
                  }

                  .MuiInputBase-root {
                    padding: 0 !important;
                    background-color: #ffffff !important;

                    input,
                    button {
                      background-color: #ffffff !important;
                    }

                    input {
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 14px;
                      padding: 0 !important;
                      color: var(--Purple-3, #7547dc) !important;
                      max-width: 33% !important;

                      &:hover + button + fieldset,
                      &:focus + button + fieldset {
                        border: none !important;
                        outline: none !important;
                      }
                    }

                    button {
                      height: 10px !important;
                      min-height: 10px !important;
                      margin-right: auto;
                      position: static !important;

                      svg {
                        path {
                          fill: var(--Purple-3, #7547dc);
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .crossroad-panel-condition-delete-buttons {
            button {
              width: 100%;
              padding: 8px !important;
              border-radius: 6px !important;
              border: 1px solid var(--Grey-5, #ebeaea) !important;
              background: var(--White, #fff) !important;
              box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05) !important;
              color: var(--Grey-1, #3d3d3d) !important;
              font-size: 13px;
              font-style: normal;
              font-weight: 588;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .workflow-builder-crossroad-panel-footer {
    display: flex;
    justify-content: space-between !important;

    .workflow-builder-crossroad-panel-footer-end-buttons {
      display: flex;
      gap: 10px;
    }
  }
}
