.risk-table-filter-panel-container {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .risk-table-filter-panel-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .risk-table-filter-panel-header-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.8rem;

      .risk-table-filter-panel-header-title-icon-button {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        background: #ece7f9;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .risk-table-filter-panel-header-title-text {
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        line-height: 31px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #31156f;
      }
    }

    .risk-table-filter-panel-header-controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      .risk-table-filter-panel-header-controls-apply-button {
        width: 75px;
        height: 44px;
        background: #7547dc;
        border: 1px solid #31156f;
        box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05);
        border-radius: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }

      .risk-table-filter-panel-header-controls-close-button {
        width: 40px;
        height: 44px;
        border: 1px solid #ffffff;
        box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05);
        border-radius: 8px;
      }
    }
  }
}

.risk-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .risk-page-filter-container {
    padding-top: 10px;

    .action-buttons {
      .action-base-button {
        font-size: 14px;
        color: $purple-3;
        border-radius: 0;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .risk-page-body {
    padding: 1rem;
    height: 100%;

    .risk-page-content-body {
      height: 100%;
      background: #ffffff !important;
      border: 1px solid #c7c7c7 !important;
      border-radius: 16px !important;

      .risk-page-main-container {
        height: 95% !important;

        .risk-page-main-table {
          height: inherit;
          padding: 1rem 2rem;
          padding-bottom: 0rem;
        }

        .risk-page-table-pagination-container {
          border-top: 1px solid #e2ecf5;
          width: 100%;
          padding: 1rem 2rem;
        }
      }
    }
  }

  .risk-page-table-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0.6rem;

    .risk-page-table-base-button {
      width: 159px !important;
      height: 40px !important;
      background: #ffffff !important;
      border: 1px solid #7547dc !important;
      box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05) !important;
      border-radius: 8px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 13px !important;
      line-height: 16px !important;
      color: #7547dc !important;

      svg {
        fill: #7547dc;
      }

      &:disabled {
        border: 1px solid #beace9 !important;
        color: #beace9 !important;

        svg {
          fill: #beace9;
        }
      }
    }

    .risk-page-table-header-filters-button {
      width: 120px !important;
      height: 40px !important;
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 10px 16px !important;
      gap: 8px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 13px !important;
      line-height: 16px !important;
      color: #ffffff !important;
    }
  }
}

.risk-page-table-container {
  min-height: 70vh !important;
  border: none !important;
  overflow: hidden;

  .risk-page-table-column-sort-icon {
    display: block;
  }

  .risk-table-icon-button {
    border: 1px solid #7547dc;
    box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05);
    border-radius: 8px;
    width: 32px !important;
    height: 32px;
    min-width: 0px !important;
    background-color: #ffffff;
    cursor: pointer;

    &:disabled {
      cursor: default;
      border: 1px solid #beace9 !important;
      color: #beace9 !important;

      svg {
        fill: #beace9;
      }
    }
  }

  .risk-page-table-column-header-container {
    background: #f0f5ff;
    border-radius: 16px;
    border: none;
    padding: 0rem 2rem;

    .risk-page-table-column-separator {
      display: none;
    }

    .risk-page-table-column-header {
      width: 100% !important;
    }

    .risk-page-table-column-title {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #31156f;
    }

    .risk-page-table-column-title-sort {
      outline: none !important;
      border: none !important;

      button {
        visibility: visible;

        &:hover {
          background: transparent !important;
        }

        span {
          display: none !important;
        }
      }
    }

    .risk-page-table-column-title-sort-icon-container {
      .risk-page-table-column-title-sort-icon-container-placeholder-box {
        height: 10px;
      }
    }
  }

  .risk-page-table-column-title-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
  }

  .risk-page-table {
    .risk-page-table-row:hover {
      background: #f0f5ff;
    }

    .risk-page-table-row-container {
      background: #ffffff;
      border: 1px solid #787878;
      border-radius: 16px;
      transition: all 200ms;
      overflow: hidden;
      cursor: pointer;
      width: 100%;

      .risk-page-table-row-expansion-area {
        transition: all 200ms;
      }

      .risk-page-table-row-expansion-area-active {
        min-height: 200px;
      }

      .risk-page-table-row-expansion-area-not-active {
        display: none;
      }

      .risk-page-table-row {
        display: grid;
        grid-template-columns: 480px 280px 250px 200px 150px auto;
        align-items: center;
        width: 100%;
        padding: 1rem 1.5rem;

        .risk-page-table-row-expansion-icon {
          margin-right: 1rem;
          cursor: pointer;
          transition: transform 200ms;
          position: absolute;
          left: 15px;
        }

        .risk-page-table-row-expansion-icon-open {
          transform: rotateZ(-90deg);
        }

        .risk-page-table-row-risk-cell {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
          padding-left: 1rem;

          .risk-page-table-row-risk-cell-main-container {
            display: flex;
            flex-direction: row;
            gap: 2px;

            .risk-page-table-row-risk-cell-main {
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 16px;
              color: #525252;
              max-width: 430px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .risk-page-table-row-risk-cell-counter {
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: #525252;
            }
          }
        }

        .risk-page-table-row-security-standard-cell {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #525252;
        }

        .risk-page-table-row-actions-cell {
          display: flex;
          flex-direction: row;
          justify-content: end;
        }

        .risk-page-table-row-code-vs-cloud-cell {
          display: flex;
          flex-direction: column;
          gap: 3px;

          .risk-page-table-row-code-vs-cloud-cell-counter-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 136px;

            .risk-page-table-row-code-counter,
            .risk-page-table-row-cloud-counter {
              font-weight: 400;
              font-size: 13px;
              color: #c7c4c4;
            }
          }

          .risk-page-table-row-code-vs-cloud-cell-bar {
            width: 136px;
            height: 8px;
            border-radius: 6px;
            display: flex;
            flex-direction: row;
            overflow: hidden;

            .risk-page-table-row-code-cell-bar {
              background: #67e0bd;
              height: 100%;
            }

            .risk-page-table-row-cloud-cell-bar {
              background: #7547dc;
              height: 100%;
            }
          }
        }
      }
    }

    .risk-page-table-virtual-scroller {
      padding: 2rem 0rem;

      .risk-page-table-virtual-scroller-render-zone {
        min-width: 100%;
        width: inherit;
        display: flex;
        flex-direction: column;
        justify-self: stretch;
        gap: 1.5rem;
      }
    }
  }
}

.risk-page-body-container {
  padding: 2rem 1.4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  .risk-page-body-control-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .actions-list {
      display: flex;
      align-items: center;
      gap: 20px;
      .duplication-toggle {
        display: flex;
        align-items: center;
        gap: 6px;
      }

      .csv-export,
      .campaign-btn,
      .import-btn {
        height: 32px;
        border-radius: 6px;
        border: 1px solid var(--Grey-5, #ebeaea);
        background: var(--White, #fff);
        padding: 0 14px;

        display: flex;
        align-items: center;
        gap: 5px;
        box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);

        p {
          color: var(--Grey-1, #3d3d3d) !important;
          font-weight: 588 !important;
        }
      }

      .campaign-btn {
        &:disabled {
          .header-8 {
            color: #7f7f7f !important;
          }

          svg {
            path {
              fill: #7f7f7f;
            }
          }
        }
      }
    }
  }

  .risk-page-body-table {
    flex: 1;
  }
}

.campaign-modal-cancel-button {
  background: #ffffff !important;
  border: 1px solid $purple-3 !important;
  box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05) !important;
  border-radius: 6px !important;
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: $purple-3 !important;
}

.campaign-modal-create-button {
  background: $purple-3 !important;
  border: 1px solid $purple-3 !important;
  box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05) !important;
  border-radius: 6px !important;
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ffffff !important;
}

.setting-modal-title {
  color: $grey-1 !important;
  padding: 12px 35px;
}

.risk-action-response-container {
  max-width: 40vw;
  position: relative;

  .MuiAlert-icon {
    display: none;
  }
  .MuiAlert-message {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    span {
      max-width: 65vw;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .risk-action-response-close-icon {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    height: 30px;
    width: 30px;
    padding: 0 !important;

    svg {
      path {
        fill: #c7c4c4;
      }
    }
  }
}

.risk-view-clear-button {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: $purple-3 !important;

  &:hover {
    background: transparent !important;
  }

  .MuiTouchRipple-root {
    display: none;
  }
}

.risk-view-snackbar {
  margin-bottom: 4rem;
}

.risk-page-ai-filter-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e2e2;
  filter: drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.1));
  background: #ffffff;
  padding: 16px 23px;
  border-radius: 10px;
  width: 80%;
  margin: auto;
  gap: 10px;

  .risk-page-ai-filter-title-area {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .risk-page-ai-filter-title {
      color: var(--Grey-1, #3d3d3d);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .risk-page-ai-filter-helper-text {
      color: var(--Grey-3, #7f7f7f);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .risk-page-ai-filter-search-area {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .filter-main-input {
      width: 100%;

      .MuiInputBase-root {
        width: 100%;
      }
    }
  }
}

.campaign-live-tooltip-text {
  color: #3d3d3d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
