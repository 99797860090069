.action-menu-list-container {
  .action-menu-list-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.8rem 1rem;
    gap: 1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    transition: all 200ms;

    &:hover {
      background: #f0f5ff;
    }
  }

  .action-menu-list-item-container-disabled {
    opacity: 0.5;

    &:hover {
      background: #ffffff;
    }
  }

  .azure-devops-menu-item-icon {
    height: 23px;
    width: 23px;
  }
}
