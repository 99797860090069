.dashboard-section-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  * {
    font-family: 'HEX Franklin v0.2' !important;
  }

  .dashboard-section-header {
    display: flex;
    gap: 0.5rem;
    min-height: 40px;

    align-items: center;
    justify-content: space-between;

    .dashboard-section-header-title {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      color: var(--grey-1, #3d3d3d);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;

      svg {
        max-width: 18px;
        max-height: 18px;

        path {
          fill: $grey-1;
        }
      }
    }
  }
}
