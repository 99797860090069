.material-list-container {
  max-width: 100%;
  max-height: 70vh;
  overflow: auto;
}

.material-list-item-button {
  padding: 0.5rem 0;
}

/* width */
.material-list-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.material-list-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.material-list-container::-webkit-scrollbar-thumb {
  background: #cdd7e2;
  border-radius: 6px;
}
