.slaChip {
  border-radius: 5px !important;
  border: 1px solid !important;

  &.overDue {
    background-color: #e3000050;
    color: #e30000;
    border-color: #e30000;
  }

  &.onTime {
    background-color: #23cd9c50;
    color: #23cd9c;
    border-color: #23cd9c;
  }

  .MuiChip-icon {
    color: inherit !important;
    font-size: 15px;
  }
  .MuiChip-label {
    font-weight: 600;
  }
}
