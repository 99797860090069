.azure-integration-subscription-section-container {
  .azure-integration-subscription-section-content {
    padding-top: 20px;

    .azure-integration-subscription-multiple {
      display: flex;
      flex-direction: column;

      .azure-integration-subscription-multiple-title {
        color: var(--Grey-1, #3d3d3d);
        font-size: 14px;
        font-style: normal;
        font-weight: 588;
        line-height: 14px;
        padding: 0 !important;
        margin: 0 !important;
        margin-bottom: 15px !important;
      }

      .azure-integration-subscription-script-download-input,
      .azure-integration-subscription-management-group-id-input {
        margin-bottom: 30px;
      }

      .azure-integration-subscription-script-download-input,
      .azure-integration-subscription-script-input {
        position: relative;

        button {
          position: absolute;
          right: 15px;
          top: 12px;
          padding: 0 !important;
          width: 16px;
          height: 16px;

          svg {
            width: 16px;
            height: 16px;

            path {
              fill: var(--Grey-3, #7f7f7f) !important;
            }
          }
        }

        input,
        textarea {
          border: 1px solid var(--Grey-5, #ebeaea) !important;
          background-color: var(--Grey-7, #f9f9f9) !important;
          border-radius: 8px;
          color: var(--Grey-3, #7f7f7f);
        }

        input {
          padding-right: 2rem !important;
        }

        textarea {
          &::-webkit-scrollbar-track {
            background-color: var(--Grey-7, #f9f9f9) !important;
          }
        }
      }
    }
  }
}
