.iconLink {
  display: flex;
  flex-direction: column;
}

.menuIconText {
  font-family: 'HEX Franklin v0.2';
  font-style: normal;
  font-weight: 588;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #614f94;
}
