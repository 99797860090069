@import './colors';

.opus-workflow-trigger-panel {
  width: 30rem;
  padding: 2rem 1.5rem;
  min-height: 88vh;
  background: #ffffff;
  box-shadow: 0px 0px 14px rgba(158, 140, 233, 0.24);
  border-radius: 19px;
  position: relative;
}

.opus-workflow-trigger-panel-list {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.opus-workflow-trigger-panel-list-item {
  background: #f5f8fd;
  padding: 12px;
  border-radius: 10px;
}

.opus-workflow-trigger-panel-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 2rem;
}

.opus-workflow-trigger-header-title {
  font-weight: bold;
}

.opus-workflow-trigger-header-close-icon {
  position: absolute;
  right: 1.5rem;
}

.opus-workflow-trigger-header-close-icon path {
  fill: #748495;
}

.opus-workflow-trigger-panel-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #cdd7e2;
  padding: 16px 24px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

.opus-workflow-trigger-panel-footer-button {
  width: 75px;
  height: 44px;
}

.opus-workflow-trigger-panel-search {
  margin-bottom: 1rem;
}

.opus-workflow-trigger-panel-search-input {
  width: 100%;
  background-color: #f2f7fc;
  border-radius: 7px;
}

.opus-workflow-trigger-panel-body {
  max-width: 100%;
  min-height: 55vh;
  max-height: 60vh;
  overflow: auto;
}

.opus-workflow-trigger-panel-body-loading {
  width: 100%;
  height: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.opus-workflow-trigger-panel-footer-button {
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  box-sizing: border-box;
  cursor: pointer;
}

.opus-workflow-trigger-panel-footer-close-button {
  border: 1px solid #d4dbe2;
  background-color: #ffffff;
}

.opus-workflow-trigger-panel-footer-save-button {
  background: #6664ed;
  border-radius: 8px;
  color: #ffffff;
  border: none;
}

.opus-workflow-trigger-panel-list-item-counter {
  width: 29px;
  height: 24px;
  background: #ceeee0;
  border-radius: 40px;
  text-align: center;
  font-weight: bold;
}

/* width */
.opus-workflow-trigger-panel-body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.opus-workflow-trigger-panel-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.opus-workflow-trigger-panel-body::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 6px;
  border: 0.1px solid rgba(205, 215, 226, 0.3);
}

@media screen and (min-height: 1200px) {
  .opus-workflow-trigger-panel-body {
    max-height: 68vh;
  }
}

@media screen and (min-height: 800px) and (max-height: 1000px) {
  .opus-workflow-trigger-panel-body {
    max-height: 56vh;
  }
}
