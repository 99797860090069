.item-with-icon-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;

  .avatar-container {
    padding: 0.3rem;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: inherit !important;
  }

  .bordered {
    border-radius: 8px;
    border: 1px solid #e2e2e2;
  }

  .text-container {
    text-align: left;
    .title {
      .body-1 {
        color: $grey-1;
        margin: 0;
      }
    }

    .description {
      .body-3 {
        color: $grey-3;
        margin: 0;
      }
    }
  }
}
