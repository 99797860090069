.finding-detail-popover {
  .drawer-paper {
    width: 75%;
  }
}

.finding-content-header {
  .finding-content-header-title-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .finding-content-header-icon {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      border: 1px solid var(--Grey-5, #ebeaea);

      img {
        max-height: 30px;
        max-width: 30px;
      }
    }

    .finding-content-header-title {
      color: var(--Grey-1, #3d3d3d);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }

    #copy-link-button-root {
      margin-left: auto;
    }
  }

  .finding-content-header-elements {
    border: 1px solid #ebeaea;
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 12px 20px;
    margin: 10px 0;
  }

  .finding-content-header-actions {
    margin: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    gap: 10px;
  }

  .text-label {
    font-size: 13px;
    line-height: 13px;
    color: #7f7f7f;
    font-weight: 400;
  }

  .text-display {
    font-size: 13px;
    line-height: 13px;
    color: #3d3d3d;
    font-weight: 400;
  }

  .element-with-label {
    display: flex;
    gap: 6px;
    align-items: center;
    width: fit-content;

    > div > svg {
      height: 13px;
    }
  }

  .main-content-skeleton {
    min-height: 148px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .updates-info-container {
    flex: 1;
    display: flex;
    gap: 25px;
    justify-content: end;
  }
}

.finding-details-page-not-found-page-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .finding-details-page-not-found {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 10px;

    svg {
      height: 72px;
      width: 72px;
    }

    h1,
    h2 {
      margin: 0;
      text-align: center;
    }

    h1 {
      font-family: 'HEX Franklin v0.2';
      font-size: 24px;
      font-weight: 800;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      color: #3d3d3d;
    }

    h2 {
      font-family: 'HEX Franklin v0.2';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      color: #7f7f7f;
    }
  }
}

.finding-content-container {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  height: 100vh;
}

.finding-content-header-and-tabs {
  padding: 1rem 1.4rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 12;
  width: 100%;

  .related-finding-warning {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 40px;
    background: var(--Grey-7, #f9f9f9);
    padding: 0 22px;

    svg {
      width: 17px;
      path {
        fill: $grey-1;
      }
    }
    .label-4:not(.check-info-btn) {
      color: var(--Grey-1, #3d3d3d);
      font-weight: 400 !important;
    }
    .check-info-btn {
      color: var(--Purple-3, #7547dc);
    }
  }
}

.no-data-container {
  height: 110px;
}

.interactive-label {
  gap: 0.5rem;

  .interactive-label-text {
    a {
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-decoration-line: underline;
    }
  }

  svg {
    path {
      fill: var(--Grey-1, #3d3d3d);
    }
  }
}

.icon-box {
  .icon-box-text {
    gap: 0.3rem;
  }

  .icon-box-label {
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.finding-content-resource-logo {
  width: 28px;
}

.finding-content-body {
  padding: 0 1.4rem;
  position: relative;

  .finding-content-details-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .content-section-body {
    margin-top: 10px;
    padding: 10px 0;
  }
}

.finding-content-section {
  padding: 0.7rem 1.2rem;
  border: 1px solid var(--Grey-5, #ebeaea);
  border-radius: 10px;
}

.content-section-header {
  display: flex;
  gap: 0.5rem;
}

.content-section-title {
  color: var(--Grey-1, #3d3d3d);
  font-size: 16px;
  font-style: normal;
  font-weight: 588;
  line-height: 16px;
}

.finding-content-section-body {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .finding-content-sub-section {
    padding-bottom: 1rem;
  }

  .finding-content-sub-section:last-child {
    padding-bottom: 0;
  }
}

.finding-content-section-icon {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--Grey-5, #ebeaea);
  border-radius: 10px;
}

.finding-content-sub-section {
  border-bottom: 1px solid var(--Grey-5, #ebeaea);

  .content-section-title {
    color: var(--Grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 13px;
  }
}

.finding-content-sub-section:last-child {
  border-bottom: none !important;
}

.finding-content-status-section-body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.finding-item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0.8rem;

  .finding-item-title {
    color: var(--Grey-3, #7f7f7f);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
  }

  .finding-item-body {
    p {
      padding: 0;
      margin: 0;
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.finding-content-status-last-activity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--Grey-5, #ebeaea);
  border-radius: 6px;
  padding: 10px 16px;

  .finding-content-status-last-activity-author {
    display: flex;
    gap: 0.6rem;
    align-items: center;

    .finding-content-status-last-activity-author-text {
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .finding-content-status-last-activity-date {
    svg {
      color: var(--Grey-3, #7f7f7f);
      width: 13px;
      height: 13px;
    }

    span {
      color: var(--Grey-1, #3d3d3d);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.finding-content-environment-sub-section-body {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2px;

  .finding-item {
    flex: 1;
  }
}

.finding-content-riskAttributes-sub-section-body {
}

.finding-content-resource-sub-section-body {
  display: flex;
  flex-direction: column;

  .finding-content-resource-sub-section-additonal-properties-row {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #ebeaea;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 24px;
  }

  .finding-content-resource-sub-section-main-row {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-wrap: wrap;
    gap: 8px;
    overflow: hidden;

    .finding-item {
      flex: 1;
      overflow: hidden;

      .icon-box {
        .icon-box-icon {
          overflow: visible;
        }

        .icon-box-text {
          width: 100%;

          .icon-box-label {
            max-width: 100%;
            line-height: 15px;
          }

          .icon-box-helper-text {
            line-height: 13px;
          }
        }
      }

      .finding-item-body {
        div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .finding-content-resource-item-body-collapsable-text {
      padding: 0;
      margin: 0;
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.finding-content-resource-sub-section-tags-row {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ebeaea;
  margin-top: 15px;
  margin-bottom: 15px;

  .finding-item-body {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
}

.finding-content-section-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  padding-right: 5rem;
}

.finding-section-resource-tag {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  padding: 5px 9px;
  border: 1px solid var(--Grey-5, #ebeaea);
  border-radius: 4px;
  color: var(--Grey-3, #7f7f7f);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  height: 24px;

  .finding-section-resource-tag-value {
    color: var(--Grey-1, #3d3d3d);
    font-weight: 588;
  }
}

.finding-content-link {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 0.3rem;
  color: var(--Purple-3, #7547dc);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  text-decoration: none;

  svg {
    path {
      fill: var(--Purple-3, #7547dc);
    }

    transform: rotateZ(180deg);
  }
}

.finding-content-cloud-to-code-sub-section-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.finding-content-table-body-loading {
  .ag-root-wrapper-body {
    min-height: 300px;
  }

  .finding-content-table-body-loading-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.finding-content-table-body {
  .finding-content-table-body-loading-wrapper {
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-root-wrapper {
    padding: 0;
    border: none;
  }

  .ag-body-viewport {
    max-height: 540px !important;
    overflow-y: auto;
  }

  .ag-center-cols-clipper {
    min-height: 0 !important;
  }

  .ag-cell {
    p {
      width: inherit;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.finding-content-grid-wrapper {
  .ag-root-wrapper {
    padding: 0;
    border: none;
  }

  .ag-center-cols-clipper {
    min-height: 0 !important;
  }
}

.finding-content-application-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 20px;
  }
}

.finding-content-resource-logo {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--Grey-5, #ebeaea);

  img {
    width: 16px;
  }
}

.finding-content-workspace-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 28px;
  }
}

.finding-section-resource-tag-show-more {
  color: var(--Purple-3, #7547dc) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  padding: 0 !important;
}

.finding-content-source-information-sensor-finding-id-container {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--Grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
  }

  button {
    width: 14px;
    height: 14px;
    margin-left: 8px;

    svg {
      width: 14px;
    }
  }
}

.finding-owners-email-cell {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .finding-owners-email-cell-is-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--Grey-5, #ebeaea);
    padding: 5px 9px;
    height: 24px;
    border-radius: 4px;
    color: var(--Purple-3, #7547dc);
    font-size: 13px;
    font-style: normal;
    font-weight: 588;
    line-height: 14px;
  }
}

.finding-content-no-data {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.finding-content-description-container {
  color: var(--Grey-1, #3d3d3d);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.finding-content-description-read-more {
  cursor: pointer;
  gap: 0.3rem;
  color: var(--Purple-3, #7547dc);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-left: 4px;
}

.finding-content-workflow-container {
  border-radius: 12px;
  border: 1px solid $grey-5;
  overflow: hidden;
  position: relative;
  max-height: 70vh;

  .finding-content-workflow-select-container {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 12;

    .findingWorkflows {
      border: none !important;
    }

    .workflow-select-container {
      max-width: 230px;
      padding-right: 14px;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
      border: none !important;

      .MuiSelect-select {
        padding: 6px 14px;

        p {
          font-weight: 500;
          color: $grey-1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .finding-content-workflow-refresh-container {
    position: absolute;
    left: 1rem;
    top: 1rem;
    z-index: 12;

    button {
      padding-right: 14px;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
      border: 1px solid $grey-5;
    }
  }
}

.finding-content-additional-data-container {
  .finding-content-additional-data-icon,
  .finding-content-additional-data-icon img {
    max-height: 24px;
  }

  .collapsable-sub-section {
    width: 100% !important;
    border-radius: 0;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $grey-5;
  }

  .collapsable-sub-section .MuiAccordionSummary-content {
    margin: 0;
  }

  .collapsable-sub-section .MuiAccordionSummary-content.Mui-expanded {
    margin-bottom: 24px;
  }

  .collapsable-sub-section:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  .content-section-body .MuiPaper-root:before {
    background: none;
  }

  .widget-header {
    display: inline-flex;
    gap: 9px;
    align-items: center;
  }

  .widget-header-text {
    font-size: 13px !important;
    line-height: 13px;
    font-weight: 600;
    color: $grey-1;
  }

  .widget-header-title-app-prefix {
    font-size: 13px !important;
    line-height: 13px;
    color: $grey-3;
    font-weight: 400;
    margin-right: -6px;
  }

  .finding-content-section-row.finding-content-key-val-widget {
    gap: 24px 0;
    padding: 0;
    justify-content: flex-start;
  }

  .finding-content-key-val-widget .finding-item {
    flex: 0 25%;
  }

  .finding-content-json-tree-text-area {
    position: relative;

    textarea {
      border: 1px solid #ebeaea;
      border-radius: 8px;
      padding: 8px;
      height: 210px;
      overflow: scroll;
      resize: none;
      width: 100%;
      font-size: 13px !important;
      color: #7f7f7f;
    }

    .copyButton {
      position: absolute;
      right: 12px;
      z-index: 20;
    }
  }

  .finding-content-additional-data-accordion-summary {
    padding: 0 !important;
  }
}

.finding-content-actions-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid var(--Purple-3, #7547dc);
  border-radius: 10px;
  box-shadow: 0px 8px 12px rgba(210, 195, 245, 0.5);
  padding: 25px 20px;

  .finding-content-actions-title-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .finding-content-actions-title-text {
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 588;
      line-height: 13px;
    }

    .finding-content-actions-title-icon {
      svg {
        width: 16px;
        height: 13px;
      }
    }
  }

  .finding-content-actions-body {
    background: var(--Grey-7, #f9f9f9);
    border: 1px solid var(--Grey-5, #ebeaea);
    border-radius: 10px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    .finding-content-actions-body-title {
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 588;
      line-height: 13px;
    }

    .finding-content-actions-body-description {
      color: var(--Grey-1, #3d3d3d);
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
    }

    .finding-content-actions-body-selected-options {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .finding-content-actions-body-selected-option {
        display: flex;
        align-items: center;
        gap: 0.3rem;

        .finding-content-actions-body-selected-option-icon {
          path {
            fill: #0fae7f;
          }
        }

        .finding-content-actions-body-selected-option-text {
          color: var(--Grey-1, #3d3d3d);
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 13px;
        }
      }
    }

    .finding-content-actions-body-options {
      margin-top: 0.5rem;
    }
  }

  .finding-content-actions-footer {
    width: 100%;
    display: flex;
    justify-content: end;
  }
}

.finding-content-radio-button-group {
  display: flex;
  flex-direction: row !important;
  gap: 1rem;
  padding-left: 0.6rem;

  svg[data-testid='RadioButtonUncheckedIcon'] {
    fill: #c7c4c4;
    height: 20px;
    width: 16px;
  }

  svg[data-testid='RadioButtonCheckedIcon'] {
    fill: #7547dc;
    height: 20px;
    width: 16px;
  }

  .MuiTouchRipple-root {
    display: none !important;
  }

  .MuiTypography-root {
    color: var(--Gray-6, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
  }

  span {
    padding: 0 !important;
    padding-right: 0.2rem !important;
  }

  input,
  span {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.finding-content-risk-score-breakdown-container {
  .finding-content-risk-score-breakdown-body {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
  }

  .finding-content-risk-score-breakdown-item {
    display: grid;
    grid-template-columns: 150px auto;
    align-items: start;
    border-left: 1px solid var(--Grey-5, #ebeaea);
    padding-bottom: 20px;
    column-gap: 30px;

    .finding-content-risk-score-breakdown-item-category-container {
      justify-self: start;
      position: relative;
      width: 100%;
      height: 100%;

      .finding-content-risk-score-breakdown-item-category {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        padding: 8px 10px;
        border: 1px solid var(--Grey-5, #ebeaea);
        border-radius: 10px;
        background-color: #ffffff;
        z-index: 2;
        position: absolute;
        top: 0;
        left: -19px;
        max-height: 38px;

        .finding-content-risk-score-breakdown-item-category-text {
          color: var(--Grey-1, #3d3d3d);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
        }

        svg {
          path {
            fill: #3d3d3d;
          }
        }
      }

      .finding-content-risk-score-breakdown-item-category-blank-space {
        z-index: 1;
        position: absolute;
        top: 3px;
        left: -20%;
        width: 100%;
        height: 26px;
        background-color: #ffffff;
      }
    }

    .finding-content-risk-score-breakdown-item-body {
      padding-bottom: 20px;
      padding-top: 0.2rem;
      border-bottom: 1px solid var(--Grey-5, #ebeaea);
    }
  }

  .finding-content-risk-score-breakdown-item:last-child {
    border-left: 0;
    padding-bottom: 0;

    .finding-content-risk-score-breakdown-item-body {
      border-bottom: none;
      padding-bottom: 15px;
    }
  }

  .finding-content-risk-score-breakdown-item-category-row {
    display: flex;
    gap: 0.7rem;
    align-items: center;
    width: 100%;

    .finding-content-risk-score-breakdown-item-category-row-title {
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }

    .finding-content-risk-score-breakdown-item-category-severity {
      display: flex;
      gap: 0.6rem;
      align-items: center;

      .finding-content-risk-score-breakdown-item-category-severity-title {
        color: var(--Grey-1, #3d3d3d);
        font-family: 'HEX Franklin v0.2';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
      }
    }

    .finding-content-risk-score-breakdown-criticality-container,
    .finding-content-risk-score-breakdown-attribute-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .factor-item {
        border: 1px solid var(--Gray-2, #e2e2e2);
        border-radius: 4px;
        padding: 2px 9px;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .info {
        padding: 6px 7px;
        border-radius: 4px;
        border: 1px solid $green-6;
      }
      .warning {
        padding: 6px 7px;
        border-radius: 4px;
        border: 1px solid var(--red-100, #fde8e8);
      }
    }

    .finding-content-risk-score-breakdown-item-category-application {
      display: flex;
      gap: 0.4rem;
      align-items: center;

      .finding-content-risk-score-breakdown-item-category-application-indicator {
        color: var(--Grey-3, #7f7f7f);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
      }

      .finding-content-risk-score-breakdown-item-category-application-name {
        color: var(--Grey-1, #3d3d3d);
        font-size: 13px;
        font-style: normal;
        font-weight: 588;
        line-height: 13px;
      }

      .finding-content-risk-score-breakdown-item-category-application-logo {
        display: flex;
        align-items: center;

        img {
          width: 19px;
        }
      }
    }
  }
  .finding-content-risk-score-breakdown-item-category-intelligence {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .finding-content-risk-score-breakdown-item-category-intelligence-row {
      display: flex;
      gap: 0.6rem;
      align-items: center;

      .rating,
      .trend-up {
        border-radius: 6px;
        border: 1px solid var(--Purple-6, #ece7f9);
        color: var(--Purple-3, #7547dc);
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        path {
          fill: var(--Purple-3, #7547dc) !important;
        }
        p {
          color: var(--Purple-3, #7547dc) !important;
        }
      }
    }

    .finding-content-risk-score-breakdown-item-category-intelligence-tag-container {
      border: 1px solid var(--Gray-5, #e2e2e2);
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      padding: 10px 15px;
      gap: 17px;

      .row {
        display: grid;
        grid-template-columns: 110px auto;
        align-items: center;

        .list {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;

          .MuiStack-root {
            display: flex;
            align-items: center;
            padding: 5px;
            border-radius: 4px;
            border: 1px solid var(--Gray-2, #e2e2e2);
            background: var(--White, #fff);

            svg {
              height: 13px;
              width: 13px;
              path {
                fill: $grey-1;
              }
            }
          }
        }
      }
    }
  }

  .finding-content-risk-score-breakdown-item-row-container {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    .finding-content-risk-score-breakdown-item-row-item-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }

    .external-item {
      border: 1px solid var(--Gray-2, #e2e2e2);
      border-radius: 4px;
      padding: 2px 9px;
      display: flex;
      align-items: center;
      gap: 10px;
      width: fit-content;
      min-height: 30px;
    }

    .finding-content-ssvc {
      display: flex;
      flex-direction: column;
      position: relative;
      background-color: transparent;
      border: none;
      border-radius: 4px;
      padding: 0;
      width: 100%;

      .divider {
        border-bottom: 1px solid #ccc;
        width: 96%;
        position: absolute;
        top: 20px;
        left: 20px;
      }
      .finding-content-ssvc-flow-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .ssvc-item-icon {
          position: relative;
        }

        .ssvc-item-cell:last-child .horizontal-line {
          display: none;
        }

        .ssvc-item-cell {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          gap: 1px;
          cursor: pointer;

          .ssvc-item-icon-container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 50px;
            height: 50px;
            z-index: 1;
            background-color: #fcfcfc;

            .ssvc-item-icon {
              &:hover {
                border-radius: 10px;
                border: 1px solid var(--Grey-4, #c7c4c4);
                background: var(--Grey-6, #f5f5f5);
                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
              }
              width: 40px;
              height: 40px;
              border-radius: 10px;
              border: 1px solid var(--Grey-4, #c7c4c4);
              background: #fff;
              box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            }
          }
          .ssvc-title {
            color: var(--Grey-1, #3d3d3d);
            text-align: center;
            font-family: 'HEX Franklin v0.2';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px; /* 114.286% */
          }
          .ssvc-item-value {
            margin-top: 5px;
            border-radius: 4px;
            border: 1px solid var(--Grey-5, #ebeaea);
            background: #fff;
            color: var(--Grey-3, #7f7f7f);
            font-family: 'HEX Franklin v0.2';
            font-size: 12px;
            font-style: normal;
            font-weight: 588;
            padding: 1px 11px;
            text-transform: capitalize;
          }
          p {
            margin: 0;
          }
        }

        .ssvc-item-decision-green {
          .ssvc-item-icon {
            border-color: var(--Green-2, #0fae7f) !important;
            background: rgba(223, 254, 245, 0.5) !important;

            svg {
              path {
                fill: var(--Green-2, #0fae7f);
              }
            }
          }
          .ssvc-item-value {
            border-color: var(--Green-2, #0fae7f);
            background: rgba(223, 254, 245, 0.5) !important;
            color: var(--Green-2, #0fae7f);
          }
        }

        .ssvc-item-decision-red {
          .ssvc-item-icon {
            border-color: var(--Red-3, #e02b4b) !important;
            background: rgba(255, 225, 230, 0.5) !important;

            svg {
              path {
                fill: var(--Red-3, #e02b4b);
              }
            }
          }
          .ssvc-item-value {
            border-color: var(--Red-3, #e02b4b);
            background: rgba(255, 225, 230, 0.5) !important;
            color: var(--Red-3, #e02b4b);
          }
        }

        .ssvc-item-decision-orange {
          .ssvc-item-icon {
            border-color: var(--Orange-2, #c77a32) !important;
            background: rgba(255, 233, 218, 0.5) !important;

            svg {
              path {
                fill: var(--Orange-2, #c77a32);
              }
            }
          }
          .ssvc-item-value {
            border-color: var(--Orange-2, #c77a32);
            background: rgba(255, 233, 218, 0.5) !important;
            color: var(--Orange-2, #c77a32);
          }
        }

        .ssvc-item-decision-yellow {
          .ssvc-item-icon {
            border-color: var(--Yellow-2, #cdad37) !important;
            background: rgba(254, 247, 220, 0.5) !important;

            svg {
              path {
                fill: var(--Yellow-2, #cdad37);
              }
            }
          }
          .ssvc-item-value {
            border-color: var(--Yellow-2, #cdad37);
            background: rgba(254, 247, 220, 0.5) !important;
            color: var(--Yellow-2, #cdad37);
          }
        }
      }
    }
    .ssvc-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;
      margin-top: 20px;
      .ssvc-modal-btn {
        display: flex;
        align-items: center;
        gap: 5px;
        margin: 0;
        color: var(--Grey-1, #3d3d3d) !important;
        font-family: 'HEX Franklin v0.2';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;

        path {
          fill: var(--Grey-1, #3d3d3d) !important;
        }
      }
      .ssvc-doc {
        display: flex;
        align-items: center;
        gap: 5px;
        a {
          margin: 0;
          color: var(--Grey-1, #3d3d3d);
          font-family: 'HEX Franklin v0.2';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
  .finding-content-score-icon {
    .info {
      padding: 6px 7px;
      border-radius: 4px;
      border: 1px solid $green-6;
    }
    .warning {
      padding: 6px 7px;
      border-radius: 4px;
      border: 1px solid var(--red-100, #fde8e8);
    }
  }
  .finding-content-risk-score-breakdown-criticality-chip {
    width: 100%;
  }

  .finding-content-risk-score-breakdown-attribute-text {
    color: var(--Grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }

  .risk-score-breakdown-attribute-icon-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  .risk-score-breakdown-icon-wrapper-with-criticality {
    justify-content: flex-end !important;
  }

  .finding-content-risk-score-breakdown-attribute-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.finding-content-dot {
  min-width: 5px;
  min-height: 5px;
  border-radius: 50%;
  background-color: #7f7f7f;
}
.environmental-tag-section
  .finding-content-risk-score-breakdown-item-category-row
  .finding-content-risk-score-breakdown-item-category-row-title {
  width: 100%;
}

.finding-content-cursor-item {
  cursor: pointer;
}
.ssvc-tooltip-container {
  padding: 5px;
  color: $grey-1;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .opus-body-text-1 {
    color: var(--Grey-1, #3d3d3d) !important;
    opacity: 1 !important;
  }

  .tooltip-content {
    &.list {
      .chip {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        border: 1px solid var(--Gray-2, #e2e2e2);
        border-radius: 4px;
        padding: 2px 9px;
        width: fit-content;
      }
      .list-row {
        display: list-item;
        list-style-type: disc;
        margin-left: 16px;
        list-style-position: outside;
        padding-left: 1px;
      }
    }

    &.not-list {
      .chip {
        align-items: center;
        gap: 6px;
        border: 1px solid var(--Gray-2, #e2e2e2);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        padding: 2px 9px;
        width: fit-content;

        svg {
          height: 12px;
          width: 12px;
        }
      }
      .body-1 {
        margin: 0;
        padding-left: 0;
      }
    }
  }

  span {
    color: $grey-1;
  }

  .decision-tooltip {
    font-family: 'HEX Franklin v0.2' !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: $grey-1;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    gap: 6px;

    .environment-chip {
      display: flex;
      align-items: center;
      border: 1px solid var(--Gray-2, #e2e2e2);
      border-radius: 4px;
      padding: 2px 9px;
      width: fit-content;
      gap: 6px;
    }
  }
}
