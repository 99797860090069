.data-grid-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .data-grid-body {
    padding: 1rem;
  }
}

.data-grid-table-container {
  height: inherit;
  .data-grid-table-column-sort-icon {
    display: block;
  }

  .data-grid-table-column-header-container {
    background: #f0f5ff;
    border-radius: 16px;
    border: none;

    .data-grid-table-column-title-sort-icon-container {
      .data-grid-table-column-title-sort-icon-container-placeholder-box {
        height: 10px;
      }
    }

    .data-grid-table-column-separator {
      display: none;
    }

    .data-grid-table-column-header {
      width: 100% !important;
    }

    .data-grid-table-column-title {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #31156f;
    }

    .data-grid-table-column-title-sort {
      outline: none !important;
      border: none !important;

      button {
        visibility: visible;

        &:hover {
          background: transparent !important;
        }

        span {
          display: none !important;
        }
      }
    }
  }
}
.action-column {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 140px;
}

.data-grid-action-button-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.data-grid-action-button {
  min-width: 32px;
  min-height: 32px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid $grey-5;
  background: $white;
  box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);

  svg {
    width: 16px;
    height: 19px;
    fill: $grey-1;
  }
}
