.label-list {
  border: 1px solid var(--Grey-5, #ebeaea);
  border-radius: 6px;
  padding: 8px 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;

  .label-list-item {
    border-radius: var(--rounded-md, 6px);
    padding: 2px 10px;
    border: 1px solid var(--Grey-5, #ebeaea);
    background: var(--white, #fff);
    color: var(--Grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

#label-management-dropdown {
  .MuiPaper-root {
    border-radius: 4px !important;
    border: 1px solid var(--Grey-6, #f5f5f5) !important;
    background: var(--White, #fff) !important;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
    min-width: 365px;
  }

  .label-management-dropdown-content {
    .label-management-dropdown-no-data {
      height: 5vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .label-management-dropdown-body {
      padding: 8px;
      border-bottom: 1px solid var(--Grey-5, #ebeaea);
      display: flex;
      flex-direction: column;
      gap: 4px;

      .label-management-dropdown-search {
        .filter-input {
          width: 100%;
          .MuiInputBase-root {
            width: 100%;
          }
        }
      }

      .label-management-dropdown-list {
        display: flex;
        flex-direction: column;
        gap: 4px;
        max-height: 35vh;
        overflow: auto;

        .label-management-dropdown-list-section {
          .label-management-dropdown-list-section-title {
            padding: 10px 16px 8px 8px;
            color: var(--Grey-3, #7f7f7f) !important;
            font-size: 13px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 13px !important;
          }
          .label-management-dropdown-list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 8px;
            border-radius: 8px;
            user-select: none;
            cursor: pointer;

            &:hover {
              background: var(--gray-50, #f9fafb);
            }

            .label-management-dropdown-list-item-title {
              display: flex;
              align-items: center;
              gap: 8px;
              color: var(--Gray-6, #3d3d3d);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;

              .label-management-dropdown-list-item-title-text {
                max-width: 250px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .common-checkbox {
                padding: 0 !important;
              }
            }

            .label-management-dropdown-list-item-remove-button {
              svg {
                height: 17px;

                path {
                  fill: var(--Neutral-500, #9799a3) !important;
                }
              }
            }
          }
        }
      }
    }

    .label-management-dropdown-footer {
      padding: 4px 8px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .label-management-dropdown-input {
        .label-management-dropdown-list-item-new {
          padding: 10px 8px !important;
          color: var(--Purple-3, #7547dc);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          display: flex;
          align-items: center;
          gap: 4px;
          border-radius: var(--rounded-md, 6px);
          border: 1px solid var(--Grey-7, #f9f9f9);
          background: var(--White, #fff);
          box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);

          svg {
            path {
              fill: var(--Purple-3, #7547dc);
            }
          }
        }

        .label-management-dropdown-list-item-add {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          height: 40px;

          input {
            border: none !important;
          }

          .label-management-dropdown-list-item-add-controls {
            display: flex;
            align-items: center;
            gap: 10px;

            button {
              padding: 0;
            }

            .label-management-dropdown-list-item-add-button {
              width: 32px;
              height: 32px;
              border-radius: var(--rounded-md, 6px);
              background: var(--Purple-3, #7547dc);
              box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);

              svg {
                path {
                  fill: #ffffff;
                }
              }
            }
          }
        }
      }

      .label-management-dropdown-save {
        padding: 0px 7px;
        width: 100%;

        .label-management-dropdown-save-button {
          width: 100%;
          height: 32px;
          color: #fff;
          font-family: 'HEX Franklin v0.2';
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          border-radius: var(--rounded-md, 6px);
          background: #7547dc;
          box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
        }
      }
    }
  }
}

.add-label-button {
  display: flex;
  align-items: center;
  gap: 13.5px;
  height: 32px !important;
  border-radius: var(--rounded-md, 6px) !important;
  border: 1px solid var(--Grey-5, #ebeaea) !important;
  background: var(--White, #fff) !important;
  box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05) !important;
  padding: 8px 13px !important;
  color: var(--Grey-1, #3d3d3d) !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 588 !important;
  line-height: normal !important;

  .add-label-button-text {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .add-label-button-end-andornment {
    svg {
      width: 10px !important;
      path {
        fill: var(--Grey-2, #5c5c5c);
      }
    }
  }
}

.label-cell {
  display: flex;
  gap: 10px;

  .label-cell-item {
    padding: 2px 10px;
    max-width: 72px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: var(--rounded-md, 6px);
    border: 1px solid var(--Grey-5, #ebeaea);
    background: var(--white, #fff);
    color: var(--Grey-1, #3d3d3d);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.label-add-modal {
  .MuiChip-root {
    margin: 0 !important;
    margin-right: 3px !important;
    max-width: 80px !important;
  }

  .auto-complete-input {
    cursor: pointer !important;
  }
}
