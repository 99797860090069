.display-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
  .header {
    min-height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 9px;
    p {
      padding: 0;
      margin: 0;
    }
    padding-bottom: 15px;

    border-bottom: 1px solid var(--Grey-5, #ebeaea);

    .default-tag {
      display: inline-flex;
      padding: var(--0, 3px) var(--0, 5.5px) var(--0, 3px) var(--0, 6.5px);
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid var(--Purple-3, #7547dc);
      background: #f9f9f9;
      box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);

      p {
        color: var(--Purple-3, #7547dc);
        font-family: 'HEX Franklin v0.2';
        font-size: 12px;
        font-style: normal;
        font-weight: 588;
        line-height: 14px;
        text-transform: uppercase;
      }
    }
  }

  .footer-section {
    display: grid;
    grid-template-columns: 10fr 2fr;

    .filter-and-attributes {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 20px;
      column-gap: 16px;

      .divider {
        border-left: 1px solid var(--Grey-5, #ebeaea);
        height: 27px;
        width: 1px;
      }

      .filters {
        display: flex;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;

        p {
          margin: 0;
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        .body-2 {
          color: var(--Grey-1, #3d3d3d);
        }
        .body-1 {
          color: var(--Grey-3, #7f7f7f);
        }
        .chips-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
          flex-wrap: wrap;

          .attr-item {
            width: 48px;
            height: 24px;
            gap: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: var(--Rounded-s, 4px);
            border: 1px solid var(--Neutral-200, #ebebef);
            background: var(--Generic-White, #fff);
            svg {
              width: 15px;
              height: 15px;
              path {
                fill: var(--Neutral-900, #424347);
              }
            }
          }

          .chip {
            display: inline-flex;
            padding: var(--0, 4px) var(--0, 6.5px) var(--0, 6px) var(--0, 6.5px);
            justify-content: center;
            height: 27px;
            border-radius: 4px;
            border: 1px solid var(--Grey-5, #ebeaea);
            background: var(--Grey-7, #f9f9f9);
            box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);

            .key {
              padding-right: 3px;
            }
            .value {
              display: inline-flex;
              flex-direction: row;
              align-items: center;
              gap: 1px;
            }
          }
        }
      }
      .attributes {
        display: flex;
        align-items: center;
        gap: 5px;
        p {
          margin: 0;
        }
        display: flex;
        flex-direction: row;
        align-items: center;

        .chips-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
        }
      }
      .sla-list {
        display: flex;
        align-items: center;
        gap: 5px;
        p {
          margin: 0;
        }
        display: flex;
        flex-direction: row;
        align-items: center;

        .chips-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;

          .sla-chip {
            gap: 4px;
            padding: 2px 10px 2px 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: var(--Rounded-s, 4px);
            border: 1px solid var(--Neutral-200, #ebebef);
            background: var(--Generic-White, #fff);
            .label-4 {
              font-size: 13px;
            }
            .common-severity-chip-container {
              min-height: 20px;
              min-width: 20px;
            }
          }
        }
      }

      .sla-settings {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3px;
        .setting-type {
          color: var(--Grey-1, #3d3d3d);
        }
      }
    }

    .actions-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
      justify-content: flex-end;

      .data-grid-action-button {
        display: flex;
        min-width: 32px;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px solid var(--Grey-5, #ebeaea);
        background: var(--White, #fff);
        padding: 0;

        &:disabled {
          background: #f5f5f5;
          svg {
            path {
              fill: #c7c4c4;
            }
          }
        }

        box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
        svg {
          width: 13px;
          height: 13px;
        }
      }
    }
  }
}

.tooltip-content {
  color: var(--Grey-1, #3d3d3d) !important;
  opacity: 1 !important;
  display: contents;
}
