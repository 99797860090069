.action-menu-list-button {
  width: 104px !important;
  height: 32px !important;
  background: #ffffff !important;
  border: 1px solid #7547dc !important;
  box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05) !important;
  border-radius: 8px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  color: #7547dc !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
}

.action-menu-list-popover {
  background: #ffffff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.09) !important;
  border-radius: 16px !important;
  padding: 8px 0px;
}
