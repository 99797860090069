.list-modal-popover {
  .MuiPaper-root {
    box-shadow: none !important;
    border-radius: 0 !important;
    border: 1px solid var(--grey-5, #ebeaea) !important;
    border-bottom: none !important;
    min-width: 270px !important;
    margin-top: 0.5rem !important;
    color: var(--gray-6, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;

    .list-modal-item-container {
      .list-modal-item {
        display: flex;
        justify-content: space-between;
        padding: 22px 20px;
        user-select: none;
        cursor: pointer;
        border-bottom: 1px solid var(--grey-5, #ebeaea) !important;
      }
    }
  }
}
