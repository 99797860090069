.organization-tree-item-node {
  .opus-secondary-button {
    min-width: auto !important;

    p {
      margin-left: 8px;
    }
  }

  .organization-node-add-button {
    width: 32px;
    height: 32px !important;

    path {
      fill: $purple-3;
    }
  }

  .root-btn {
    width: 129px;
  }

  .organization-node-action-button {
    border: none !important;
    border-radius: 0 !important;

    svg {
      fill: $grey-3;
    }
  }

  .node-label-container {
    display: flex;
    align-items: center;
    gap: 10px;

    .node-icon-container {
      width: 36px;
      height: 36px;
      border-radius: 12px;
      border: 1px solid $grey-6;
      background: $grey-6;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: $purple-3;

        path {
          fill: $purple-3;
        }
      }
    }

    .main-logo {
      width: 32px;
      height: 32px;
    }
  }

  .organization-node-content {
    display: flex;
    align-items: center;

    .node-content-item {
      display: flex;
      gap: 9px;
      align-items: center;

      .avatar-container {
        border: none !important;

        .app-icon {
          width: 24px;
          height: 24px;
        }

        svg:not(.app-icon) {
          fill: $grey-3;

          path {
            fill: $grey-3;
          }
        }
      }

      .text-container {
        p {
          color: $grey-1;
          font-size: 14px;
          font-weight: 500;
        }
      }

      &::before {
        content: '\2022';
        color: $grey-4;
        margin-left: 9px;
      }
    }
  }
}

.node-action-menu-container {
  display: flex;
  width: 183px;
  max-height: 96px;
  min-height: 48px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;

  .MuiMenu-list {
    width: 100%;

    .delete-action-button {
      margin: 0;
      padding: 0;
      color: $grey-1;
      height: 20px;

      .body-1 {
        display: flex;
      }
    }
  }
}
