.workflow-builder-crossroad-node-icon-container {
  width: 48px;
  height: 48px;
  border-radius: 20px;
  background: var(--white, #fff);
  box-shadow: 0px 4px 20px 0px rgba(15, 106, 80, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #ffffff;
  background: var(--Green-5, #acecda);

  svg {
    path {
      fill: var(--Green-1, #0f6a50) !important;
    }
  }
}
