.checkbox-label {
  padding: 2px 0px 2px 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $grey-5;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: $grey-1;
  border-radius: 8px;
  min-width: 240px;
}
