.campaigns-management-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .campaigns-management-page-body {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 24px 25px;
    height: 100%;

    .campaigns-management-page-body-creation-banner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      border: 1px solid var(--Grey-5, #ebeaea);
      border-radius: 8px;
      padding: 12px 19px;

      div {
        display: flex;
        align-items: center;
        gap: 9px;

        svg {
          width: 18px;
          height: 24px;
        }
      }

      button {
        width: 10px;
        height: 13px;

        svg {
          width: 12px;
          height: 18px;
        }
      }
    }

    .filter-input {
      margin-right: 0px !important;
    }

    .campaigns-management-page-body-filters {
      display: flex;
      align-items: center;
      padding: 12px 20px;
      border-radius: 8px;
      border: 1px solid #e2e2e2;
      background: #fff;
      gap: 20px;
    }

    .campaigns-management-page-body-table {
      flex: 1;

      .campaign-title-row {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;

        .campaign-title-text {
          max-width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .campaign-title-icons {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;

          .campaign-title-live {
            color: var(--Green-2, #0fae7f);
            font-size: 12px;
            font-style: normal;
            font-weight: 588;
            line-height: 14px;
          }

          .campaign-title-active {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #dffef5;
            border-radius: 50%;

            .campaign-title-active-inner-circle {
              width: 12px;
              height: 12px;
              background-color: #22cc9a;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}

.campaigns-tracker-page {
  padding: 20px 26px;
  max-height: 100%;
  font-family: 'HEX Franklin v0.2' !important;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.campaign-findings-grid-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;

  .campaign-findings-grid-header {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .campaign-findings-grid-header-text {
      color: var(--Grey-1, #3d3d3d) !important;
      font-size: 18px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 16px !important;
    }

    .campaigns-findings-grid-filters {
      display: flex;
      align-items: center;
      padding: 12px 20px;
      border-radius: 8px;
      border: 1px solid #e2e2e2;
      background: #fff;
      gap: 20px;
    }
  }

  .campaign-findings-grid {
    height: 40vh;

    .ag-header {
      overflow: hidden !important;
    }

    .ag-root-wrapper {
      padding: 0 !important;

      .ag-header-viewport {
        border: none !important;
      }
    }

    .common-simple-data-grid-visibility-control {
      top: 0 !important;
      right: 0 !important;
      background-color: transparent !important;
      z-index: 200;
    }
  }
}

.campaign-breakdown-statistics-container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .dashboard-widget-card-container {
    gap: 2rem !important;
    .dashboard-widget-card-header-icon {
      border-radius: 12px;
      border: 1px solid var(--grey-5, #ebeaea);
      background-color: #ffffff;

      path {
        fill: $grey-1;
      }
    }
  }

  .campaign-breakdown-statistics-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .campaign-breakdown-statistics-title {
      display: flex;
      align-items: center;
      gap: 10px;

      .campaign-breakdown-statistics-title-text {
        color: var(--Grey-1, #3d3d3d);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }

      svg {
        path {
          height: 16px;
          width: 16px;
          fill: var(--Grey-3, #7f7f7f) !important;
        }
      }
    }

    .campaign-breakdown-statistics-filters {
      .filter-chip {
        border-radius: var(--rounded-md, 6px);
        border: 1px solid var(--Grey-5, #ebeaea);
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);

        .filter-chip-label {
          color: var(--Grey-3, #7f7f7f);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .filter-chip-value {
          color: var(--Grey-1, #3d3d3d);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }

  .campaign-breakdown-statistics-body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .campaign-breakdown-statistics-metrics {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }

    .campaign-breakdown-statistics-chart {
      .comparative-dashboard-section-container {
        .settings-pagination {
          margin: 0 !important;
        }
        #comparative-widget-legend-container {
          ul {
            padding-bottom: 1rem !important;
          }
        }
        .widget-divider {
          display: none;
        }
        .comparative-dashboard-section-header {
          p {
            color: var(--Grey-1, #3d3d3d);
            font-size: 16px;
            font-style: normal;
            font-weight: 588 !important;
            line-height: normal;
          }
        }

        .open-findings-widget {
          .base-chart-main-container {
            position: relative;
            height: 240px;
            width: 90vw;
            display: inline-block !important;

            canvas {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }

        chartjs-tooltip {
          background-color: $white !important;
          padding: 6px 10px !important;
          min-width: auto !important;
          color: $grey-1 !important;
          border-radius: 4px;
          z-index: 9999;
          filter: drop-shadow(5px 5px 7px rgba(112, 112, 112, 0.339));

          td {
            display: flex;
            align-items: center;

            .tooltip-label {
              min-width: 82px;
            }
          }
          .tooltip-caret {
            border-left: solid 5px transparent;
            border-right: solid 5px transparent;
            border-bottom: solid 5px #fff;
            height: 0;
            width: 0;
          }
        }
      }
    }
  }
}

.campaign-tracker-page-container {
  overflow-y: auto;
}

.campaign-grid-actions {
  .campaign-grid-actions-button {
    border-radius: var(--rounded-md, 6px);
    border: 1px solid var(--Grey-5, #ebeaea);
    background: var(--White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
    width: 32px !important;
    height: 32px;

    &:disabled {
      svg {
        path {
          fill: var(--Grey-4, #c7c4c4) !important;
        }
      }
    }
  }
}

.campaign-grid-actions-menu {
  .MuiMenu-paper {
    border-radius: 8px !important;
    border: 1px solid var(--Grey-5, #ebeaea) !important;
    background: var(--White, #fff) !important;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
    min-width: 225px;

    ul {
      padding: 8px;
      gap: 8px;

      li {
        padding: 12px 9px;
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
          width: 18px;
          height: 18px;

          path {
            fill: #3d3d3d;
          }
        }

        span {
          color: var(--Grey-1, #3d3d3d);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
