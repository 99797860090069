.fileLogo,
.repositoryLogo,
.resourceLogo {
  img {
    width: 32px;
    height: 32px;
    padding: 6px;
    justify-content: center;
    align-items: center;
  }
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.providersLogo > div > svg {
  height: 17px;
}

.table-text {
  align-items: center;
  display: flex;
  font-weight: 700 !important;
  color: #2d2c2c;
  font-size: 1rem !important;
}
