#operational-dashboard-section-metrics-chart-legend-container {
  width: 90%;
  margin: auto;
  margin-top: 1.5rem;

  ul {
    padding: 0 !important;
    display: flex !important;
    flex-wrap: wrap;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0.2rem;

    span {
      width: 12px !important;
      height: 12px !important;
      border-radius: 50%;
    }

    b {
      font-family: 'HEX Franklin v0.2';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: $grey-6;
    }
  }
}

.finding-by-category-chart-legend-container {
  ul {
    display: flex;
    flex-direction: column !important;
    column-gap: 1rem;
    gap: 1rem;

    b {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #474747;
    }
  }
}

.OperationalDashboardInfo {
  .iconContainer {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .code-vs-cloud-chart-legend-container {
    ul {
      padding: 0 !important;

      span {
        height: 8px !important;
        width: 32px !important;
      }

      b {
        font-weight: 500;
      }

      li:first-child {
        margin: 0 !important;
      }
    }
  }

  .operational-general-info-sla-container {
    position: relative;
    width: 20%;

    .operational-general-info-sla-header-container {
      position: absolute;
      top: -100%;
      left: 50%;
      transform: translateX(-50%);
    }

    .operational-general-info-sla-metadata-chart-container {
      width: 50%;
    }

    .operational-general-info-sla-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 20px;
      width: 20px;
    }

    .operational-general-info-sla-icon-container-success {
      border: 1px solid #23cd9c;
      background-color: #e8f7f0;
    }

    .operational-general-info-sla-icon-container-progress {
      border: 1px solid rgba(74, 82, 146, 0.5);
      background-color: rgba(74, 82, 146, 0.1);
    }

    .operational-general-info-sla-icon-container-error {
      border: 1px solid #ff024e;
      background-color: rgba(255, 2, 78, 0.15);
    }

    .operational-general-info-sla-indicator-text {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      color: #2b1470;
    }

    .operational-general-info-sla-counter-text {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #0a0b50;
    }
  }

  .title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #4a5392;
    opacity: 0.6;
  }

  .value {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    color: #2b1470;
  }
}

.operational-content-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .operational-dashboard-section-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .operational-dashboard-section-body-card-list {
      display: flex;
    }

    .operational-dashboard-section-body-chart-list {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      .dashboard-chart-card-container {
        flex: 1;
      }

      .operational-dashboard-section-by-category-chart-container {
        .operational-dashboard-chart-card-body {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }

        .base-chart-container-box {
          max-width: 250px;
          max-height: 245px;
        }
      }

      #operational-dashboard-section-by-category-chart-legend-container {
        ul {
          display: flex;
          flex-direction: column !important;
          gap: 0.4rem;
          padding: 0 !important;

          span {
            width: 12px !important;
            height: 12px !important;
            border-radius: 50%;
          }

          b {
            font-family: 'HEX Franklin v0.2';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            color: $grey-1;
          }
        }
      }

      #operational-dashboard-section-by-severity-chart-legend-container {
        width: 50%;
        margin: auto;
        margin-top: 1.5rem;

        ul {
          padding: 0 !important;

          span {
            width: 12px !important;
            height: 12px !important;
            border-radius: 50%;
          }

          b {
            font-family: 'HEX Franklin v0.2';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: $grey-6;
          }
        }
      }
    }
  }

  #operational-dashboard-section-by-ticket-age-chart-legend-container,
  #operational-dashboard-section-by-ticket-status-chart-legend-container {
    ul {
      display: flex;
      gap: 0.6rem;
      justify-content: center;

      b {
        color: var(--gray-6, #3d3d3d) !important;
        font-size: 13px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
      }
    }
  }
}

.operational-dashboard-section-filters {
  display: flex;
  align-items: center;
  gap: 12px;

  .auto-complete-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .operational-dashboard-section-metrics-button {
    border-radius: var(--rounded-md, 6px);
    border: 1px solid var(--Grey-5, #ebeaea);
    background: var(--White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
    color: var(--Grey-1, #3d3d3d);
    font-size: 13px;
    font-style: normal;
    font-weight: 588;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 7px;
    min-width: 210px;
    height: 31px;

    svg {
      path {
        fill: var(--Grey-1, #3d3d3d) !important;
      }
    }
  }
}

.operational-dashboard-section-timeline-filter-root {
  height: 32px !important;
  width: 170px !important;

  .operational-dashboard-section-timeline-filter-input-root {
    border: 1px solid $grey-4 !important;
    border-radius: 4px !important;
    background: $white !important;
    padding: 0px 8px !important;

    input {
      transition: 200ms;
      user-select: none;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $grey-1;

      padding: 4px 5px;
      padding-right: 0px;

      &:hover + button + fieldset,
      &:focus + button + fieldset {
        border: none !important;
        outline: none;
      }
    }
  }
}

.operational-metrics-modal-container {
  .common-modal-header-title-container {
    display: flex;
    align-items: center;
    gap: 12px;

    .common-modal-header-title-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 12px;
      border: 1px solid #ebeaea;

      svg {
        path {
          height: 18px;
        }
      }
    }
  }

  .operational-metrics-modal-body {
    display: flex;
    flex-direction: column;
    gap: 26px;

    .operational-metrics-modal-description {
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .operational-metrics-modal-categories {
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-height: 50vh;
      overflow: auto;

      .operational-metrics-modal-category {
        display: flex;
        flex-direction: column;
        gap: 18px;

        .operational-metrics-modal-category-title {
          color: var(--Grey-1, #3d3d3d);
          font-size: 14px;
          font-style: normal;
          font-weight: 588;
          line-height: 16px;
        }

        .operational-metrics-modal-category-items {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;

          .operational-metrics-modal-category-item {
            display: flex;
            align-items: center;
            gap: 11px;
            border: 1px solid var(--Grey-5, #ebeaea);
            padding: 11px 13px;
            border-radius: 10px;
            user-select: none;
            cursor: pointer;

            .common-checkbox {
              padding: 0 !important;
            }

            span {
              color: var(--Grey-1, #3d3d3d);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
            }
          }

          .operational-metrics-modal-category-item-selected {
            border-color: var(--Purple-3, #7547dc);
          }
        }
      }
    }
  }

  .operational-metrics-modal-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    button {
      height: 42px;
      width: 110px;
    }
  }
}
