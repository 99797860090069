.token-container {
  display: flex;
  gap: 4px;
  min-height: 18px;
}

.token-parameter {
  color: var(--Purple-3, #7547dc);
  font-family: 'HEX Franklin v0.2';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  background: var(--Purple-6, #ece7f9);
}

.token-function,
.token-helper {
  color: var(--Grey-1, #3d3d3d);
  font-family: 'HEX Franklin v0.2';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  background: var(--Grey-6, #f5f5f5);
}

.token-empty {
  display: inline-block;
  min-width: 2px;
}

.public-DraftStyleDefault-ltr {
  display: flex !important;
  gap: 4px;
  flex-wrap: wrap;
}

.token-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid var(--Grey-5, #ebeaea);
  background: #fff;
  padding: 8px 7px;
  min-height: 42px;

  .DraftEditor-root {
    width: 100%;
  }

  .token-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    word-break: break-all;
    outline: none;
    gap: 4px;
    min-height: 24px;

    p {
      margin: 0;
      padding: 0;
    }

    &:active,
    &:focus {
      outline: none;
    }
  }

  .token-input-end-andornment {
    border-radius: 6px;
    border: 1px solid var(--Grey-5, #ebeaea);
    background: var(--Grey-7, #f9f9f9);
    box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.token-input-panel-container {
  border: 1px solid var(--Grey-5, #ebeaea) !important;
  box-shadow: 8px 8px 18px rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px !important;
  min-width: 430px !important;
  max-width: 430px !important;
  min-height: 600px !important;
  display: flex;
  flex-direction: column;

  .token-input-panel-body {
    padding: 22px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;

    .token-input-panel-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .token-input-panel-close-icon {
        cursor: pointer;
        svg {
          height: 25px;
          width: 15px;

          path {
            fill: #c7c4c4;
          }
        }
      }
    }

    .token-input-panel-content {
      width: 100%;
      border-radius: 10px !important;
      padding: 22px;
      border: 1px solid var(--Grey-5, #ebeaea);
      flex: 1;
      min-height: 60vh;
      max-height: 60vh;
      overflow: scroll;

      .token-input-panel-step-icon {
        width: 32px;
        height: 32px;
      }

      .token-input-panel-step-main-branch {
        .token-input-panel-tree-view {
          padding-top: 10px;
        }

        .token-input-panel-step-main-branch-header {
          display: flex;
          gap: 8.5px;
          align-items: center;
          text-wrap: nowrap;

          .token-input-panel-step-main-branch-header-expand-icon {
            cursor: pointer;
            user-select: none;
          }

          .token-input-panel-step-main-branch-header-icon {
            width: 32px;
            min-width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            border: 1px solid var(--Grey-5, #ebeaea);
            background: var(--white, #fff);
            cursor: pointer;

            img {
              width: 20px;
            }
          }

          .token-input-panel-step-main-branch-header-title {
            color: var(--Grey-1, #3d3d3d);
            font-size: 13px;
            font-style: normal;
            font-weight: 588;
            line-height: 14px; /* 107.692% */
            max-width: 150px;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .token-input-panel-step-main-branch-header-id {
            color: var(--Grey-1, #3d3d3d);
            font-size: 13px;
            font-style: normal;
            font-weight: 588;
            line-height: 13px;
            padding: 5px 6px;
            border-radius: 4px;
            border: 1px solid var(--Grey-5, #ebeaea);
            background: #fff;
          }
        }
      }

      .token-input-panel-step-sub-branch-header {
        display: flex;
        align-items: center;
        gap: 4px;
        .token-input-panel-step-sub-branch-header-label {
          cursor: pointer;
          padding: 5px 10px;
          color: var(--Purple-3, #7547dc);
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          border-radius: 4px;
          min-width: max-content;
          background: var(--Purple-6, #ece7f9);
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: nowrap;
        }

        .token-input-panel-step-sub-branch-header-sub-label {
          color: var(--Grey-3, #7f7f7f);
          font-size: 13px;
          font-style: normal;
          font-weight: 300;
          line-height: 14px;
          border-radius: 4px;
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;
        }
      }

      .token-input-panel-step-tokens-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .token-input-panel-content-groups {
        display: flex;
        flex-direction: column;
        gap: 33px;

        .token-input-panel-content-group {
          display: flex;
          flex-direction: column;
          gap: 9px;

          .token-input-panel-content-group-title {
            color: var(--Grey-3, #7f7f7f);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 13px;
          }

          .token-input-panel-content-group-items {
            display: flex;
            flex-wrap: wrap;
            gap: 6px;

            .token-input-panel-content-group-item {
              color: var(--Grey-1, #3d3d3d);
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px;
              padding: 5px 10px;
              border-radius: 4px;
              background: var(--Grey-6, #f5f5f5);
              cursor: pointer;
              user-select: none;
            }

            .token-input-panel-content-group-system-item {
              color: var(--Purple-3, #7547dc);
              background: var(--Purple-6, #ece7f9);
            }
          }
        }
      }
    }
  }

  .token-input-panel-tree-view {
    display: flex;
    flex-direction: column;
    gap: 7px;

    .MuiCollapse-vertical {
      display: flex;
      flex-direction: column;
      gap: 7px;
      margin-top: 2px;
    }

    .Mui-focused,
    .Mui-selected {
      background-color: transparent !important;
    }

    .token-input-panel-tree-item {
      .MuiTreeItem-content {
        display: flex;
        justify-content: start;
        gap: 7px;

        &:hover {
          background-color: transparent !important;
        }

        .MuiTreeItem-iconContainer {
          margin: 0 !important;
        }

        .MuiTreeItem-label {
          padding: 5px 10px;
          color: var(--Purple-3, #7547dc);
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 14px;
          border-radius: 4px;
          background: var(--Purple-6, #ece7f9);
          width: max-content !important;
        }
      }
    }
  }
}
