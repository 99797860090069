.workflow-builder-plus-button {
  width: 1.6rem;
  min-width: 1.6rem;
  height: 1.6rem;
  border-radius: 50% !important;
  background: var(--Purple-3, #7547dc) !important;
  box-shadow: 0px 4px 20px 0px rgba(117, 71, 220, 0.6);
  padding: 4px;

  svg {
    width: 13px;
    height: 16px;
    path {
      fill: #ffffff;
    }
  }
}

.workflow-builder-plus-button-border {
  border: 3px solid var(--Purple-6, #ece7f9) !important;
}

.workflow-builder-plus-button-readonly {
  width: 12px !important;
  height: 12px !important;
  min-width: 12px !important;
  background-color: #c3aff2 !important;
  z-index: 200 !important;
  border: 2px solid #ffffff !important;
  box-shadow: 0px 0px 6px rgba(155, 181, 206, 0.48) !important;
}
