.workflow-builder-panel-slider {
  display: flex;
  justify-content: start;
  margin-top: 30px;

  .horizontal-list-slider {
    margin: 0;

    .horizontal-list-slider-thumb {
      height: 12px;
      width: 12px;
      background-color: var(--Purple-3, #7547dc);
      top: 0.1px;
    }

    .horizontal-list-slider-mark {
      height: 12px;
      width: 12px;
      background-color: var(--Grey-5, #ebeaea);
    }
  }
}

.workflow-builder-step-panel-content {
  .material-list-container {
    margin-top: 16px;
    flex: 1;
    max-height: 100%;

    .material-list-item-button {
      padding: 8px 10px !important;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      border-bottom: 1px solid #f5f5f5;

      &:hover {
        background: var(--Grey-7, #f9f9f9);
      }

      li {
        padding: 0 !important;
      }

      .MuiListItemIcon-root {
        width: 32px !important;
        min-width: 32px !important;
        height: 32px;
        border-radius: 12px;
        border: 1px solid var(--Grey-5, #ebeaea);
        background: var(--white, #fff);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .MuiListItemText-root {
        color: var(--Gray-6, #3d3d3d);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        margin-left: 10px;
      }
    }
  }

  .workflow-builder-panel-body {
    display: flex;
    flex-direction: column;

    .dynamic-form {
      margin-top: 30px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;
      max-height: 100% !important;

      .MuiFormControl-root {
        gap: 10px;
      }

      .MuiTypography-root {
        color: var(--Grey-1, #3d3d3d);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }

      .timeline-event {
        padding: 20px 15px;
        border: 1px solid var(--Grey-5, #ebeaea);
        display: flex;
        flex-direction: column;
        gap: 15px;

        .timeline-event-title-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .dynamic-form-inputs-container {
        display: flex;
        flex-direction: column;
        gap: 36px;
      }

      .MuiGrid-root {
        align-items: center;
      }

      .token-input-container {
        width: 100%;
      }
    }
  }

  .workflow-builder-step-panel-active-application {
    padding: 10px;
    background-color: var(--Grey-7, #f9f9f9);
    border: 1px solid var(--Grey-5, #ebeaea);
    border-radius: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 30px;

    .workflow-builder-step-panel-active-application-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 12px;
      border: 1px solid var(--Grey-5, #ebeaea);
      background: var(--white, #fff);
    }

    .workflow-builder-step-panel-active-application-title {
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 588;
      line-height: 14px;
    }
  }

  .workflow-builder-step-panel-active-tool-container {
    display: flex;
    align-items: center;
    border: 1px solid var(--Grey-5, #ebeaea);
    border-radius: 12px;
    background-color: var(--Grey-7, #f9f9f9);
    justify-content: space-between;
    padding: 10px;
    margin-top: 30px;

    .workflow-builder-step-panel-active-tool-id {
      padding: 5px 6px;
      border-radius: 4px;
      border: 1px solid var(--Grey-5, #ebeaea);
      background: #fff;
      color: var(--Grey-1, #3d3d3d);
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: 13px;
      display: flex;
      align-items: center;
      gap: 3px;
    }

    .workflow-builder-step-panel-active-tool-icon-with-labels {
      display: flex;
      align-items: center;
      gap: 10px;

      .workflow-builder-step-panel-active-tool-labels {
        display: flex;
        flex-direction: column;
        gap: 4px;

        p {
          margin: 0;
        }

        .workflow-builder-step-panel-active-tool-label {
          color: var(--Grey-1, #3d3d3d);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }

        .workflow-builder-step-panel-active-tool-sub-label {
          color: var(--Grey-3, #7f7f7f);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px;
        }
      }

      .workflow-builder-step-panel-active-tool-icon {
        height: 40px;
        width: 40px;
        border-radius: 12px;
        border: 1px solid var(--Grey-5, #ebeaea);
        background: var(--white, #fff);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
