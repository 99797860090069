.campaign-details-header {
  height: 100%;
  align-items: center;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1.5rem;

  .campaign-details-info-container {
    flex: 1;
  }

  .campaign-details-burndown-container {
    flex: 2;

    .base-chart {
      width: 100% !important;
    }
  }

  .campaign-details-breakdown-container {
    flex: 3;
  }
}

.campaign-filters-header {
  margin-bottom: 30px;
  padding: 20px;
  display: block !important;
}

.campaign-tracker-header {
  width: 100% !important;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 1.5rem;
  flex-direction: row;
  border-radius: 8px;
  border: 1px solid var(--Gray-2, #e2e2e2);
  background: var(--White, #fff);
  padding: 19px 24px;

  p {
    padding: 0;
    margin: 0;
  }
  .header-details {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .campaign-tracker-info {
      align-items: center;
      display: flex;
      width: 100%;
      gap: 10px;
      flex-direction: row;
      .body-1 {
        color: $grey-3;
        width: auto !important;
      }

      .priority,
      .owner,
      .assignees {
        align-items: center;
        display: flex;
        gap: 5px;
        flex-direction: row;
      }
    }
    .go-back-arrow {
      svg {
        width: 26px;
        path {
          fill: $grey-3;
        }
      }
    }

    .go-back-arrow-disabled {
      svg {
        path {
          fill: var(--Grey-4, #c7c4c4);
        }
      }
    }

    .divider {
      width: 1px;
      border-left: 1px solid #e2e2e2;
      height: 20px;
      margin-right: 3px;
    }

    .campaign-tracker-title {
      align-items: center;
      display: flex;
      width: 100%;
      gap: 10px;
      flex-direction: row;

      .header-4 {
        max-width: 850px;
        font-size: 24px !important;
        font-weight: 588 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 35px !important;
      }
    }

    .status {
      align-items: center;
      display: flex;
      gap: 6px;
      flex-direction: row;
      p {
        color: $green-2;
      }
      .campaign-status-icon {
        background-color: $green-2;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        border: 4px solid $green-6;
      }
    }
  }
  .section-divider {
    width: 1px;
    border-left: 1px solid #e2e2e2;
    height: 62px;
    margin-right: 3px;
    margin-top: auto;
  }
  .header-completion-rate {
    display: grid;
    grid-template-columns: 2fr 8fr 1fr;
    height: auto;
    align-items: center;
    gap: 12px;
    border-left: 1px solid var(--Gray-2, #e2e2e2);
    padding-left: 40px;

    .completion-percentage {
      display: flex;
      width: 92px;
      padding: 17px 14px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 1px solid var(--Gray-2, #e2e2e2);
      background: var(--Grey-7, #f9f9f9);

      p {
        color: var(--Grey-1, #3d3d3d);
        text-align: center;
        font-family: 'HEX Franklin v0.2';
        font-size: 30px;
        font-style: normal;
        font-weight: 588;
        line-height: 22px;
        margin: 0;
      }
    }

    .completion-info-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      .completion-general-data {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;

        p {
          font-size: 14px !important;
        }

        .completation-rate {
          border-radius: 6px;
          border: 1px solid var(--Gray-2, #e2e2e2);
          background: var(--Grey-7, #f9f9f9);
          padding: 4px 8px;

          p {
            color: var(--Grey-1, #3d3d3d);
            font-variant-numeric: lining-nums proportional-nums;
            font-family: 'HEX Franklin v0.2';
            font-style: normal;
            font-weight: 588;
            line-height: normal;
            margin: 0;
          }
        }
        .remaining-days {
          display: flex;
          height: 27px;
          padding: 0 8px;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          border: 1px solid var(--Gray-2, #e2e2e2);
          background-color: $white;
          color: $grey-1;
          cursor: pointer;

          &.critical {
            border: 1px solid $red-6;
            background-color: $red-6;
            color: $red-3;
          }
        }
      }
    }

    .settings {
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: row;
      padding-left: 30px;

      .campaign-settings-menu-button {
        border: 1px solid var(--Gray-2, #e2e2e2);
        max-height: 32px !important;
        min-width: 32px !important;
        margin: 0;
        padding: 0;
        border-radius: 8px;
      }
    }
  }
}
.remaining-days-tooltip {
  display: flex;
  flex-direction: column;
  gap: 19px;
  padding: 5px;
  .start-date,
  .end-date {
    .title {
      color: $grey-3;
      margin-bottom: 5px;
    }
    .date {
      color: $grey-1;
    }
  }
}
.completation-line {
  .completation-line-progress-root {
    width: 100%;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid var(--Gray-2, #e2e2e2);
    height: 20px;
    position: relative;

    .completation-line-progress-bar {
      position: absolute;
      top: 2px;
      left: 2px;
      height: 75%;

      border-radius: 8px;
    }
  }
}

.red {
  background-color: $red-3 !important;
}
.orange {
  background-color: $orange-3 !important;
}
.green {
  background-color: $green-3 !important;
}
