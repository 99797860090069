.foreign-object > section {
  background: transparent;
  width: 160px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 32px;
}

.edge-text-panel {
  background: #f2f7fc;
  text-align: center;
  border: 1.5px solid #c3aff2;
  border-radius: 20px;
  font-weight: bold;
  color: #7547dc;
  overflow: hidden;
  padding: 0rem 0.5rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  user-select: none;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
}

.not-active-edge {
  color: #cdd7e2 !important;
  border-color: #cdd7e2 !important;
}
.active-edge {
  background: #d2d4f7;
  border: 2px solid #6664ed;
  color: #6664ed;
  border-radius: 67px;
}
