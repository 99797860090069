.assets-page-body-container {
  .applications-list {
    .application-logo {
      img {
        height: 25px;
        width: 25px;
      }
    }
  }
}
