.common-tag-component {
  border-radius: 6px;
  border: 1px solid var(--Grey-5, #ebeaea);
  background: var(--White, #fff);
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
  height: 32px;
  padding: 6px;

  .icon {
    svg {
      height: 13px;
      width: 13px;
      path {
        fill: var(--Grey-1, #3d3d3d);
      }
    }
  }
  .value {
    border-radius: 6px;
    background: var(--gray-100, #f3f4f6);
    border: 1px solid var(--gray-200, #e5e7eb);

    box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
    display: inline-flex;
    padding: 2px 4px;
    justify-content: center;
    height: 25px;
    min-width: 25px;
    align-items: center;

    p {
      color: var(--Grey-1, #3d3d3d);
    }
  }
}
