.common-icon-cell-container {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #525252;
  .applications-list {
    display: flex;
    align-items: center;

    .application-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      &.bordered {
        border: 1px solid var(--Grey-5, #ebeaea);
        border-radius: 12px;
        padding: 4px;
      }
    }

    .more-app-button {
      width: 26px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      padding: 3px;
      border: 1px solid var(--Grey-5, #ebeaea);
      .body-5 {
        font-weight: 700;
        color: var(--Grey-2, #5c5c5c);
      }
    }
  }

  img {
    width: 22px;
    height: 22px;
  }

  .common-icon-cell-link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #7547dc;
    text-decoration: none;
  }
}
