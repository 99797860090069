.sla-rule-view-panel-header-icon {
  padding: 0.6rem 0.5rem;
  border: 1px solid $grey-5;
  border-radius: 16px;
}

.sla-rule-view-panel-loading {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sla-rule-view-panel {
  padding: 2rem;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .content-section-icon {
    path {
      fill: var(--Grey-1, #3d3d3d);
    }
  }

  .auto-complete-input {
    .MuiChip-root {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      border: 1px solid $grey-5;
      border-radius: 6px;
      padding: 0.4rem 0.7rem;
      background: $grey-7;
      font-family: 'HEX Franklin v0.2' !important;
      color: var(--grey-1, #3d3d3d) !important;
      font-weight: 700 !important;
      font-size: 14px !important;
      line-height: 17px !important;
    }

    .MuiChip-label {
      padding: 0;
    }

    .MuiChip-deleteIcon {
      margin: 0 !important;
    }
  }

  .sla-rule-view-panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.7rem;

    .sla-rule-view-panel-header-text {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;

      .sla-rule-view-panel-header-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        color: $grey-1;
      }
      .sla-rule-view-panel-header-description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $grey-1;
      }
    }
  }

  .sla-rule-view-panel-body {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: visible;
    padding-bottom: 2rem;
  }

  .sla-rule-view-panel-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .additional-data-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 40px;

      .delete-button {
        margin: 0 !important;
      }
    }
    .advanced-filter-container {
      border-color: $grey-4;
      border-radius: 4px !important;
      padding: 0.2rem 0.5rem !important;

      .filter-container {
        .opus-filter-button {
          align-self: center;
        }

        .chip-wrapper {
          margin-top: 0 !important;
        }
      }
    }

    .common-checkbox {
      padding: 0 !important;
      margin-right: 0.4rem;

      &.Mui-disabled {
        svg {
          path {
            fill: var(--grey-5, #ebeaea) !important;
          }
        }

        background: var(--grey-7, #f9f9f9) !important;
      }
    }

    .sla-rule-form-panel-form-divider {
      height: 1px;
      background: var(--grey-5, #ebeaea);
      margin-bottom: 0.5rem;
      margin-top: 1.5rem;
    }
  }

  .sla-rule-content-section {
    gap: 2rem;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    outline: none !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;

    .MuiAccordionSummary-root,
    .MuiAccordionDetails-root {
      padding: 0 !important;
      overflow: hidden !important;
    }

    .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      border: none;
      outline: none;
    }

    &::before {
      display: none !important;
    }

    .content-section-body {
      display: flex;
      flex-direction: column;
      gap: 35px;
    }

    .content-section-header {
      display: none !important;
    }

    .accordion-section-header {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0 !important;
      .accordion-section-title {
        font-style: normal;
        font-weight: 588;
        font-size: 16px;
        line-height: 14px;
        color: $grey-1;
      }
    }
  }

  .sla-rule-base-content-section {
    padding-bottom: 2rem;
    border-bottom: 1px solid $grey-5;
  }

  .sla-rule-validation-error-text {
    display: flex;
    gap: 0.7rem;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: $red-3;
  }

  .sla-rule-view-panel-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
    padding: 0.8rem 1rem;
    display: flex;
    border-top: 1px solid $grey-5;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;

    button {
      min-width: 110px;
    }

    .sla-rule-view-panel-footer-buttons {
      display: flex;
      gap: 1rem;
    }
  }

  .content-section {
    gap: 2rem;

    .multi-select-configuration-row {
      align-items: flex-end;
      display: grid;
      grid-template-columns: 10fr 10fr 2fr;
      justify-items: end;
    }
  }
}

.sla-rule-filter-chip-group {
  .filter-chip {
    padding: 0.4rem 0.6rem !important;
  }

  .add-filter-button {
    height: 31.78px !important;
  }
}
