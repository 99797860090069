.infoIcon {
  color: #b5bbc2;
  font-size: 16px !important;
  margin-left: 5px;
}

.generalIcon {
  border-radius: 50%;
  color: white;
  height: 33px;
  width: 33px;
  margin-left: 10px;
}
