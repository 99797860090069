.common-slider-container {
  color: $purple-3 !important;
  .common-slider-thumb {
    background-color: $white;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.08));
    stroke: #e5e7eb;

    &:hover {
      box-shadow: none !important;
    }
  }
  .Mui-focusVisible {
    box-shadow: none !important;
  }
  .common-slider-rail {
    background-color: #e5e7eb !important;
    border-radius: 4px;
    height: 8px !important;
  }
}
