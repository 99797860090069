.timeline-container {
  display: flex;
  flex-direction: column;

  .timeline-events-list-container {
    .children-item {
      padding-left: 16px;
      position: relative;
    }

    .children-item:not(:last-of-type),
    .children-item:last-of-type.default-item {
      border-left: 1px solid $grey-5;
    }
  }
}
