.scrollableText {
  white-space: initial !important;
}

.truncatedTextHeight {
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
}

.truncatedTextWidth {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
