.code-preview-container {
  width: 100%;
  display: flex;
  gap: 23px;

  .code-preview-header {
    display: flex;
    gap: 6px;
    align-items: center;
    p {
      color: $grey-1;
    }
    .headerIcon {
      height: 18px;
      display: flex;
      align-items: center;
      width: 18px;

      svg {
        path {
          fill: $grey-1;
        }
      }
    }
  }

  .code-preview-snippet {
    display: flex;
    position: relative;
    background-color: $grey-7;
    border: 1px solid $grey-5;
    border-radius: 8px;
    padding: 1px;

    .copyButton {
      position: absolute;
      z-index: 9999999;
      right: 13px;
      top: 5px;
      svg {
        width: 18px;
        height: 18px;
        path {
          fill: $grey-3;
        }
      }
    }

    .code-mirror {
      color: $grey-3 !important;
      font-family: 'Abel' !important;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: inherit;
      background-color: $grey-7 !important;

      .cm-scroller {
        background-color: $grey-7 !important;
        padding: 13px 53px 13px 16px;
      }

      span {
        color: $grey-3 !important;
      }
      .cm-editor {
        height: 97% !important;
        width: 100% !important;
        background-color: $grey-7 !important;
      }

      .cm-gutters {
        display: none !important;
      }
      .cm-activeLine {
        background-color: transparent;
      }
    }
  }
}

.commitLink {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0051ff !important;
}

.iacTags {
  background: #ffffff;
  border: 1px solid #d8e0e8;
  border-radius: 5px;
  margin: 0 4px;
  width: fit-content;
  padding: 5px 10px !important;

  p {
    color: #65717d;
    font-size: 12px;
  }
}
