.popover-filter {
  * {
    font-family: 'HEX Franklin v0.2';
  }

  .MuiPaper-root {
    min-width: 200px;
    max-height: 45vh;
    background: $white;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 4px;
    border: 1px solid $grey-6;
  }
}

.popover-list {
  margin-top: 3px;
  max-height: 250px;
  overflow: auto;

  .filter-item {
    &:hover .filter-item-delete-btn {
      display: flex;
      background-color: $grey-7;
    }

    .filter-item-delete-btn {
      cursor: pointer;
      margin: 0 0px 0 15px;
      padding: 0;
      display: none;
      justify-content: flex-end;
      align-items: center;

      svg {
        width: 13px;
        height: 14px;
        path {
          fill: $purple-4;
        }
      }
    }
    .item-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.popover-nav-bar {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}

.popover-back-icon {
  font-size: 17px !important;
  cursor: pointer;
}

.popover-nav-name {
  flex: 1;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #3d3d3d;
}

.popover-filter {
  margin-top: 4px !important;

  .MuiPaper-root {
    padding: 8px !important;
  }
}

.empty-result-text {
  text-align: center;
}

.date-picker-container {
  display: grid;
}

.number-input-container {
  display: grid;

  input {
    padding: 10px 14px !important;
  }
}

.date-picker-label {
  margin: 5px 0;
}

.flatpickr-input {
  height: 30px;
  // width: auto;
  text-align: center;
  border-radius: 8px;
  border: 1px solid $grey-5;
  margin-bottom: 5px;
  font-family: 'HEX Franklin v0.2';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
}
