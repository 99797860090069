.icon-button-container {
  position: relative;
  height: 40px;
  width: 40px;
  border: 1px solid $grey-5;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 18px;
  }

  .icon-button-badge {
    position: absolute;
    font-family: 'HEX Franklin v0.2';
    font-style: normal;
    font-weight: 588;
    font-size: 9px;
    line-height: 11px;
    text-align: center;
    color: $grey-1;
    background: $grey-6;
    border: 1px solid $grey-5;
    right: 1px;
    top: 1px;
    transform: translate(50%, -50%);
    border-radius: 50%;
    height: 16px;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
