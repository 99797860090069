.workspace-card {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background: $white;
  align-items: start;
  border: 1px solid $grey-5;
  border-radius: 8px;
  padding: 0.7rem;

  .workspace-card-icon {
    padding: 0.5rem;
    background: #f5f5f5;
    border-radius: 12px;
  }

  .workspace-card-text {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .workspace-card-count-text {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: left;
      color: $grey-1;
    }

    .workspace-card-helper-text {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: $grey-3;
    }
  }
}
