.trend-chip-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'HEX Franklin v0.2';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  border-radius: 4px;
  gap: 3px;
  padding: 4px 10px;

  &.negative-trend {
    color: $red-3;
    background: transparent;
    border: 1px solid $red-5;
    svg {
      fill: $red-3;
    }
  }

  &.positive-trend {
    color: $green-2;
    background: transparent;
    border: 1px solid $green-5;

    svg {
      fill: $green-2;
    }
  }
  &.neutral-trend {
    color: $grey-2;
    background: transparent;
    border: 1px solid $grey-5;

    svg {
      fill: $grey-2;
    }
  }
}
