.input-group-container {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  .input-group-autocomplete {
    min-width: 200px;

    .MuiInputBase-root,
    input {
      padding: 0 !important;
      border: none !important;
    }

    .auto-complete-expand-button {
      display: none !important;
    }

    .MuiInputBase-root {
      border-bottom: 1px solid $purple-3 !important;
      border-radius: 0px !important;
    }

    input {
      &::-webkit-input-placeholder {
        color: transparent !important;
      }
    }
  }

  .input-group-bordered-area {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.3rem;
    font-size: 14px;
    padding: 0.7rem;
    border: 1px solid $grey-4;
    border-radius: 5px;

    .input-group-configuration-text {
      display: flex;
      align-items: center;
      gap: 0.3rem;
    }

    .input-group-configuration-label {
      color: $purple-3;
    }

    .input-group-configuration-input {
      .auto-complete-container {
        padding: 0 !important;

        .auto-complete-label-select {
          display: none !important;
        }
      }
    }
  }

  .input-group-selection-area {
    display: flex;
    flex-direction: row;
    gap: 0.7rem;

    .input-group-tag {
      border: 1px solid $grey-5;
      padding: 0.5rem;
      border-radius: 5px;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
