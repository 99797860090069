.workflow-builder-header {
  padding: 13px 23px;
  border-bottom: 1px solid #e2e2e2;
  background: var(--white, #fff);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .workflow-builder-header-text-row {
    .workflow-builder-header-text {
      padding: 0;
      margin: 0;
      color: var(--Grey-1, #3d3d3d);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .workflow-builder-header-text-input {
      min-width: 240px;
      border-radius: 8px;
    }
  }

  .workflow-builder-header-button-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .workflow-builder-header-button-save {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 10px 16px;

      svg {
        path {
          fill: #ffffff;
        }
      }
    }

    .workflow-builder-header-button-close {
      padding: 10px 32px !important;
      border-radius: 8px !important;
      border: 1px solid var(--Grey-5, #ebeaea) !important;
      background: var(--White, #fff) !important;
      box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05) !important;
      color: var(--Grey-1, #3d3d3d) !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: normal !important;
    }
  }
}
