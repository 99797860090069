.comparative-dashboard-page-container {
  .dashboard-page-body {
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .open-findings-widget {
      .base-chart-main-container {
        position: relative;
        height: 240px;
        width: 90vw;
        display: inline-block !important;

        canvas {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }

  chartjs-tooltip {
    background-color: $white !important;
    padding: 6px 10px !important;
    min-width: auto !important;
    color: $grey-1 !important;
    border-radius: 4px;
    z-index: 9999;
    filter: drop-shadow(5px 5px 7px rgba(112, 112, 112, 0.339));

    td {
      display: flex;
      align-items: center;

      .tooltip-label {
        min-width: 82px;
      }
    }
    .tooltip-caret {
      border-left: solid 5px transparent;
      border-right: solid 5px transparent;
      border-bottom: solid 5px #fff;
      height: 0;
      width: 0;
    }
  }
}

#comparative-widget-legend-container {
  margin-top: 1rem;

  ul {
    justify-content: center !important;

    li {
      span {
        border-radius: 50%;
        width: 12px !important;
        height: 12px !important;
      }

      b {
        color: rgb(102, 102, 102);
        margin: 0px;
        padding: 0px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: $grey-1;
      }
    }
  }
}

.comparative-dashboard-section-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .ag-root-wrapper {
    padding: 0 !important;
    border: none !important;
  }

  .widget-divider {
    border-color: $grey-5;
    margin: 15px 0 10px 0;
  }

  .comparative-dashboard-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h5 {
      color: $grey-3;
    }

    .auto-complete-container {
      width: 10%;

      p {
        display: none;
      }
    }

    .comparative-dashboard-section-timeline-filter-input-root {
      height: 32px;
      display: flex;
      align-items: center;
      align-content: center;
    }

    // .comparative-dashboard-section-timeline-filter-root {
    //   width: 100%;

    //   .comparative-dashboard-section-timeline-filter-input-root {
    //     border: 1px solid $grey-4  !important;
    //     border-radius: 4px !important;
    //     background: $white  !important;
    //     padding: 0px 8px !important;

    //     input {
    //       transition: 200ms;
    //       user-select: none;
    //       font-family: 'Manrope';
    //       font-style: normal;
    //       font-weight: 500;
    //       font-size: 14px;
    //       line-height: 24px;
    //       color: $grey-1;

    //       padding: 4px 5px;
    //       padding-right: 0px;

    //       &:hover+button+fieldset,
    //       &:focus+button+fieldset {
    //         border: none !important;
    //         outline: none;
    //       }
    //     }
    //   }
    // }
  }

  .comparative-dashboard-section-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .comparative-dashboard-section-body-card-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}
