.integration-logo {
  img {
    max-width: 64px;
    height: 32px;
  }
}

.integration-list-modal.common-dialog {
  .dialog-content {
    overflow-y: hidden;
  }

  .dialog-actions {
    margin-top: 0 !important;
  }

  .MuiPaper-root {
    min-width: 980px !important;
  }

  .integration-logo {
    width: 28px;
  }

  .ag-root-wrapper {
    border: none !important;
    padding: 0;
  }

  .integration-list-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .integration-list-modal-table {
      .integrations-list-data-grid {
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    .settings-pagination {
      margin-bottom: 0 !important;
    }
  }
}
