.business-unit-form-panel-loading {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.business-unit-form-panel {
  padding: 1.5rem;
  padding-right: 1rem;
  background: $white;
  height: 100%;
  position: relative;

  .MuiChip-root {
    padding: 4px 6px;
  }

  .business-unit-form-panel-body {
    height: 92%;
    overflow: auto;

    .business-unit-form-panel-form {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      .business-unit-form-panel-form-divider {
        border: 0.5px solid $grey-5;
      }

      .business-unit-form-panel-form-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
      }

      .business-unit-form-panel-form-general-info-group {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .business-scope-text-field {
          width: 60%;
        }

        .business-impact-multi-select-field {
          .MuiFormControl-root {
            background: transparent;
          }
          .MuiInputBase-root {
            width: 30%;
          }
        }
      }

      .business-unit-form-panel-form-related-environments-group {
        display: flex;
        flex-direction: column;
        gap: 1.75rem;

        .business-unit-linked-domains-text-field {
          text-decoration: underline;
        }
      }

      .business-unit-form-panel-form-communication-group {
        display: flex;
        flex-direction: column;
        gap: 1.75rem;

        .business-unit-related-team-multi-select-field {
          .MuiFormControl-root {
            background: transparent;
          }
          .MuiInputBase-root {
            width: 49.5%;
          }
        }
      }

      .business-unit-form-panel-form-general-configuration-group {
        .business-unit-form-panel-risk-attributes {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;
          column-gap: 1.5rem;

          .checkbox-label {
            border: 1px solid $grey-4;
            border-radius: 4px;
          }
        }
      }

      .business-unit-form-panel-accordion-icon {
        padding: 0.7rem;
        background: $grey-5;
        border-radius: 16px;
      }
      .business-unit-form-panel-accordion {
        box-shadow: none;
        border: none;
        outline: none;
        border: 1px solid $grey-5;
        border-radius: 8px;
        width: 100%;
        margin: 0 !important;

        &::before {
          display: none;
        }

        .accordion-section-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.7rem;

          .accordion-section-title-description {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            .accordion-section-title {
              display: flex;
              gap: 0.3rem;

              .accordion-section-title-text {
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 20px;
                color: $grey-1;
              }

              .accordion-section-helper-text {
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 20px;
                color: $grey-3;
              }
            }

            .accordion-section-description {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: $grey-1;
            }
          }
        }

        .MuiAccordionDetails-root {
          padding-bottom: 1.8rem;
        }
      }
    }
  }
}

.business-unit-form-modal-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .business-unit-form-modal-description {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey-1;
  }

  .business-unit-form-modal-buttons {
    justify-self: end;
    align-self: flex-end;

    button {
      padding: 0.7rem 2rem !important;
      border-radius: 6px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;

      &:nth-child(2) {
        background-color: $purple-2;
        color: $white !important;

        &:hover {
          background-color: $purple-2 !important;
          color: $white !important;
        }
      }
    }
  }
}

.business-unit-form-panel-general-configuration-container {
  display: flex;
  flex-direction: column;

  .business-unit-form-panel-form-risk-attributes-configuration-group {
    padding-bottom: 2rem;
    border-bottom: 1px solid $grey-5;
  }

  .business-unit-form-panel-form-compliance-requirements-configuration-group {
    padding-top: 2rem;
    border-bottom: 1px solid $grey-5;
  }
}

.business-unit-form-panel-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-top: 1px solid $grey-5;
  background: $white;
  padding-left: 3rem;
  width: 100%;

  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 200;

  .business-unit-form-panel-footer-buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .business-unit-form-panel-footer-validation-error {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: $red-3;

    .business-unit-form-panel-footer-validation-error-text {
      display: flex;
      gap: 0.7rem;
      align-items: center;
    }
  }
}

.business-unit-form-panel-application-icon,
.business-unit-form-panel-avatar-icon {
  height: 17px !important;
  width: auto !important;
  margin: auto;
}
