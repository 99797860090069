@mixin campaign-select-text {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #3d3d3d !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: left !important;
}

.campaign-select-dropdown-list {
  background: #ffffff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;

  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 5px !important;

    p {
      @include campaign-select-text;
    }
  }
}

.campaigns-page-container {
  height: 1200px;
  padding: 1rem;

  .campaigns-header {
    height: 240px !important;

    .MuiPaper-root {
      background: white;
      padding: 0 !important;
      height: 100% !important;
      display: flex;
      align-items: center;
      padding: 20px !important;
    }
  }

  .MuiPaper-root {
    background: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 16px;
    height: 100%;
  }
}

.campaign-header-item {
  .MuiPaper-root {
    min-height: 250px;
    height: 250px;
    padding: 15px 20px !important;
  }
}

.title-header {
  font-weight: 800 !important;
  font-size: 34px !important;
  color: #31156f;
}

.campaigns-list-page-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 20px;

  .campaigns-item {
    height: 234px;
  }

  .MuiPaper-root {
    background: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 16px;
    height: 100%;
    padding: 0 !important;
  }

  .campaigns-item {
    background: white;
    padding: 15px 20px !important;
    max-height: 250px !important;
    display: flex;
    align-items: center;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
  }
}

.campaign-select-dropdown-section {
  .campaign-select-dropdown {
    width: 100%;

    .campaign-select-dropdown-input-root {
      background-color: #ffffff !important;
      padding: 0 !important;
      width: 100%;
      cursor: pointer;
    }

    .campaign-select-dropdown-input {
      padding: 0 !important;
      max-width: 85%;
      user-select: none !important;
      @include campaign-select-text;
      font-family: 'HEX Franklin v0.2' !important;
      font-style: normal !important;
      font-weight: 800 !important;
      font-size: 20px !important;
      line-height: 24px !important;
      color: #3d3d3d !important;

      &:-webkit-input-placeholder {
        font-family: 'HEX Franklin v0.2' !important;
        font-style: normal !important;
        font-weight: 800 !important;
        font-size: 20px !important;
        line-height: 24px !important;
        color: #3d3d3d !important;
      }

      &:hover + button + fieldset,
      &:focus + button + fieldset {
        display: none !important;
      }
    }

    button {
      span {
        color: #562abb;
      }
    }
  }
}
