.settings-data-grid {
  .cloudProviderLogo {
    height: 24px;
    width: 24px;
    object-fit: contain;
  }

  .workspaces-icon-container {
    display: flex;
    align-items: center;
    gap: 7px;

    .icon {
      display: flex;
      align-items: center;
      gap: 5px;
      border-radius: var(--rounded, 4px);
      border: 1px solid var(--Grey-5, #ebeaea);
      padding: 5.5px var(--25, 6px) 5.5px var(--25, 7px);
      height: 25px;

      path {
        fill: $grey-1;
      }
    }
  }
  .risk-badge-container {
    display: flex;
    align-items: center;
    .risk-badge-icon {
      border-radius: var(--3, 12px);
      border: 1px solid var(--Grey-5, #ebeaea);
      padding: 7px;
      min-height: 33px;
      min-width: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 6px;

      svg {
        min-width: 20px;
        min-height: 20px;
      }
      path {
        fill: $grey-1;
      }
    }
  }

  p {
    color: $grey-1;
  }

  .owner-email {
    text-decoration-line: underline;
    padding: 0;
    display: block;
    margin: 0;

    &:hover {
      text-decoration-line: underline;
      background-color: transparent;
    }
  }

  .delete-action {
    border-radius: 6px;
    border: 1px solid $grey-5;
    margin: 0;
    padding: 8px 14px;
    min-width: 32px;
    height: 32px;

    &:disabled {
      path {
        fill: $grey-4 !important;
      }
    }

    &:hover {
      background-color: transparent;
    }

    path {
      fill: $grey-1;
    }

    svg {
      height: 14px;
      width: 13px;
    }
  }

  .users-org-column-content {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 2px;

    .node-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 18px;
        height: 18px;
        color: $grey-3;

        path {
          fill: $grey-3;
        }
      }
    }
  }
}
.risk-badge-tooltip {
  height: 20px;
  width: auto;
  display: inline-flex;
  .tooltip-title {
    color: var(--Grey-1, #3d3d3d) !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
  }
}
