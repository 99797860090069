.common-dialog-ticket-create .MuiDialog-container {
  min-width: 580px;
  min-height: 410px;
}

.ticket-create-form,
.findings-bulk-actions-form {
  .no-active-connections-message {
    background-color: $yellow-6;
    border: 1px solid $yellow-6;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 8px;
    margin-bottom: 12px;

    svg {
      path {
        fill: $yellow-1;
      }
    }
  }
  .bulk-changes-action-failed {
    background-color: $yellow-6;
    border: 1px solid $yellow-6;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 8px;
    margin-bottom: 12px;

    svg {
      path {
        fill: $yellow-1;
      }
    }
  }
  .multi-findings-warning {
    height: 40px;
    background-color: $grey-6;
    display: flex;
    align-items: center;
    padding: 8px 18px;
    gap: 8px;
    border-radius: 8px;

    p {
      display: flex;
      align-items: center;
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: $grey-3;
      }
    }
  }

  .multi-findings-automate {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 8px 18px;
    gap: 8px;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid $grey-5;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: $grey-1;
      }
    }
  }
}

.ticket-action-response-modal {
  .MuiDialog-container {
    min-width: 880px !important;
    min-height: 317px !important;
  }

  .ticket-action-response-container {
    display: flex;
    padding-bottom: 2rem;
    flex-direction: column;

    .container-divider {
      height: 40px;
    }

    .ticket-action-failed {
      background-color: $yellow-6;
      border: 1px solid $yellow-6;
      border-radius: 8px;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 1rem;
      gap: 8px;
      margin-bottom: 12px;

      svg {
        path {
          fill: $yellow-1;
        }
      }
    }
    .ticket-action-success {
      background-color: $purple-6;
      border: 1px solid $purple-6;
      border-radius: 8px;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 1rem;
      gap: 8px;
      margin-bottom: 12px;

      svg {
        path {
          fill: $purple-3;
        }
      }
    }
    .failed-item {
      padding: 0;
    }

    .ticket-item-details {
      min-height: 40px;
      min-width: inherit;
      display: flex;
      align-items: center;

      .view-ticket-link {
        display: flex;
        color: $purple-3;
        justify-content: flex-end;
        align-items: flex-start;
        margin-top: 4px;

        svg {
          width: 18px;
          height: 16px;
          margin-right: 10px;

          path {
            fill: $purple-3;
          }
        }
      }

      .item-title {
        line-height: 24px;
      }

      .failed-items-accordion {
        width: 100% !important;
        background-color: transparent;
        box-shadow: none;
        padding: 0 !important;
        margin: 0;
        border: none;
        position: relative;

        .MuiAccordionDetails-root {
          padding: 0 !important;
        }
        .Mui-expanded:not(.MuiAccordionSummary-expandIconWrapper) {
          margin: 9px 2px 0;
          min-height: 10px;
        }
        .MuiAccordionSummary-expandIconWrapper {
          position: absolute;
          top: 20px;
          right: 23px;
        }
      }
      .failed-item-message {
        background-color: $grey-6;
        border: 1px solid $grey-6;
        border-radius: 8px;
        margin: 13px 20px;
        padding: 10px 20px;
      }
    }
  }
}

.multi-finding-ticket-create-form-loading {
  height: 35vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.multi-finding-ticket-create-modal {
  .common-modal-header-title-container {
    display: flex;
    align-items: center;
    gap: 10px;

    .common-modal-header-title-icon {
      min-height: 40px;
      min-width: 40px;
      border-radius: 10px;
      border: 1px solid var(--Grey-5, #ebeaea);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .common-modal-header-title {
      color: #3d3d3d;
      font-size: 22px;
      font-style: normal;
      font-weight: 588;
      line-height: 24px;
    }
  }
  .multi-finding-ticket-create-container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .multi-finding-ticket-create-form {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }

  .multi-finding-ticket-create-footer {
    margin-top: 10px;
    display: flex;
    gap: 20px;
    justify-content: end;

    button {
      &:first-child {
        width: 100px;
      }

      &:last-child {
        width: 110px;
      }
    }
  }
}
