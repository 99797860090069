.thread-list-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .thread-item {
    display: flex;
    align-items: flex-start;
    gap: 12px;

    .thread-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      border: 1px solid var(--Grey-5, #ebeaea);
      background: #fff;
    }

    .thread-content {
      flex: 1;

      .thread-description {
        color: var(--Grey-1, #3d3d3d);
        font-family: 'HEX Franklin v0.2';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
        padding: 0;
      }
    }
  }
}
