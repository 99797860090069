.aws-integration-management-section-container {
  .aws-integration-management-section-content {
    padding: 20px 0;

    .aws-integration-management-section-single,
    .aws-integration-management-section-multiple {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .aws-integration-management-section-multiple {
      .aws-integration-management-section-multiple-description {
        color: var(--Grey-1, #3d3d3d);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }

      .aws-integration-management-section-multiple-instructions {
        color: var(--Grey-1, #3d3d3d);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .aws-integration-management-section-multiple-instruction {
          display: flex;
          gap: 10px;
          align-items: center;

          .aws-integration-management-section-multiple-instruction-index {
            min-height: 24px;
            min-width: 24px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--Grey-5, #ebeaea);
          }
        }
      }
    }

    .aws-integration-management-deploy-stack {
      padding: 7px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f9f9f9;
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      border-radius: 6px;
      justify-content: space-between;

      .aws-integration-management-deploy-stack-button {
        text-wrap: nowrap;
        color: var(--Purple-3, #7547dc);
        font-size: 14px;
        font-style: normal;
        font-weight: 588 !important;
        line-height: normal;
      }
    }
  }
}
