.key-value-input {
  .key-value-selections {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .key-value-selection-area {
      max-height: 300px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .key-value-selection {
        display: flex;
        align-items: center;
        gap: 24px;

        .key-value-selection-inputs {
          display: flex;
          align-items: center;
          gap: 16px;
          width: 100%;

          .key-value-key-selection-input {
            input {
              max-width: 80%;
              text-overflow: ellipsis;
            }
          }

          .auto-complete-container {
            width: 200px;
            overflow: hidden;

            &:nth-child(2) {
              width: 280px !important;
            }

            p {
              display: none;
            }

            .MuiChip-root {
              max-width: 100px !important;
              max-height: 20px !important;
              padding: 12px 0 !important;
              margin: 0 !important;
              margin-right: 3px !important;
              font-size: 12px !important;

              .MuiChip-label {
                padding-left: 6px !important;
                padding-right: 12px !important;
              }

              .MuiChip-deleteIcon {
                height: 13px !important;
                width: 12px !important;
              }
            }

            .MuiInputBase-root {
              flex-wrap: nowrap !important;
            }
          }

          input {
            height: 12px !important;
          }
        }
      }
    }
  }
}

.key-value-selection-delete-button {
  button {
    height: 32px !important;
    max-width: 32px !important;
    min-width: 32px !important;
    border-radius: var(--rounded-md, 6px);
    border: 1px solid var(--Grey-5, #ebeaea);
    background: var(--White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);

    &:disabled {
      border-radius: var(--rounded-md, 6px);
      border: 1px solid var(--Grey-5, #ebeaea);
      background: var(--Grey-6, #f5f5f5);
      box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);

      svg {
        path {
          fill: #c7c4c4 !important;
        }
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }

    svg {
      width: 12px;
    }
  }
}

.key-value-add-button {
  button {
    height: 32px;
    padding-left: 0;
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--Purple-3, #7547dc);
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    svg {
      height: 12px;
    }
  }
}
