.findingWorkflows {
  .workflowSelect {
    max-width: 230px;
    min-width: 170px;
    background: #f5f8fd;
    border-radius: 8px;

    .MuiSelect-select {
      padding: 10px !important;
    }

    fieldset {
      border: none;
    }

    .material-icons {
      color: #0a0b50;
      padding-right: 4px;
    }

    .workflowLabel {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: start;
      white-space: nowrap;
    }
  }
}
