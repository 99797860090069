.business-unit-carousel-card-tooltip-container {
  font-family: 'HEX Franklin v0.2';
}

.business-unit-carousel-list-container {
  .business-unit-carousel-card-container-active {
    border: 1.5px solid #7547dc !important;
  }

  .business-unit-carousel-card-container {
    transition: 200ms;
    background: $white;

    &:hover {
      border: 1.5px solid #7547dc;
    }

    .business-unit-carousel-card-icon-button {
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }

    background-color: $white;
    cursor: pointer;
    min-height: 130px;
    border: 1.5px solid $grey-5;
    border-radius: 8px;
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 200ms;

    &:hover {
      border: 1.5px solid #7547dc;
    }

    .business-unit-carousel-card-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .business-unit-carousel-card-cloud-providers {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        .business-unit-carousel-card-more-items-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;

          .business-unit-carousel-card-more-items-indicator {
            background: $grey-6;
            border: 1px solid $grey-5;
            border-radius: 8px;
            font-family: 'HEX Franklin v0.2';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 12px;
            color: $grey-2;
            padding: 0.3rem 0.2rem;
          }
        }
      }
    }

    .business-unit-carousel-card-footer {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .business-unit-carousel-card-title {
        font-family: 'HEX Franklin v0.2';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 15px;
        color: $grey-1;
        max-width: 120px;
      }

      .business-unit-carousel-card-description {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.3rem;
        font-family: 'HEX Franklin v0.2';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: $grey-3;
      }
    }
  }
}
