#posture-dashboard-section-by-category-chart-legend-container {
  ul {
    display: flex;
    flex-direction: column !important;
    gap: 0.4rem;
    padding: 0 !important;

    span {
      width: 12px !important;
      height: 12px !important;
      border-radius: 50%;
    }

    b {
      font-family: 'HEX Franklin v0.2';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: $grey-1;
    }
  }
}

.posture-dashboard-section-by-category-chart-container {
  .dashboard-chart-card-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .base-chart-container-box {
    max-width: 250px;
    max-height: 245px;
  }
}

.posture-content-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  chartjs-tooltip {
    background-color: #404040 !important;
    min-width: 50px !important;
    color: $grey-1 !important;
    padding: 5px 15px !important;
    border-radius: 4px;
    z-index: 9999;
    filter: drop-shadow(5px 5px 7px rgba(112, 112, 112, 0.339));

    table {
      width: 100%;

      td {
        display: flex;
        align-items: center;
        justify-content: center;

        .tooltip-label {
          display: none;
        }
      }
    }

    .tooltip-caret {
      border-left: solid 5px transparent;
      border-right: solid 5px transparent;
      border-bottom: solid 5px #404040;
      height: 0;
      width: 0;
    }
  }

  .operation-metrics-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .operation-metrics-cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;

      .dashboard-widget-card-container {
        gap: 2rem !important;
        .dashboard-widget-card-header-icon {
          border-radius: 12px;
          border: 1px solid var(--grey-5, #ebeaea);
          background-color: #ffffff;

          path {
            fill: $grey-1;
          }
        }
      }
    }

    .operation-metrics-charts {
      display: flex;
      gap: 1rem;

      .dashboard-chart-card-container {
        flex: 1;

        .dashboard-chart-card-title {
          margin-bottom: 1rem;
        }
      }
    }
  }

  #posture-dashboard-section-by-severity-chart-legend-container {
    width: 50%;
    margin: auto;
    margin-top: 1.5rem;

    ul {
      padding: 0 !important;

      span {
        width: 12px !important;
        height: 12px !important;
        border-radius: 50%;
      }

      b {
        font-family: 'HEX Franklin v0.2';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: $grey-6;
      }
    }
  }
}
