.business-impact-chip {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  border-radius: 4px;
}
.business-impact-critical {
  color: $red-1;
  border: 1px solid $red-6;
}

.business-impact-high {
  color: $red-3;
  border: 1px solid $red-6;
}

.business-impact-medium {
  color: $orange-3;
  border: 1px solid $orange-6;
}

.business-impact-low {
  color: $green-3;
  border: 1px solid $green-5;
}
