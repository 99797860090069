.operational-widget-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .dashboard-widget-card-header-icon {
    border-radius: 12px !important;
    border: 1px solid var(--grey-5, #ebeaea) !important;
    background-color: #ffffff !important;
    path {
      fill: $grey-1;
    }
  }

  &:hover {
    border: 1.5px solid var(--purple-3, #7547dc) !important;
    cursor: pointer;
  }
}

.operational-widget-card-selected-container {
  border: 1.5px solid var(--purple-3, #7547dc) !important;
}

.operational-widget-card-header-sub-title {
  font-family: 'HEX Franklin v0.2';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: $grey-3;
}

.operational-widget-card-body-trend {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'HEX Franklin v0.2';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  border-radius: 4px;
  gap: 0.2rem;
  padding: 4px 10px;

  svg {
    fill: black;
  }
}

.operational-widget-card-negative-trend {
  color: $red-3;
  background: $red-6;
  svg {
    fill: $red-3;
  }
}

.operational-widget-card-positive-trend {
  color: $green-2;
  background: $green-6;
  svg {
    fill: $green-2;
  }
}
