.cloud2code-popover {
  .drawer-paper {
    width: 70%;
    .finding-content-cloud-to-code-sub-section-body {
      gap: 30px;
    }

    .cloud-to-code-content-section-row {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
      gap: 10px;
      row-gap: 33px;
      padding-bottom: 25px;
      border-bottom: 1px solid $grey-5;
      .interactive-label-text {
        width: 14vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        a {
          text-decoration: none;
          color: $purple-3 !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
        }
      }
    }
    .code-preview-snippet {
      height: 170px;
    }

    .common-simple-data-grid-pagination {
      padding: 0 1rem;
    }
  }
  .ag-root-wrapper {
    border: none;
    padding: 0;
  }

  .code-event-workflow {
    height: 300px !important;
    .react-flow {
      max-height: 300px !important;
    }
  }
}
