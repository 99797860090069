.risk-group-grid-risk-name-cell-counter {
  font-weight: 700;
  margin-left: 3px;
}

.risk-group-grid-risk-category-cell {
  max-width: 150px;

  .risk-group-grid-risk-category-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #525252;
  }
}

.risk-group-grid-cloud-resources-cell {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: $grey-1;
}

.risk-group-grid-root-causes-cell {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: $grey-1;
}

.risk-grid-last-activity-avatar-icon {
  img {
    border-radius: 50%;
  }
}

.risk-grid-last-activity-app-icon {
  img {
    height: 20px;
    width: 20px;
  }
}

.resource-group-grid-actions-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  button {
    padding: 8px 10px;
    color: var(--Grey-1, #3d3d3d) !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 588 !important;
    line-height: normal !important;
    border-radius: 6px !important;
    border: 1px solid var(--Grey-5, #ebeaea) !important;
    background: var(--White, #fff) !important;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    svg {
      path {
        fill: #3d3d3d;
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }
}
