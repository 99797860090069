.dashboard-reports-container {
  .dashboard-reports-widget-body {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    .base-chart-legend-container-row {
      margin-top: 1rem;

      ul {
        justify-content: center !important;

        li {
          span {
            border-radius: 50%;
            width: 12px !important;
            height: 12px !important;
          }

          b {
            color: rgb(102, 102, 102);
            margin: 0px;
            padding: 0px;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: $grey-1;
          }
        }
      }
    }
  }
}

.report-action-cell {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;

  .report-action-button {
    display: flex;
    border-radius: 6px;
    border: 1px solid var(--Grey-5, #ebeaea);
    background: var(--White, #fff);
    height: 32px;
    max-width: 32px;
    min-width: 32px;

    svg {
      height: 13px !important;
    }
  }
}

.report-rule-drawer-content {
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 100%;

  .report-rule-drawer-content-header {
    display: flex;
    align-items: center;
    gap: 12px;

    .report-rule-drawer-content-header-icon {
      min-height: 48px;
      min-width: 48px;
      border-radius: 16px;
      border: 1px solid #ebeaea;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .report-rule-drawer-content-header-title-area {
      display: flex;
      flex-direction: column;
      gap: 3px;
      .report-rule-drawer-content-header-title {
        color: #3d3d3d;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }

      .report-rule-drawer-content-header-sub-title {
        color: var(--Grey-1, #3d3d3d);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .report-rule-drawer-content-body {
    display: flex;
    flex-direction: column;
    gap: 36px;
    overflow: auto;

    .report-rule-drawer-content-body-section {
      display: flex;
      flex-direction: column;
      gap: 23px;
    }

    .report-rule-drawer-content-body-metrics-section {
      border: 1px solid var(--Grey-5, #ebeaea);
      border-radius: 16px;
      padding: 17px 23px;
      display: flex;
      flex-direction: column;
      gap: 28px;

      .report-rule-drawer-content-body-metrics-section-title {
        color: var(--Grey-3, #7f7f7f);
        font-size: 14px;
        font-style: normal;
        font-weight: 588;
        line-height: 16px;
      }

      .report-rule-drawer-content-body-metrics-section-inputs {
        display: flex;
        flex-direction: column;
        gap: 28px;
      }
    }

    .content-section {
      gap: 28px;

      .content-section-body {
        display: flex;
        flex-direction: column;
        gap: 23px;

        .input-label-end-icon {
          margin-left: 0.3rem !important;

          svg {
            width: 14px !important;
            height: 14px !important;
          }
        }
      }
    }
  }

  .report-rule-drawer-content-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;

    .report-rule-drawer-content-submit-buttons {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .report-rule-drawer-content-preview-button {
      button {
        display: flex;
        align-items: center;
        gap: 3px;
      }
    }
  }
}

.report-rule-date-picker {
  .MuiTypography-body1 {
    font-family: 'HEX Franklin v0.2';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: $grey-1;
  }

  .MuiLink-root {
    color: $grey-3;
  }

  input {
    padding: 6px 12px !important;
    background: #ffffff !important;
    border: 1px solid $grey-4 !important;
    border-radius: 4px !important;
    text-align: left !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: $grey-1;
    margin-bottom: 0 !important;

    &::placeholder {
      color: #a8a8a8;
      opacity: 1;
    }
  }

  .date-picker-input-outline {
    display: none !important;
  }

  .date-picker-input-icon {
    padding-right: 0.5rem;
  }

  svg {
    width: 14px !important;
    height: 14px !important;
  }
}

.report-rule-type-selection-popover {
  border-radius: 8px !important;
  border: 1px solid var(--Grey-5, #ebeaea) !important;
  background: var(--White, #fff) !important;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;

  color: var(--Grey-1, #3d3d3d) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;

  padding: 8px !important;

  min-width: 225px !important;

  ul {
    padding: 0 !important;
    li {
      padding: 12px 8px !important;
      display: flex;
      align-items: center;
      min-width: 210px;
      justify-content: space-between;
    }
  }
}

.report-rule-drawer-content-image-tooltip {
  display: flex;
  flex-direction: column;
  gap: 3px;

  color: var(--Grey-1, #3d3d3d);
  text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.report-rule-info-message {
  background: var(--Grey-6, #f5f5f5);
  color: var(--Grey-1, #3d3d3d);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  border-radius: 8px;
}
