.workflow-builder-plus-edge-container {
  .workflow-builder-plus-edge-delete-button {
    background-color: white !important;
    border: 1px solid #7547dc !important;
    height: 24px;
    width: 24px;

    svg {
      path {
        fill: #7547dc;
      }
    }
  }

  .workflow-builder-plus-edge-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background: #ffffff !important;
    text-align: center;
    border: 1px dashed #7547dc;
    border-radius: 20px;
    font-weight: bold;
    color: #7547dc;
    overflow: hidden;
    padding: 0rem 0.1rem;

    .workflow-builder-plus-button {
      box-shadow: none !important;
    }
  }
}
