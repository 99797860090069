.rcb-window-embedded {
  * {
    font-family: 'HEX Franklin v0.2' !important;
  }

  .rcb-view-history-button {
    display: none !important;
  }

  .rcb-typing-indicator {
    margin-left: 16px;
    margin-top: 8px;
    padding: 12px 16px;
  }

  .rcb-message-prompt-text {
    display: none !important;
  }

  .chat-bot-json-container {
    margin-top: 8px;
    padding: 12px 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 16px;
  }

  .rcb-chat-window {
    width: 70vw !important;
    height: 90vh !important;
    border-radius: 12px !important;
  }

  .rcb-chat-header {
    align-items: center !important;
  }

  .rcb-bot-avatar {
    background-size: 25px 25px !important;

    background-position: center !important;
    background-repeat: no-repeat !important;
  }

  .rcb-chat-header-container {
    background-color: var(--Purple-1, #31156f) !important;
    background-image: none !important;
  }

  .rcb-bot-message {
    background-color: #7547dc !important;
    border-radius: 12px !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .rcb-bot-message:has(> div) {
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .rcb-user-message {
    background-color: var(--Grey-5, #ebeaea) !important;
    border-radius: 12px !important;
    color: var(--gray-6, #3d3d3d) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
  }

  .rcb-send-button {
    width: 70px !important;
    height: 39px !important;
    border-radius: 12px !important;
    box-shadow: none !important;
    background: var(--Purple-3, #7547dc) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    transition: 200ms !important;

    &:hover {
      background: var(--purple-2, #562abb) !important;
    }

    .rcb-send-icon {
      width: 25px !important;
      height: 25px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      transform: none !important;

      svg {
        width: 25px !important;
        height: 25px !important;
      }
    }
  }

  .rcb-chat-input {
    border-top: none !important;
  }

  .rcb-chat-input-textarea {
    border: 1px solid var(--Grey-4, #c7c4c4) !important;
    height: 60px !important;
    box-sizing: border-box !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border-radius: 8px !important;
    line-height: 20px;

    &:focus {
      box-shadow: none !important;
      border-color: var(--Purple-3, #7547dc) !important;
    }
  }
}

.chat-findings-grid-container {
  width: 100% !important;
  padding: 1rem;

  .ag-root-wrapper {
    padding: 0;
    border: none !important;
  }
}
