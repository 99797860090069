.cve-main-container {
  background-color: #fff;
  padding: 1rem 1.5rem;

  .cve-main-table {
    height: 350px;

    .cve-table-view {
      height: 260px;
    }
  }
}

.cve-detail-main-container {
  background-color: #fff;
  padding: 1rem 1.5rem;

  .cve-detail-main-table {
    height: 200px;

    .cve-detail-table-view {
      height: 110px;
    }
  }
}

.cve-table-container {
  border: none !important;
  overflow: hidden;

  .cve-table-column-sort-icon {
    display: block;
  }

  .cve-table-icon-button {
    border: 1px solid #7547dc;
    box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05);
    border-radius: 8px;
    width: 32px !important;
    height: 32px;
    min-width: 0px !important;
    background-color: #ffffff;
    cursor: pointer;

    &:disabled {
      cursor: default;
      border: 1px solid #beace9 !important;
      color: #beace9 !important;

      svg {
        fill: #beace9;
      }
    }
  }

  .cve-table-column-header-container {
    background: #f0f5ff;
    border-radius: 16px;
    border: none;
    padding: 0rem 2rem;

    .cve-table-column-separator {
      display: none;
    }

    .cve-table-column-header {
      width: 100% !important;
    }

    .cve-table-column-title {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #31156f;
    }

    .cve-table-column-title-sort {
      outline: none !important;
      border: none !important;

      button {
        visibility: visible;

        &:hover {
          background: transparent !important;
        }

        span {
          display: none !important;
        }
      }
    }

    .cve-table-column-title-sort-icon-container {
      .cve-table-column-title-sort-icon-container-placeholder-box {
        height: 10px;
      }
    }
  }

  .cve-table-column-title-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
  }

  .cve-table {
    .cve-table-row:hover {
      background: #f0f5ff;
    }

    .cve-table-row-container {
      background: #ffffff;
      border: 1px solid #787878;
      border-radius: 16px;
      transition: all 200ms;
      overflow: hidden;
      cursor: pointer;
      width: 100%;

      .cve-table-row-expansion-area {
        transition: all 200ms;
      }

      .cve-table-row-expansion-area-active {
        min-height: 200px;
      }

      .cve-table-row-expansion-area-not-active {
        display: none;
      }

      .cve-table-row {
        display: grid;
        grid-template-columns: 180px 140px 140px;
        align-items: center;
        width: 100%;
        padding: 1rem 1.5rem;

        .cve-table-row-expansion-icon {
          margin-right: 1rem;
          cursor: pointer;
          transition: transform 200ms;
          position: absolute;
          left: 15px;
        }

        .cve-table-row-expansion-icon-open {
          transform: rotateZ(-90deg);
        }

        .cve-table-row-risk-cell {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
          padding-left: 1rem;

          .cve-table-row-risk-cell-main-container {
            display: flex;
            flex-direction: row;
            gap: 2px;

            .cve-table-row-risk-cell-main {
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 16px;
              color: #525252;
              max-width: 430px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .cve-table-row-risk-cell-counter {
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: #525252;
            }
          }
        }

        .cve-table-row-security-standard-cell {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #525252;
        }

        .cve-table-row-actions-cell {
          display: flex;
          flex-direction: row;
          justify-content: end;
        }

        .cve-table-row-code-vs-cloud-cell {
          display: flex;
          flex-direction: column;
          gap: 3px;

          .cve-table-row-code-vs-cloud-cell-counter-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 136px;

            .cve-table-row-code-counter,
            .cve-table-row-cloud-counter {
              font-weight: 400;
              font-size: 13px;
              color: #c7c4c4;
            }
          }

          .cve-table-row-code-vs-cloud-cell-bar {
            width: 136px;
            height: 8px;
            border-radius: 6px;
            display: flex;
            flex-direction: row;
            overflow: hidden;

            .cve-table-row-code-cell-bar {
              background: #67e0bd;
              height: 100%;
            }

            .cve-table-row-cloud-cell-bar {
              background: #7547dc;
              height: 100%;
            }
          }
        }
      }
    }

    .cve-table-virtual-scroller {
      padding: 1rem 0rem;

      .cve-table-virtual-scroller-render-zone {
        min-width: 100%;
        width: inherit;
        display: flex;
        flex-direction: column;
        justify-self: stretch;
        gap: 1.5rem;
      }
    }
  }
}
