.interactive-label {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $grey-1;

  .interactive-label-icon {
    display: flex;
    align-items: center;
  }

  .interactive-label-text {
    a {
      text-decoration: none;
      color: $purple-3;
    }
  }
}
