.logoWrapper {
  width: 119px;
  height: 144px;
  margin-bottom: 2rem;
}

.actionButton {
  font-size: 14px !important;
  color: $purple-3 !important;
  font-weight: 700 !important;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
