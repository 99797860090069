.finding-exception-status-box-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid var(--Purple-3, #7547dc);
  border-radius: 10px;
  box-shadow: 0px 8px 12px rgba(210, 195, 245, 0.5);
  padding: 25px 20px;

  .finding-exception-status-box-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .finding-exception-status-box-title {
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 588;
      line-height: 13px;
    }

    .finding-exception-status-box-status {
      border-radius: 5px;
      border: 1px solid var(--Gray, #cdd7e2);
      background: var(--White, #fff);
      color: #525252;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
    }
  }

  .finding-exception-status-box-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .finding-exception-status-box-body-description {
      border: 1px solid var(--Grey-5, #ebeaea);
      border-radius: 10px;
      background-color: var(--Grey-7, #f9f9f9);
      padding: 14px 16px;
      color: var(--Grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
    }

    .finding-exceptions-status-box-body-table {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      .finding-exceptions-status-box-body-table-row {
        display: flex;
        gap: 10px;
        align-items: center;

        .finding-exceptions-status-box-body-table-bold-cell {
          color: var(--Grey-3, #7f7f7f) !important;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 13px;
        }

        .finding-exceptions-status-box-body-table-cell {
          color: var(--Grey-1, #3d3d3d);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 13px;
        }
      }
    }
  }

  .finding-exception-status-box-footer {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 20px;

    .base-opus-text-button {
      padding-right: 0;
      margin-right: 0 !important;
      font-weight: 500;
    }
  }
}

.finding-exception-modal-container {
  .common-modal-header {
    .common-modal-header-title-container {
      display: flex;
      align-items: center;
      gap: 0.875rem;

      .common-modal-header-title-icon {
        border-radius: 12px;
        border: 1px solid var(--grey-5, #ebeaea);
        padding: 0.5rem;
      }

      .common-modal-header-title {
        color: #3d3d3d;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }

  .finding-exception-form-container {
    display: flex;
    flex-direction: column;
    gap: 2.625rem;

    .finding-exception-form {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .alert-expand-box-container {
        margin: 0 !important;
      }

      .input-label-wrapper {
        gap: 1.25rem;
      }

      .finding-exception-bold-label {
        color: var(--grey-1, #3d3d3d);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
      }

      .finding-exception-radio-group-container {
        .MuiButtonBase-root {
          padding: 0;
          margin-right: 10px;
        }

        .MuiFormControlLabel-root {
          margin-left: 0;
        }

        .MuiFormControlLabel-label {
          color: var(--grey-1, #3d3d3d);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }

        .MuiTouchRipple-root {
          display: none !important;
        }
      }

      .finding-exception-input-group-container {
        display: grid;
        grid-template-columns: 2fr 5fr;
        gap: 1.6rem;
      }

      .finding-exception-form-breaker {
        height: 1px;
        background: #ebeaea;
      }
    }

    .finding-exception-form-buttons {
      display: flex;
      justify-content: end;
      gap: 1.6rem;
    }
  }
}
