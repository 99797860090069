#button-root {
  border: 1px solid $grey-5;
  border-radius: 6px;
  height: 32px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: $grey-1;
  padding: 8px 11px;
  align-items: center;

  &:disabled {
    color: $grey-4;
    background-color: transparent;

    svg {
      path {
        fill: $grey-4;
      }
    }
  }
}
