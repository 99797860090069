.project-info-widget {
  width: 100%;
  height: 100%;

  .calendar-icon {
    font-size: 17px;
    fill: #3d3d3d;
  }

  .archive-message {
    margin-top: 10px;
  }

  .warning-info {
    max-height: 108px;

    .archive-message,
    .project-timeline {
      text-align: left;
      color: rgb(216, 13, 13);
      font-weight: 700;
      font-size: 12px;
    }
  }

  .project-assignees,
  .business-priority > p {
    font-family: 'HEX Franklin v0.2';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #7f7f7f;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .assignees-container {
    flex-wrap: wrap;
    margin-top: 15px;

    .avatar {
      height: 32px !important;
      width: 32px !important;
      font-size: 15px;
      margin-right: 5px;
      margin-bottom: 5px;
      background: #f5f5f5;
      border: 1px solid #ebeaea;
      border-radius: 200px;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  .business-priority {
    width: 100%;
    display: flex;
    align-items: baseline;
  }

  .project-bussines-priority {
    border-top: 1px solid#e2e2e2;
    border-bottom: 1px solid#e2e2e2;
  }

  .MuiAvatar-root {
    height: 32px !important;
    width: 32px !important;
    font-size: 15px;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .MuiDivider-root {
    width: 70%;
    margin-left: 1.5rem;
    margin-top: 10px;
  }

  .project-info {
    .project-name {
      color: #3d3d3d;
      display: -webkit-box;
      max-width: 400px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }

    .project-timeline,
    .creating-message {
      text-align: left;
      font-weight: 400;
      font-size: 13px;
      color: #3d3d3d;
    }

    .creating-message {
      font-weight: 700;
      color: rgb(216, 13, 13);
    }

    .auto-complete-label-select {
      margin-bottom: 0px !important;
    }
  }
}
