.tab-list-container {
  .tab-list-body.underlined {
    border: none !important;
    overflow: visible !important;

    .tab-list-item {
      background-color: transparent;
      border: none;
      border-radius: 0;
      font-family: 'HEX Franklin v0.2';
      font-style: normal;
      font-weight: 588;
      font-size: 13px;
      line-height: 13px;
      text-align: center;
      color: $grey-3;
      cursor: pointer;

      .tab-list-item-content {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .tab-list-item-active {
      border-bottom: 2px solid $purple-3;
      color: $purple-3;
      padding-bottom: 6px;

      &:hover {
        background: $grey-7;
      }
    }
  }
  svg {
    width: 24px;
  }
  .tab-list-body:not(.underlined) {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid $grey-5;

    .tab-list-item {
      background: $white;
      border: none;
      border-right: 1px solid $grey-5;
      padding: 0.7rem 1.2rem;
      font-style: normal;
      font-weight: 588;
      font-size: 14px;
      line-height: 14px;
      color: $grey-1;
      transition: 100ms;
      cursor: pointer;

      &:hover {
        background: $grey-7;
      }

      &:last-child {
        border: none;
      }
    }

    .tab-list-item-active {
      color: $purple-3;
      background: $purple-6;

      &:hover {
        background: $purple-6;
      }
    }
  }

  .tab-list-container-label {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    color: #7f7f7f;
    padding-right: 11px;
  }

  display: flex;
  flex-direction: row;
  align-items: center;

  .tab-list-item-content {
    p {
      text-transform: capitalize;
      margin: 0;
    }
    .tab-list-item-icon {
      height: 25px;
      width: 25px;
    }
  }
}
