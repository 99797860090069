.resourceIconWrapper {
  width: 2rem;
  height: 1.5rem;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
