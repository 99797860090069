.dynamic-form {
  max-height: 70vh;
  overflow: auto;
}

/* width */
.dynamic-form::-webkit-scrollbar,
.list-panel-textarea::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.dynamic-form::-webkit-scrollbar-track,
.list-panel-textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.dynamic-form::-webkit-scrollbar-thumb,
.list-panel-textarea::-webkit-scrollbar-thumb {
  background: #cdd7e2;
  border-radius: 6px;
}
.timeline-event {
  border: 1px solid #d7d9db;
  border-radius: 7px;
  padding: 14px;
}
.timeline-event-input .list-panel-input {
  width: 96%;
  margin-left: 13px;
}
.list-panel-textarea {
  width: 100%;
  border: 1px solid #bcbec0;
  border-radius: 7px;
  background-color: #f9f9fb;
}
.list-panel-textarea:hover {
  border: 1px solid black;
}

@media screen and (max-height: 1100px) {
  .dynamic-form.fullHeight {
    max-height: 60vh;
  }
  .dynamic-form.limitedHeight {
    max-height: 44vh;
  }
}

@media screen and (min-height: 1100px) and (max-height: 1300px) {
  .dynamic-form.fullHeight {
    max-height: 65vh;
  }
  .dynamic-form.limitedHeight {
    max-height: 55vh;
  }
}
