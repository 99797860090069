.portal-background {
  background: rgb(0, 0, 0, 0.7);
  justify-content: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.portal-container {
  padding: 0;
  border: none;
  position: relative;
  display: flex;
  overflow: hidden;
  background-color: white;

  form {
    display: contents;
  }

  &.default-width {
    width: 50%;
    height: 100%;
  }

  .portal-inner {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .inner-email-modal {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: initial;
  }
}

.drawer-container {
  font-family: 'HEX Franklin v0.2' !important;

  .drawer-paper {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    box-shadow: none !important;
    align-items: stretch;
    overflow: hidden;

    .drawer-paper-buttons {
      position: sticky;
      top: 0;
      padding-top: 2rem;
      padding-right: 0.5rem;
      background-color: transparent;
    }

    .drawer-paper-body {
      flex: 1;
      border-top-left-radius: 16px;
      height: 100%;
      background-color: $white;
      position: relative;
      overflow: hidden;
    }
  }

  .MuiPaper-elevation {
    width: 50%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    min-width: 190px;
  }
}

.action-button-left {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;

  .MuiBackdrop-root {
    display: none !important;
  }

  .MuiPopover-paper {
    background-color: transparent !important;
    overflow: hidden !important;
    box-shadow: none !important;
    height: auto !important;
    max-height: fit-content !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    top: 40px !important;
    left: 115px !important;
    position: absolute;
  }
}

.btn-popover {
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  min-height: 32px !important;
  background: #4d4d4d !important;
  border-radius: 8px !important;
  padding: 8px !important;
}
