.auto-complete-container {
  width: 100%;

  input {
    transition: 200ms;
    &:hover + button + fieldset,
    &:focus + button + fieldset {
      border: 1px solid #7547dc !important;
      outline: none;
    }
  }
}

.auto-complete-root-readonly {
  .auto-complete-expand-button {
    display: none;
  }
}

.auto-complete-label-select {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #0a0b50;
  margin-bottom: 8px !important;
}

.auto-complete-input {
  background-color: #f5f8fd;
  border-radius: 8px !important;
  width: 100% !important;

  .Mui-disabled {
    background: var(--grey-7, #f9f9f9) !important;
    color: var(--grey-4, #c7c4c4) !important;
    border-color: var(--grey-5, #ebeaea) !important;
  }
}

.auto-complete-input > div {
  padding: 6px 8px !important;
  padding-right: 36px !important;
}

.auto-complete-expand-button {
  min-width: 0 !important;
  padding: 0 !important;
  min-height: 0 !important;
  height: 32px !important;
  width: 32px !important;
  z-index: 200 !important;
}

.auto-complete-input fieldset {
  border-radius: 8px !important;
  border: 0;
}

.auto-complete-input input::placeholder {
  color: #a8a8a8;
  opacity: 1;
  text-transform: capitalize;
}

.auto-complete-option-box {
  margin: 8px;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.auto-complete-option-box-single {
  padding: 10px 12px !important;

  .MuiStack-root {
    max-width: 100%;
  }

  .MuiTypography-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
.auto-complete-option-box-disabled {
  opacity: 0.5;
}

.auto-complete-selected-option {
  background: #f2f7fc;
}

.auto-complete-option-box:hover,
.auto-complete-option-box:active {
  background: #f5f5f5;
}
