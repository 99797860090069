.common-chip-container {
  background: $white;
  border: 1px solid $grey-8;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  color: $grey-1;
  padding: 5px 10px;
}
