.base-chart-container {
  background: #ffffff;
  box-shadow: 0px 0px 22px rgba(236, 236, 236, 0.71), inset 0px 1px 4px #ffffff;
  backdrop-filter: blur(8px);
  border-radius: 8px;
  padding: 1.5rem 0;
}

.base-chart-legend-container-column {
  ul {
    display: flex;
    flex-direction: column !important;
    gap: 1rem;

    li {
      span {
        min-width: 70px;
      }
    }
  }
}

.base-chart-legend-container-row {
  width: 100%;
  margin-top: 1rem;

  ul {
    justify-content: center !important;
    display: flex;
    gap: 1rem;

    li {
      span {
        border-radius: 50%;
        width: 12px !important;
        height: 12px !important;
      }

      b {
        color: rgb(102, 102, 102);
        margin: 0px;
        padding: 0px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: $grey-1;
      }
    }
  }
}
