.ticket-name-container {
  display: flex;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: start;
  align-items: center;
  gap: 10px;

  .ticket-icon-container {
    border-radius: 6px;
    border: 1px solid var(--Grey-5, #ebeaea);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
  }
  .ticket-details {
    display: flex;
    flex-direction: column;
    gap: 9px;
    align-items: flex-start;
    justify-content: center;
    a {
      color: var(--Grey-1, #3d3d3d);
      font-family: 'HEX Franklin v0.2';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 100% */
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
    .id {
      color: var(--Grey-3, #7f7f7f);
      font-family: 'HEX Franklin v0.2';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      padding: 0;
      margin: 0;
    }
  }
}
