.common-dialog {
  padding: 29px 32px;
  max-width: inherit !important;
  max-height: inherit !important;

  .dialog-title {
    padding: 0;

    .title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .close-icon-button {
        font-size: 24px;
        color: $grey-4;
        padding: 0;
        margin: auto 0;

        .material-icons {
          font-weight: 900;
        }
      }

      .title {
        font-family: 'HEX Franklin v0.2';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: $grey-1;
        margin-left: 14px;
      }

      .icon-container {
        width: 40px !important;
        height: 40px !important;
        border: 1px solid $grey-5;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 24px;
          height: 25px;
        }
      }
    }
  }

  .dialog-content {
    padding: 0;

    .input-label {
      font-family: 'HEX Franklin v0.2';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: $grey-1;
    }

    .description-container {
      background: #f5f5f5;
      border-radius: 8px;
      padding: 15px;
    }

    .select-input {
      .MuiSelect-select {
        padding: 0;
        min-height: auto;
      }
    }

    .select-input,
    .input-label {
      font-family: 'HEX Franklin v0.2';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: $grey-1;
      margin-bottom: 14px;
    }

    .input-title {
      font-family: 'HEX Franklin v0.2';
      font-style: normal;
      font-weight: 588;
      font-size: 14px;
      line-height: 14px;
      color: $grey-1;
    }

    .input-dialog input::placeholder {
      font-family: 'HEX Franklin v0.2';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: $grey-3;
    }

    .input-dialog input:focus {
      border: '1px solid #6664ED';
    }

    .select-input {
      padding: 0;
      background: $white;
      border: 1px solid $grey-4;
      border-radius: 4px;
      margin: 0;
    }

    .select-input fieldset {
      border: 0 !important;
    }

    textarea {
      margin-bottom: 0 !important;
    }
  }

  .dialog-actions {
    padding: 0px !important;
    gap: 1.6rem;

    button {
      min-width: 100px;
      margin: 0;
    }
  }

  .MuiPaper-root {
    width: 580px;
    box-sizing: border-box;
    background: $white;
    border: 1px solid $grey-6;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 1.5rem !important;
    min-width: inherit !important;
    min-height: inherit !important;
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    .MuiInputBase-root {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 11px 14px;
      background: #ffffff;
      border: 1px solid $grey-4;
      border-radius: 4px;
      height: 40px;
    }

    a {
      color: $purple-3;
      font-family: 'HEX Franklin v0.2';
      font-style: normal;
      font-weight: 588;
    }
  }

  form {
    .MuiGrid-root {
      margin: 0;
    }
  }

  .text-area-field-1 {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 14px !important;

    &:focus {
      border: 1px solid $grey-4 !important;
      outline: none !important;
    }

    &::-webkit-input-placeholder {
      color: $grey-3;
    }
  }

  .email-input-field-1 {
    font-size: 14px;
    line-height: 20px;
    padding: 4px 14px;
    padding: 4px 14px !important;
  }
}

.action-close {
  background: $white !important;
  border: 1px solid $grey-5 !important;
  box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05) !important;
  border-radius: 6px !important;
  color: $grey-1 !important;
}

.action-save,
.action-close {
  text-transform: capitalize !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}
