.automation-management-view-container {
  height: 90vh;
}

.automation-management-table-container {
  padding: 2rem;
  padding-top: 1rem;
  padding-bottom: 0rem;
  height: 100%;

  .automation-management-table-pagination {
    position: absolute;
    width: 100%;
  }

  .MuiDataGrid-footerContainer {
    display: unset;
  }

  .automation-management-table-chip {
    border-radius: 5px;
    background: #f4f7fa;
    border: 1px solid #d4dbe2;
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .automation-management-table-logo {
    height: 25px;
  }

  .automation-management-table {
    border: none;
  }

  .MuiDataGrid-columnHeaders {
    display: none;
  }

  .MuiDataGrid-virtualScroller {
    margin: 0 !important;
  }

  .MuiDataGrid-virtualScrollerRenderZone {
    width: 100% !important;
  }

  .automation-management-table-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #ffffff;
    margin-bottom: 1rem;
    border-radius: 8px;

    .automation-management-table-row-information {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 1rem;
      align-items: center;

      .automation-management-table-row-finding-information {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        width: 70%;

        .automation-management-table-row-finding-information-title {
          width: 25vw;
          font-weight: 700;
        }
      }

      .automation-management-table-row-rules-information {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;

        .automation-management-table-row-rules-counter {
          font-weight: 700;
          font-size: 17px;
        }
      }
    }

    .automation-management-table-row-slide-control {
      position: absolute;
      cursor: pointer;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
    }

    .automation-management-table-row-rules {
      bottom: 0;
      left: 0;
      transition: all 200ms;
      box-sizing: border-box;
      padding: 0rem 1rem;
      border: 1px solid #f5f8fd;

      .automation-management-table-row-button-group-container {
        padding: 1rem 0rem;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #f5f8fd;
        gap: 0.5rem;

        .automation-management-table-row-add-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          user-select: none;
          width: fit-content;
        }
      }

      .automation-management-table-row-rule-container:first-child {
        margin-top: 1rem;
      }

      .automation-management-table-row-rule-container {
        display: grid;
        grid-template-columns: 11fr 1fr;
        background-color: #f5f8fd;
        border-radius: 8px;
        margin-bottom: 1rem;
        padding: 1rem;
        flex-wrap: wrap;
        gap: 1rem;
        white-space: nowrap;

        .automation-management-table-row-rule-selections {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(270px, 2fr));
          gap: 2rem;

          .automation-management-table-row-tag-input input {
            height: 10px;
            background: #ffffff;
            border: 1px solid #cdd7e2;
            border-radius: 5px;
          }

          div {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: center;
          }
        }

        .automation-management-table-row-rule-controls {
          display: flex;
          flex-direction: row;
          gap: 2rem;
          align-items: center;
        }
      }
    }
  }
}

.automation-management-table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;

  .automation-management-table-header-add-rule-button svg {
    margin-right: 0.5rem;
  }

  .automation-management-table-header-delete-button svg {
    margin-right: 0.5rem;
  }

  .automation-management-table-header-delete-button path {
    fill: #6664ed;
  }

  .automation-management-table-header-select {
    display: flex;
    flex-direction: row;
    align-items: center;

    .automation-management-table-header-select-text {
      font-size: 16px;
      color: #748495;
    }
  }

  .automation-management-table-header-button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;

    .automation-management-table-header-button-group-search-input {
      input {
        font-size: 14px;
        line-height: 19px;
        height: 7px;
        min-width: 200px;
      }
    }

    .automation-management-table-header-button-group-search-input
      input::placeholder {
      color: #d4dbe2;
    }

    .automation-management-table-header-button-group-search-input-icon {
      color: #d4dbe2;
      fill: #d4dbe2;
    }
  }
}

.automation-management-remediation-list-container {
  padding: 1rem 0.5rem;

  .automation-management-remediation-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #4e5668;
  }

  .automation-management-remediation-list-item {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.automation-management-rule-row-tooltip {
  background: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.54);
  border-radius: 3px;
}

.main-filters-button {
  margin-right: 1rem;
  box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05) !important;
}

.main-filters-button:not(.selected) {
  border: 1px solid #7547dc !important;

  p {
    color: #7547dc !important;
  }
}

.main-filters-button.selected {
  background: #beace9 !important;
  border: 1px solid #31156f !important;

  p {
    color: #31156f !important;
  }
}
