.sla-chip-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.2rem;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  border-radius: 4px;
  padding: 4px 8px;
}

.sla-chip-overdue {
  color: $red-3;
  background: $red-6;

  svg {
    path {
      fill: $red-3;
    }
  }
}

.sla-chip-on-time {
  color: $green-3;
  background: $green-6;

  svg {
    path {
      fill: $green-3;
    }
  }
}
