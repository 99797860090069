.resource-asset-view-column {
  .ag-header-cell-label {
    justify-content: center;
  }
}

.resource-details-popover {
  .drawer-paper {
    width: 80%;
  }
}

.resource-asset-details-drawer-content-container {
  padding: 15px 31px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  height: 100vh;

  .finding-content-header-actions {
    margin: 0;
  }

  .label-list {
    margin: 0;
  }

  .resource-content-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.4rem;
  }

  .finding-content-header {
    padding-top: 6px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .finding-content-header-elements {
      padding: 6px 12px;
      border-radius: 10px;
      margin: 0 !important;
    }
  }

  .content-section {
    gap: 15px;
  }

  .resource-asset-details-sources-item {
    display: flex;
    align-items: center;
    justify-content: center;

    .application-logo {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .finding-content-sub-section-no-title {
    .content-section-header {
      display: none;
    }
  }

  .resource-asset-details-findings-count {
    color: var(--Grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    font-weight: 588;
    line-height: 14px;
  }

  .resource-asset-details-severity-chip-container {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .resource-asset-details-severity-chip {
      display: flex;
      gap: 7px;
      padding: 3px;
      padding-left: 9px;
      border: 1px solid #ebeaea;
      border-radius: 4px;
      color: var(--Gray-6, #3d3d3d);
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;

      .resource-asset-details-severity-chip-label {
        width: 22px;
        height: 22px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .resource-asset-details-severity-chip-critical {
      .resource-asset-details-severity-chip-label {
        background-color: #ffe1e6;
        color: #761727;
      }
    }

    .resource-asset-details-severity-chip-high {
      .resource-asset-details-severity-chip-label {
        background-color: #ffe1e6;
        color: #e02b4b;
      }
    }

    .resource-asset-details-severity-chip-medium {
      .resource-asset-details-severity-chip-label {
        background-color: #ffe9da;
        color: #ed923c;
      }
    }

    .resource-asset-details-severity-chip-low {
      .resource-asset-details-severity-chip-label {
        background: #dffef5;
        color: #0fae7f;
      }
    }

    .resource-asset-details-severity-chip-informational {
      .resource-asset-details-severity-chip-label {
        background: #e8effc;
        color: #0c5ffd;
      }
    }
  }

  .resource-asset-details-sources-container {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    img {
      width: 17px;
    }
  }
}

.resource-attributes-container {
  display: flex;
  align-items: center;
  gap: 4px;

  .attribute-content-with-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    max-height: 24px;
    border-radius: 4px;
    padding: 5px 7px;
  }

  .attribute-content {
    height: 28px;
    width: 28px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.resource-data-container {
  .tab-list-container {
    height: 32px;

    .tab-list-body:not(.underlined) {
      display: flex;
      height: inherit;
      align-items: center;
    }
  }

  .finding-content-resource-section-body {
    gap: 1rem;
  }
}
