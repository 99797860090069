.resource-owner-header-select-paper {
  .resource-owner-header-menu-item-button {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    svg {
      path {
        fill: #3d3d3d;
      }
    }
  }
  .MuiPaper-root {
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08) !important;
    border-radius: 16px;
    padding: 8px;

    .MuiList-root {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0px;
      max-height: 400px;
      overflow: auto;

      .MuiMenuItem-root {
        padding: 14px;
      }
    }
  }
}

.resource-owner-header-menu-item {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.resource-owner-widget-create-dialog-container {
  * {
    font-family: 'Manrope' !important;
  }

  .common-modal-inner-container {
    gap: 2rem;
    .common-modal-header {
      .common-modal-header-title {
        color: #525252;
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 28px;
        text-transform: capitalize;
      }

      .common-modal-header-close-button {
        path {
          fill: #748495 !important;
        }
      }
    }

    .common-modal-body {
      .input-label-wrapper {
        .input-label {
          font-size: 16px !important;
          font-weight: 700 !important;
          color: #0a0b50 !important;
        }
      }
    }
  }

  .resource-owner-widget-create-form-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    .resource-owner-widget-create-form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .resource-owner-widget-create-form-checkbox-label {
        .MuiTypography-root {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .resource-owner-widget-create-footer {
      display: flex;
      justify-content: space-between;
      align-content: center;

      .resource-owner-widget-create-button-row {
        display: flex;
        justify-content: end;
        gap: 1rem;
      }
    }
  }
}

.resource-owner-widget-container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: $white;
  padding: 1rem 1.5rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  .resource-owner-widget-body-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .resource-owner-widget-header {
    display: flex;
    align-items: center;
    color: #0a0b50;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    white-space: nowrap;
    justify-content: space-between;

    .resource-owner-header-selection {
      display: flex;
      align-items: center;
      width: 70%;
      gap: 0.2rem;

      .resource-owner-header-title {
        font-weight: 700;
      }

      .resource-owner-header-selection-field {
        width: 65%;
        .MuiInputBase-root {
          width: 100%;
        }
        .MuiSelect-select {
          width: 100%;
          padding: 2px !important;
          padding-right: 0.5rem !important;
          font-size: 16px !important;
          font-weight: 400 !important;
          overflow: hidden;
        }

        .MuiOutlinedInput-notchedOutline {
          display: none !important;
        }
      }
    }

    .resource-owner-header-options-button {
      button {
        font-size: 16px;
        font-weight: 700;
        margin-right: 0;
        color: #0a0b50;
        display: flex;
        align-items: center;
        transform: translateX(40%);

        &:hover {
          background: transparent;
        }
        path {
          fill: #0a0b50;
        }

        .MuiTouchRipple-root {
          display: none !important;
        }
      }
    }
  }

  .resource-owner-widget-body {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .resource-owner-widget-body-avatar {
      .MuiBadge-root {
        height: 85px;
        width: 85px;

        .MuiAvatar-root {
          height: 100%;
          width: 100%;
        }

        .resource-owner-widget-check-icon-container {
          width: 32px;
          height: 32px;
          background: $white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.1));
          cursor: pointer;
        }

        .resource-owner-widget-check-icon-container-disabled {
          path {
            fill: #797979 !important;
          }
        }
      }
    }

    .resource-owner-widget-body-details {
      display: flex;
      flex-direction: column;
      gap: 14px;
      max-width: 70%;
      .resource-owner-widget-body-details-row {
        display: grid;
        grid-template-columns: 5fr 6fr;
        gap: 1rem;

        .resource-owner-widget-body-body-cell {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .resource-owner-widget-body-header-cell {
          font-weight: 700;
        }
      }
    }
  }
}
