.horizontal-list-slider {
  width: 10%;
  height: 20px;
  margin: auto;
}

.horizontal-list-slider-thumb {
  width: 10px;
  height: 10px;
  background: #5cc597;
  border-radius: 50%;
  top: -2px;
}

.horizontal-list-slider-mark {
  height: 5px;
  width: 5px;
  background: #cdd7e2;
  border-radius: 50%;
}
