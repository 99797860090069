.no-data-container {
  height: 200px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .MuiGrid-item {
    text-align: center;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 18px;

      .MuiSvgIcon-root {
        color: $grey-4;
        width: 5rem;
        height: 6rem;
      }
    }

    .title {
      span {
        font-weight: 800;
        font-size: 16px;
        color: $grey-1;
      }
    }

    .description {
      padding-top: 8px;

      span {
        font-size: 13px;
        color: $grey-3;
      }
    }
  }
}
