.dashboard-page-container {
  .dashboard-page-body-control-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .chip-wrapper {
      height: 32px;
      background: #ffffff;
    }
  }

  .dashboard-page-content-body {
    padding-bottom: 3rem;
  }

  .dashboard-section-header {
    h5 {
      color: $grey-3;

      span {
        color: $grey-3;
      }
    }
  }

  .operational-dashboard-section-chart-container {
    min-height: 305px;

    chartjs-tooltip {
      background-color: #404040 !important;
      min-width: 50px !important;
      color: $grey-1 !important;
      padding: 5px 15px !important;
      border-radius: 4px;
      z-index: 9999;
      filter: drop-shadow(5px 5px 7px rgba(112, 112, 112, 0.339));

      table {
        width: 100%;

        td {
          display: flex;
          align-items: center;
          justify-content: center;

          .tooltip-label {
            display: none;
          }
        }
      }

      .tooltip-caret {
        border-left: solid 5px transparent;
        border-right: solid 5px transparent;
        border-bottom: solid 5px #404040;
        height: 0;
        width: 0;
      }
    }

    .base-chart-main-container {
      height: 230px;
      max-width: 47vw;

      canvas {
        width: inherit !important;
        max-width: 40vw !important;

        height: 100% !important;
      }
    }
  }

  .operational-dashboard-section-chart-container-stacked-chart {
    .base-chart-main-container {
      height: 190px !important;

      canvas {
        margin-left: 5vw !important;
      }
    }
  }

  .operational-dashboard-section-by-severity-chart-container-analytic-trends {
    height: 340px !important;

    #operational-dashboard-section-by-severity-chart-legend-container {
      margin-top: 1rem;
    }

    .base-chart-main-container {
      height: 220px !important;
      width: 100% !important;
      max-width: 100% !important;

      canvas {
        width: inherit !important;
        height: 100% !important;
      }
    }
  }

  .dashboard-page-body {
    padding: 2rem 1.4rem;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .business-unit-dashboard-section-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .business-unit-dashboard-section-header {
        h5 {
          color: $grey-3;
        }
      }
    }

    .active-campaign-dashboard-section-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .active-campaign-dashboard-section-header {
        h5 {
          color: $grey-3;
        }
      }
    }
  }
}

.dashboard-page-filters {
  .filter-chip {
    padding: 12px !important;
  }

  .dashboard-filter-chip {
    color: var(--Grey-1, #3d3d3d);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 500px;
  }
}
