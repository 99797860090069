.opus-input-label-1 {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #31156f !important;
}

.opus-text-field-1 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #101828;

  div {
    height: 46px;
    background: #f5f8fd;
    border-radius: 8px;
    overflow: hidden;

    fieldset {
      border: none;
      outline: none;
    }

    input {
      transition: 200ms;
      &:hover + fieldset,
      &:focus + fieldset {
        border: 1px solid #7547dc !important;
        outline: none;
      }
    }
  }
}

.opus-text-field-validation-1 {
  div {
    input {
      transition: 200ms;

      & + fieldset {
        border: 1px solid $red-3 !important;
      }

      &:hover + fieldset,
      &:focus + fieldset {
        border: 1px solid $red-3 !important;
        outline: none;
      }
    }
  }
}

.opus-date-field-1 {
  width: 100%;
  background: #f5f8fd;
  border-radius: 8px;
  border: none;
  outline: none;
  min-height: 46px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #101828;
  padding: 0 1rem;
}

.text-field-1 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  padding: 0.7rem;
  border: 1px solid $grey-4;
  border-radius: 4px;
  color: $grey-1;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:focus {
    border: 1px solid $purple-3 !important;
    outline: none !important;
  }

  &::-webkit-input-placeholder {
    color: $grey-3;
  }
}

.text-area-field-1 {
  font-family: 'HEX Franklin v0.2';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  resize: none;
  padding: 0.7rem;
  border: 1px solid $grey-4;
  border-radius: 4px;
  color: $grey-1;
  width: 100%;

  &:focus {
    border: 1px solid $purple-3 !important;
    outline: none !important;
  }

  &::-webkit-input-placeholder {
    color: $grey-3;
  }
}

.input-validation-error-1 {
  border: 1px solid $red-3 !important;

  &:focus {
    border: 1px solid $red-3 !important;
  }
}

.multi-select-field-1 {
  .MuiInputBase-root {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    border: 1px solid $grey-4;
    border-radius: 4px;
    color: $grey-1;
    padding: 0 !important;
    padding: 0.17rem 0.3rem !important;
    width: 100%;
    background: $white !important;

    .MuiChip-label {
      line-height: 22px;
    }

    &:focus {
      outline: 1px solid $purple-3 !important;
    }

    &::-webkit-input-placeholder {
      color: $grey-3;
    }

    fieldset {
      display: none !important;
    }
  }

  .MuiChip-root {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: $grey-1 !important;
  }
}

.multi-select-field-paper-1 {
  font-family: 'HEX Franklin v0.2' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: $grey-1;
  background: #ffffff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
  border-radius: 8px;

  .MuiAutocomplete-listbox {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    li {
      margin: 0px !important;
      border-radius: 4px !important;
      padding: 4px 2px;
      cursor: pointer;
      justify-content: start;
      align-items: center;

      &:hover {
        background-color: $grey-6 !important;
      }

      .MuiTouchRipple-root {
        display: none !important;
      }

      .MuiCheckbox-root {
        &:hover {
          background: transparent !important;
        }
      }
    }

    .auto-complete-selected-option {
      background-color: $grey-6 !important;
    }
  }
}

.auto-complete-end-adornment {
  display: flex;
  align-items: center;
  gap: 5px;

  .auto-complete-clear-icon {
    cursor: pointer !important;
  }
}

.opus-switch-field-1 {
  padding: 0 !important;
  max-height: 24px !important;
  max-width: 42px !important;

  .Mui-disabled {
    .MuiSwitch-track {
      background-color: $grey-2;
    }
  }

  .MuiSwitch-thumb {
    background-color: $white !important;
    margin-left: 0.1rem;
  }

  .MuiButtonBase-root {
    padding: 0 !important;
    top: 9%;
  }

  .MuiSwitch-switchBase {
    .MuiTouchRipple-root {
      display: none !important;
    }
  }

  .Mui-disabled {
    .MuiSwitch-track {
      background-color: $grey-2 !important;
    }
  }
  .MuiSwitch-track {
    border-radius: 20px !important;
    background-color: $grey-2 !important;
  }

  .Mui-checked {
    & + .Mui-disabled {
      .MuiSwitch-track {
        background-color: $grey-2;
      }
    }
    &:not(.Mui-disabled) {
      & + .MuiSwitch-track {
        background-color: $purple-3 !important;
        opacity: 1 !important;
      }
    }

    .MuiSwitch-thumb {
      margin-left: 0 !important;
    }
  }
}

.validation-error-text {
  display: flex;
  gap: 0.7rem;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: $red-3;
}
.select-input {
  padding: 0;
  background: $white;
  border: 1px solid $grey-4 !important;
  border-radius: 4px !important;
  margin: 0;
  height: 40px !important;
}

.select-input fieldset {
  border: 0 !important;
}
