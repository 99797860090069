.risk-customization-container {
  margin: 0 2rem 2rem 2rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.risk-config-item {
  display: flex;
  align-content: center;
  align-items: center;
  border-bottom: 1px solid #ebeaea;
  padding-bottom: 10px;
  &:last-child {
    border: none;
  }
  .risk-config-item-header {
    display: flex;
    align-items: center;
    gap: 12px;

    .icon-container {
      width: 32px;
      height: 32px;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid var(--Gray-2, #e2e2e2);
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;

      svg {
        min-height: 14px;
        min-width: 14px;
        path {
          fill: $grey-1;
        }
      }
    }
    .title-container {
      display: flex;
      flex-direction: row;
      gap: 6px;
      align-items: center;

      .information-badge {
        .body-1 {
          font-weight: 500;
          color: $grey-3;
        }
      }
      .label-4 {
        color: $grey-1;
      }
      .body-1 {
        font-weight: 500;
        color: $grey-3;
      }
    }
  }
  .slider {
    display: flex;
    align-items: center;
    gap: 22px;
    justify-content: space-between;

    .slider-label {
      color: $grey-900;
      margin-bottom: 5px;
    }
    .common-slider-container {
      width: 100% !important;
    }
    .value-container {
      width: 48px;
      height: 32px;
      border-radius: 8px;
      border: 1px solid var(--Grey-5, #ebeaea);
      background: #fff;
      color: $grey-900;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.risk-customization-list-container {
  border-top: 1px solid #ebeaea;

  display: flex;
  flex-direction: column;
  gap: 10px;
  .risk-customization-header {
    padding: 10px 0;
    display: flex;
    gap: 7px;
    flex-direction: row;
    align-items: center;
    color: #3d3d3d;
  }
}
