.onboarding-modal {
  .common-modal-inner-container {
    width: 70% !important;
    height: 90% !important;
    overflow: scroll;

    .common-modal-body {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}

.onboarding-content-container {
  height: 100%;
  width: 100%;
  flex: 1;

  display: flex;
  gap: 1.5rem;

  .onboarding-sidebar {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 30%;

    .onboarding-sidebar-menu-item-disabled {
      background: var(--grey-7, #f9f9f9) !important;
      color: var(--grey-3, #7f7f7f) !important;
      border: 1px solid var(--grey-5, #ebeaea) !important;

      img {
        filter: grayscale(100%);
      }
    }

    .onboarding-sidebar-menu-item-active {
      border: 1px solid var(--grey-1, #3d3d3d) !important;
    }

    .onboarding-sidebar-menu-item {
      display: flex;
      min-width: 200px;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      user-select: none;
      padding: 0.5rem 1rem;
      border: 1px solid var(--grey-5, #ebeaea);
      color: var(--grey-1, #3d3d3d);
      border-radius: 6px;

      .onboarding-sidebar-menu-item-title {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        display: flex;
        gap: 0.2rem;

        .onboarding-sidebar-menu-item-optional-text {
          color: var(--grey-3, #7f7f7f);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .onboarding-sidebar-menu-item-icon {
        border-radius: 12px;
        border: 1px solid var(--grey-5, #ebeaea);
        width: 32px;
        height: 32px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 24px;
          width: 24px;
        }
      }

      .onboarding-sidebar-menu-item-status-indicator {
        margin-left: auto;

        svg {
          path {
            fill: var(--purple-3, #7547dc);
          }
        }
      }
    }
  }

  .onboarding-main-area {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .onboarding-content-area {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      flex: 1;

      .onboarding-integration-form {
        height: 100%;
        display: flex;
        flex-direction: column;

        .integration-modal-content {
          height: 100%;
          gap: 0rem !important;
          margin: 0 !important;

          .integration-modal-content-body {
            max-height: 100% !important;
          }
        }
      }

      .onboarding-cloud-integration-form {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        flex: 1;
        overflow: auto;
      }

      .onboarding-content-buttons {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .onboarding-content-action-buttons {
          display: flex;
          gap: 1rem;
        }
      }

      .onboarding-content-body {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 1.5rem;
        border: 1px solid var(--grey-5, #ebeaea);
        border-radius: 10px;
        height: 90%;
        overflow: auto;

        .onboarding-content-header-area {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;

          .onboarding-content-header-area-title {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: #3d3d3d;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }

          .onboarding-content-header-area-title-icon {
            border-radius: 16px;
            border: 1px solid var(--grey-5, #ebeaea);
            min-width: 40px;
            min-height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 26px;
              height: 26px;
            }
          }

          .onboarding-content-header-area-description {
            color: var(--grey-1, #3d3d3d);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }

      .onboarding-content-initial-screen-body {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .onboarding-content-initial-screen-title {
          display: flex;
          align-items: center;
          gap: 1rem;

          .onboarding-content-initial-screen-title-icon {
            display: flex;
            align-items: center;

            img {
              height: 32px;
            }
          }

          .onboarding-content-initial-screen-title-text {
            color: #3d3d3d;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }
        }

        .onboarding-content-initial-screen-description {
          color: var(--grey-1, #3d3d3d);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }

        .onboarding-content-initial-screen-image {
          svg {
            height: 90%;
            width: 100%;
          }
        }
      }
    }

    .onboarding-content-initial-screen-buttons {
      display: flex;
      justify-content: end !important;
    }
  }
}
