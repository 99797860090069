.threat-intel-widget-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  .threat-intel-widget-text-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .threat-intel-widget-description {
      font-weight: normal;
      color: #000;
    }
  }

  .threat-intel-widget-chart-container {
    padding-left: 3rem;

    .threat-intel-widget-negative-chart {
      background-color: $red-3;
    }

    .threat-intel-widget-positive-chart {
      background-color: $green-3;
    }

    .threat-intel-widget-chart {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .threat-intel-widget-inner-chart {
        width: 110px;
        height: 110px;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1),
          0px 0px 15px 5px rgba(0, 0, 0, 0.1) inset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .threat-intel-widget-score {
          font-size: 30px;
          font-weight: bold;
          line-height: 1.5rem;
        }

        .threat-intel-widget-negative-score {
          color: $red-3;
        }

        .threat-intel-widget-positive-score {
          color: $green-3;
        }

        .threat-intel-widget-total {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}
