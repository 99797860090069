.organization-tree-container {
  min-height: 70vh;
  max-height: 70vh;

  overflow-y: scroll;
  overflow-x: hidden;

  .organization-tree-root {
    width: 99%;
    .expand-icon,
    .collpase-icon {
      color: $grey-3;
    }

    .organization-tree-item-node-focused,
    .organization-tree-item-node-selected {
      background-color: $white !important;
    }

    .MuiTreeItem-content {
      &:hover {
        background-color: inherit !important;
      }

      border: 1px solid $grey-5;
      border-radius: 8px;
      padding: 10px;
      margin: 9px;
      height: 60px;
    }

    .MuiTreeItem-group {
      border-left: 1px solid $grey-5;
    }
  }
}

.organization-modal-container {
  .integration-modal-icon {
    height: 26px;
    margin-bottom: 0;
  }

  .common-modal-header-title-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .common-modal-header-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .common-modal-header-title-icon {
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 16px;
      border: 1px solid var(--grey-5, #ebeaea);
    }
  }

  .organization-modal-button-row {
    position: absolute;
    bottom: 26px;
    right: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;

    .organization-modal-connection-buttons {
      gap: 2rem;
    }
  }
}

.organization-drawer {
  .drawer-paper {
    width: 440px !important;
  }
}

.organization-form-image-container {
  height: 24px !important;
  width: 24px !important;
}
