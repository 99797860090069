.common-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    font-family: 'HEX Franklin v0.2' !important;
  }

  .common-modal-inner-container {
    background: #ffffff;
    border-radius: 8px;
    padding: 1.5rem;
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .common-modal-header {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .common-modal-header-title {
        margin: 0;
      }
    }

    .common-modal-footer {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
  }
}

.common-modal-header-close-button {
  max-height: 31px;

  .common-modal-header-close-icon {
    width: 15px;
    height: 15px;

    path {
      fill: $grey-4;
    }
  }
}
