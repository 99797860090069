.burndown-widget {
  height: inherit;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .campaign-label {
    text-align: initial;
  }

  .campaign-widget-body {
    .base-chart {
      margin-right: auto;
    }
  }

  .base-chart {
    height: 135px !important;
    width: 375px !important;
  }

  .base-chart-legend-container-row {
    ul {
      padding: 0 !important;
    }

    ul > li:last-child > span {
      background: #e2e2e2 !important;
    }

    ul > li:first-child > span {
      background-color: #9870f5 !important;
    }

    span {
      border-radius: 50%;
      height: 10px !important;
      width: 10px !important;
    }

    b {
      font-family: 'HEX Franklin v0.2' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 13px !important;
      line-height: 16px !important;
    }
  }
}
