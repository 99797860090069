.select-chip-group-popover {
  font-family: 'HEX Franklin v0.2' !important;

  .MuiPaper-root {
    box-shadow: none !important;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08) !important;
    border-radius: 4px;
    background: $white;
    min-width: 225px;
    padding: 8px;
    max-height: 350px;
    max-width: 200px;

    .select-chip-category-dropdown-item,
    .select-chip-option-dropdown-item {
      padding: 14px 8px;
      color: var(--gray-6, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 588;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background: $grey-6 !important;
      }
    }

    .select-chip-option-dropdown-item {
      justify-content: start;
      gap: 0.5rem;

      .select-chip-option-dropdown-item-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .MuiTouchRipple-root {
        display: none !important;
      }

      .MuiCheckbox-root {
        padding: 0 !important;
      }
    }
  }
}

.select-chip-group-container {
  border: 1px solid $grey-4;
  border-radius: 4px;
  padding: 0.3rem 0.4rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  .select-chip-container {
    border: 1px solid $grey-5;
    border-radius: 6px;
    padding: 0.4rem 0.6rem;
    background: $grey-7;
    color: $grey-3;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    .select-chip-inner-container {
      display: flex;
      align-items: center;
      gap: 0.2rem;
    }

    .select-chip-icon {
      margin-left: 0.4rem;
      svg {
        width: 10px;
        height: 10px;
        path {
          fill: $grey-3;
        }
      }
    }

    .select-chip-close-icon {
      cursor: pointer;
      margin-left: 0.4rem;
    }

    .select-chip-values,
    .select-chip-label-bold {
      color: var(--grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .select-chip-with-delimiter {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .select-chip-delimiter-text {
      color: var(--grey-1, #3d3d3d);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }
}

.select-chip-option-dropdown-container {
  .select-chip-option-dropdown-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px;

    .select-chip-option-dropdown-back-button {
      cursor: pointer;
    }

    .select-chip-option-dropdown-label {
      margin: auto;
      color: var(--gray-6, #3d3d3d);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }

  .select-chip-option-dropdown-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
}
