.text {
  color: #748495 !important;
  font-size: 16px !important;
}

.selectRows > div {
  padding: 12px 36px 12px 12px !important;
  color: #748495 !important;
  background-color: #f2f7fc !important;
  border-radius: 8px !important;
}

.selectRows > fieldset {
  border: 0 !important;
}

.selectRows span {
  position: absolute;
  right: 8px;
  pointer-events: none;
}
