.campaign-findings-main-container {
  padding: 1rem;
  height: 565px;

  .campaign-findings-main-header {
    margin-bottom: 15px;
    width: 100%;

    .actions-container {
      width: 100%;
      display: block;
      position: relative;
    }
  }

  .campaign-findings-main-table {
    .campaign-findings-table-container {
      max-height: 400px;
      height: 400px;

      .findings-table-view {
        .statusWrapper {
          background: #ffffff;
          border: 1px solid #bdd4ff;
          padding: 0 10px;
          border-radius: 5px;
          height: 32px;

          p {
            color: #024ad4;
          }
        }
      }
    }
  }
}
