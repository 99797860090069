.filter-panel-container {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .filter-panel-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .filter-panel-header-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.8rem;

      .filter-panel-header-title-icon-button {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        background: #ece7f9;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .filter-panel-header-title-text {
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        line-height: 31px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #31156f;
      }
    }

    .filter-panel-header-controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      .filter-panel-header-controls-apply-button {
        width: 75px;
        height: 44px;
        background: #7547dc;
        border: 1px solid #31156f;
        box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05);
        border-radius: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
      }

      .filter-panel-header-controls-close-button {
        width: 40px;
        height: 44px;
        border: 1px solid #ffffff;
        box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05);
        border-radius: 8px;
      }
    }
  }
}
