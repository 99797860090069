@import './workflow-builder-layout.scss';
@import './workflow-builder-nodes.scss';
@import './workflow-builder-edges.scss';
@import './workflow-builder-popovers.scss';
@import './workflow-builder-panels.scss';
@import './workflow-builder-buttons.scss';
@import './workflow-builder-header.scss';

.workflow-builder-handle {
  width: 12px !important;
  height: 12px !important;
  background-color: #c3aff2 !important;
  z-index: 200 !important;
  border: 2px solid #ffffff !important;
  box-shadow: 0px 0px 6px rgba(155, 181, 206, 0.48) !important;
}

.workflow-diagram-basic-edge-container {
  background: #ffffff !important;
  text-align: center;
  border: 1px dashed #7547dc;
  border-radius: 20px;
  font-weight: bold;
  color: #7547dc;
  overflow: hidden;
  padding: 0rem 0.5rem;

  .plus-button {
    box-shadow: none !important;
  }
}

.workflow-diagram-condition-edge-container {
  position: relative;
  overflow: visible;
}

.workflow-diagram-edge-label-delete-button {
  background-color: white !important;
  border: 1px solid #7547dc !important;
  height: 24px;
  width: 24px;

  svg {
    path {
      fill: #7547dc;
    }
  }
}

.workflow-diagram-condition-edge-label-delete-button {
  position: absolute !important;
  right: -10px;
  top: -5px;
}

.workflow-container {
  position: relative;

  .workflow-run-button {
    display: flex;
    align-items: center;
    gap: 6px;
    position: absolute;
    z-index: 200;
    padding: 16px;
    top: 10%;
    left: 1%;
    color: var(--Grey-1, #3d3d3d) !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    border-radius: 6px;
    border: 1px solid var(--Grey-5, #ebeaea);
    background: var(--White, #fff);
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: #ffffff;
    }
  }

  .workflow-run-button-disabled {
    color: var(--Grey-1, #7f7f7f) !important;

    .MuiTouchRipple-root {
      display: none !important;
    }

    svg {
      path {
        fill: var(--Grey-1, #7f7f7f);
      }
    }
  }
}
