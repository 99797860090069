.iac-related-findings {
  max-height: 548px;

  .title {
    font-weight: 700;
  }

  .findings-list {
    flex-direction: column;
    overflow: scroll;
    gap: 0.7rem;
  }
}
