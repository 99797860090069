.user-org-node-label-container {
  display: flex;
  align-items: center;

  .user-org-node-label-text {
    .node-label-container {
      display: flex;
      align-items: center;
      gap: 10px;

      .label-1 {
        color: $grey-1;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
      }

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: $grey-3;
        }
      }
    }
  }
}
