.active-campaign-carousel-card-container {
  background: $white;
  border: 1px solid $grey-5;
  border-radius: 8px;
  padding: 0.9rem 0.7rem;
  min-height: 175px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;

  .active-campaign-carousel-card-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    font-family: 'HEX Franklin v0.2';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    min-height: 21px;
    /* or 150% */

    color: #3d3d3d;
  }
  h5 {
    margin: 0;
    padding: 0;
  }

  .active-campaign-carousel-card-completion-rate-container {
    width: 100%;
    padding-bottom: 6px;
  }

  .active-campaign-carousel-card-completion-rate-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'HEX Franklin v0.2';
    gap: 0.3rem;
    width: 100%;

    .active-campaign-carousel-card-completion-rate {
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 15px;
      text-align: center;
      color: #3d3d3d;
    }

    .active-campaign-carousel-card-completion-text {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 13px;
      color: #3d3d3d;
    }
  }

  .active-campaign-carousel-card-completion-rate-indicator-container {
    width: 100%;
    min-height: 16px;
    background-color: $grey-6;
    border-radius: 4px;
    position: relative;
    overflow: hidden;

    .active-campaign-carousel-card-completion-rate-inner-indicator-container {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .active-campaign-carousel-card-completion-rate-positive-indicator-container {
    .active-campaign-carousel-card-completion-rate-inner-indicator-container {
      background-color: $green-4;
    }
  }

  .active-campaign-carousel-card-completion-rate-negative-indicator-container {
    .active-campaign-carousel-card-completion-rate-inner-indicator-container {
      background-color: $red-4;
    }
  }

  .active-campaign-carousel-card-days-remaining-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4rem;
    width: 100%;

    .active-campaign-carousel-card-days-counter {
      font-family: 'HEX Franklin v0.2';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $grey-1;
      padding: 0.2rem 0.4rem;
      border: 1px solid $grey-5;
      border-radius: 4px;
    }
  }
}
