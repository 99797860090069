.no-data-backdrop-container {
  height: 100%;
  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .no-data-backdrop-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    .no-data-backdrop-icon {
      svg {
        path {
          fill: $grey-4;
        }
      }
    }

    .no-data-backdrop-description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: $grey-3;
    }
  }
}
