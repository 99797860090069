.assets-tab-list-container {
  .tab-list-body {
    display: flex;
    align-items: center;
    gap: 10px;

    .tab-list-item {
      height: 40px;
      display: flex;
      gap: 10px;
      align-items: center;
      border: 1px solid #e2e2e2;
      border-radius: 8px;
      padding: 6px 10px;
      background-color: $white;
      cursor: pointer;

      .tab-label {
        display: flex;
        align-items: flex-end;
        gap: 1px;
      }

      &.tab-list-item-active {
        background-color: var(--Purple-6, #ece7f9);
        border: 1px solid var(--Purple-3, #7547dc);
        color: var(--Purple-3, #7547dc);

        path {
          fill: var(--Purple-3, #7547dc);
        }
      }
    }
  }
}
