.user-organization-selection {
  display: flex;
  align-items: center;

  .action-button {
    border-radius: 6px;
    border: 1px solid $grey-5;
    background: $white;
    box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
    min-width: 40px;
    height: 40px;
    padding: 10px 16px !important;

    svg {
      width: 15px;
      height: 18px;

      path {
        fill: $grey-1;
      }
    }
  }

  .chip-group-container {
    border: 1px solid $grey-4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 40px;

    .common-simple-chip {
      margin-right: 8px;
    }

    .expand-button {
      path {
        fill: $grey-1;
      }
    }
  }
}

.user-org-tree-selectable-paper {
  width: 421px;
  padding: 8px;
}
