.risk-page-actions-container {
  position: relative;
}

.risk-actions {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  background: $purple-6;
  border-radius: 6px;
  padding: 0.1rem 1rem;

  .risk-actions-buttons {
    display: flex;
    flex-direction: row;
    margin-right: 3rem;
  }

  .risk-actions-selected-items {
    font-style: normal;
    font-weight: 588;
    font-size: 14px;
    line-height: 14px;
    color: $grey-1;
  }

  .action-base-button {
    font-style: bold;
    font-weight: 588;
    font-size: 14px;
    line-height: 17px;
    color: $purple-3;

    svg {
      width: 17px;
      height: 16px;
      path {
        fill: $purple-3 !important;
      }
    }

    &:hover {
      background: transparent !important;
    }

    .MuiTouchRipple-root {
      display: none !important;
    }

    &:disabled {
      color: $purple-5 !important;

      svg {
        path {
          fill: $purple-5 !important;
        }
      }
    }
  }

  .action-cancel-button {
    position: absolute;
    padding: 0 !important;
    right: 0;
  }
}

.actions-list {
  .risk-action-base-button {
    font-size: 14px;
    border-radius: 0;
    padding: 0 !important;
    padding-left: 0.5rem !important;
    padding-right: 2rem !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: $grey-1;

    svg {
      max-width: 24px;
      max-height: 24px;

      path {
        fill: $grey-1;
      }
    }

    &:hover {
      background-color: transparent !important;
    }

    .MuiTouchRipple-root {
      display: none !important;
    }
  }

  .azure-devops-menu-item-icon {
    height: 23px;
    width: 23px;
  }

  .MuiPaper-root {
    font-family: 'HEX Franklin v0.2';
    background: $white;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: $grey-6;
  }
}

.risk-action-response-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .risk-action-response-title-container {
    padding: 8px;
    margin-bottom: 10px;
    background: $grey-6;
    border-radius: 4px;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;

    .risk-action-response-title-space {
      display: inline-block;
      width: 1px;
    }

    .risk-action-response-title-note {
      font-weight: 700;
    }
  }

  .risk-action-response-list-container {
    display: flex;
    flex-direction: column;

    .risk-action-response-list-title {
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 14px;
      color: $grey-1;
    }

    .risk-action-response-list-items {
      max-height: 35vh;
      overflow: auto;

      .risk-action-response-list-item {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: $grey-1;
        max-width: 35vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid $grey-5;
        padding: 1.2rem 0;
      }
    }
  }

  .risk-action-response-list-buttons {
    margin-top: 1rem;
    display: flex;
    flex-direction: row-reverse;

    .action-response-base-button {
      background: $purple-3 !important;
      border: 1px solid $purple-3 !important;
      box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05) !important;
      border-radius: 6px !important;
      font-family: 'HEX Franklin v0.2' !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 14px !important;
      line-height: 17px !important;
      color: #ffffff !important;
    }
  }
}

.risk-actions-buttons-menu {
  button {
    &:disabled {
      svg {
        path {
          fill: rgba(0, 0, 0, 0.26);
        }
      }
    }
  }
}
