.code-event-workflow {
  height: 152px;
  .react-flow {
    background-color: $grey-7;
    border: 1px solid $grey-5;
    border-radius: 10px;
    max-height: 152px;
  }

  .iacNode {
    background-color: $white;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));
    border-radius: 10px;
    border: 1px solid $grey-5;
    min-height: 40px;
    display: flex;
    align-items: center;
    gap: 5px;

    .iac-node-label-group {
      display: flex;
      flex-direction: column;

      .iac-node-label {
        color: var(--Grey-1, #3d3d3d);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .iac-node-sub-label {
        color: var(--Grey-3, #7f7f7f);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .iacIcon {
      margin: auto;
    }

    .resourceIconWrapper {
      margin: 'auto';
    }

    .nodeHandle {
      background: transparent;
      border: none;
      height: 0;
      width: 0;
    }

    .bug-count {
      display: flex;
      align-items: center;
      gap: 3px;
      padding: 5px var(--2, 8px);
      height: 24px;
      color: $red-3;
      background-color: $red-6;
      border-radius: 4px;

      path {
        fill: $red-3;
      }
    }
  }
}
