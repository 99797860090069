.content-section {
  display: flex;
  flex-direction: column;

  .content-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .MuiAccordionSummary-root {
    min-height: 0 !important;
  }
}

.content-section.collapsable-section {
  .MuiAccordionSummary-content {
    margin: 0 !important;
    min-height: 0 !important;
  }
}

.content-section-title {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
