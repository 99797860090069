@mixin sort-icon-style {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px;
  width: 10px;

  .ag-icon {
    &::before {
      content: '';
    }
  }
}

@mixin checkbox-icon-style {
  background-repeat: no-repeat;
  background-size: cover;
}

.common-simple-data-grid-container {
  --ag-alpine-active-color: #7547dc !important;
  --ag-checkbox-unchecked-color: #c7c4c4 !important;
  --ag-checkbox-indeterminate-color: #7547dc !important;
  --ag-selected-row-background-color: #f9f9f9 !important;
  --ag-row-hover-color: #f9f9f9 !important;
  --ag-border-color: #ffffff !important;
  --ag-secondary-border-color: #ebeaea !important;
  --ag-header-background-color: #f9f9f9 !important;
  --ag-odd-row-background-color: #ffffff !important;
  --ag-icon-size: 15px !important;
  --ag-row-height: 54px !important;
  --ag-header-height: 40px !important;
  --ag-font-size: 14px !important;
  --ag-font-family: 'HEX Franklin v0.2' !important;

  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .common-simple-data-grid-body {
    height: 100%;
    position: relative;

    .common-simple-data-grid-visibility-control {
      position: absolute;
      top: 1.7rem;
      right: 1.6rem;
      z-index: 50;
      height: 100%;
      height: 2.2rem;
      width: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background-color: #f9f9f9;
    }
  }

  p {
    line-height: 20px;
  }

  .column-disable-sort {
    .ag-sort-indicator-container {
      display: none !important;
    }
  }

  .common-checkbox {
    padding: 0 !important;

    input,
    div {
      height: 15px !important;
      width: 15px !important;
    }

    .MuiTouchRipple-root {
      display: none !important;
    }
  }

  .ag-root-wrapper {
    padding: 1.5rem;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
  }

  .ag-checkbox-input-wrapper {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  .ag-header-viewport {
    background: #f9f9f9 !important;
    border: 1px solid #ebeaea !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;

    .ag-header-cell {
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 13px !important;
      line-height: 13px !important;
      color: #3d3d3d !important;
    }

    .ag-icon {
      color: #b5b5b5 !important;
    }

    .advanced-data-grid-visibility-control {
      .ag-header-cell-comp-wrapper {
        display: flex;
        justify-content: end;
      }
    }
  }

  .ag-nested-row {
    height: max-content !important;
  }

  .ag-row {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 14px !important;
    color: #3d3d3d !important;

    .ag-cell {
      outline: none !important;
      border: none !important;
      display: flex;
      align-items: center;

      .cell-text-renderer {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .ag-cell-wrapper {
        text-overflow: ellipsis;
      }

      .item-with-icon-component {
        height: 100%;
        width: inherit;
      }

      .common-icon-cell-container {
        width: 100%;
        flex-wrap: nowrap !important;
      }

      .avatar-chip-icon {
        display: flex;
        align-items: center;
      }
    }
  }
}

.ag-header-row {
  .ag-header-cell:last-child {
    .ag-header-cell-resize {
      right: 25px !important;
      z-index: 200;
    }
  }
}

.nested-common-data-grid-container {
  padding-left: 2rem;
  padding-bottom: 0rem !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 400px !important;

  .ag-root-wrapper {
    padding: 0rem !important;
    border: none !important;
  }

  .ag-header-viewport {
    background: #ffffff !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }
}

.ag-overlay-wrapper {
  z-index: 200;
}

.ag-sort-indicator-container {
  height: 1rem;
  padding-left: 0.3rem;
}

.ag-sort-none-icon {
  background-image: url('../icons/sort-group-icon.svg');
  @include sort-icon-style;

  &.ag-hidden {
    display: block !important;
  }
}

:not(.ag-hidden) {
  .ag-sort-ascending-icon {
    background-image: url('../icons/sort-ascending-icon.svg');
    @include sort-icon-style;
  }

  .ag-sort-descending-icon {
    background-image: url('../icons/sort-descending-icon.svg');
    @include sort-icon-style;
  }

  & ~ .ag-sort-none-icon {
    &.ag-hidden {
      display: none !important;
    }
  }
}

.ag-checkbox-input-wrapper {
  width: 16px;
  height: 16px;
  background-image: url('../icons/unchecked-checkbox.svg');
  border-radius: 0 !important;

  &::after {
    display: none !important;
  }

  @include checkbox-icon-style;
}

.ag-checkbox-input-wrapper.ag-indeterminate {
  background-image: url('../icons/indeterminate-checkbox.svg');
  @include checkbox-icon-style;
}

.ag-checkbox-input-wrapper.ag-checked {
  background-image: url('../icons/checked-checkbox.svg');
  @include checkbox-icon-style;
}

.data-grid-navigable-cell {
  position: absolute !important;
  width: 100% !important;
  padding: 0 !important;
  z-index: 200;
  background: transparent;

  & > a {
    width: 100%;
    height: 100%;
  }
}

div[col-id='DATA_GRID_CHECKBOX_CELL'],
.common-icon-cell-container {
  z-index: 300;
}

.ag-center-cols-container {
  width: 100%;
}

.ag-row {
  width: 100%;
}

.ag-row-selected {
  background: #f9f9f9 !important;
}

.common-advanced-data-grid-row {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  border-bottom: 1px solid #ebeaea;

  .common-advanced-data-grid-cells {
    position: relative;
    left: 0;
    height: 3rem;

    .common-advanced-data-grid-cell {
      transition: all 300ms ease-in-out !important;

      .common-checkbox {
        padding: 0 !important;

        input,
        div {
          height: 15px !important;
          width: 15px !important;
        }

        .MuiTouchRipple-root {
          display: none !important;
        }
      }
    }
  }

  .common-advanced-data-grid-cells {
    &:hover {
      background: #f9f9f9;
    }
  }

  .common-advanced-data-grid-expanded-area {
    flex: 1;
    padding-bottom: 1rem;
    background-color: $white !important;
    position: sticky;
    width: 100%;
    left: 0;

    .common-simple-data-grid {
      .ag-root-wrapper {
        padding: 0;
        border: none;
        padding-left: 2.2rem;
      }

      .ag-header-viewport {
        background: $white !important;
        border: none !important;
        border-bottom: 1px solid #ebeaea !important;

        .ag-checkbox-input-wrapper {
          display: none !important;
        }
      }

      .ag-row {
        border-bottom: 1px solid #ebeaea !important;
        width: 100%;
        min-width: 100vw;
      }

      .ag-center-cols-container {
        min-width: 100% !important;
      }
    }

    .common-simple-data-grid-pagination {
      padding-left: 2.2rem;
    }

    .common-simple-data-grid-visibility-control {
      top: 0;
      right: 0;
      background: $white;
    }
  }
}

.common-advanced-data-grid-container {
  .ag-full-width-container {
    .ag-row {
      display: block;
    }

    .ag-row-selected {
      &:before {
        display: none !important;
      }

      .common-advanced-data-grid-cells {
        background: #f9f9f9;
      }

      .common-advanced-data-grid-expanded-area {
        .ag-row-selected {
          background: #f9f9f9;
        }
      }
    }
  }
}

.root-cause-grid-container {
  .ag-center-cols-container {
    min-width: 100%;

    .data-grid-navigable-cell {
      width: 95% !important;
    }
  }
}

.ag-full-width-row {
  border: none !important;
}
