.settings-page-body-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .settings-page-body-table {
    .MuiBox-root:first-child {
      margin-top: 0;
    }

    .sla-rules-list {
      max-height: 68vh !important;
      overflow-y: scroll;
    }
  }

  .settings-page-body-control-row {
    margin: 1.5rem 2rem;

    .tab-list-body {
      border: none !important;
      overflow: visible !important;

      .tab-list-item {
        background-color: transparent;
        border: none;
        border-radius: 0;
        font-family: 'HEX Franklin v0.2';
        font-style: normal;
        font-weight: 588;
        font-size: 13px;
        line-height: 13px;
        text-align: center;
        color: $grey-3;
      }

      .tab-list-item:disabled {
        color: $grey-4;
        font-weight: 400;
      }

      .tab-list-item-active {
        border-bottom: 2px solid $purple-3 !important;
        color: $purple-3;
      }
    }
  }

  .settings-header-button {
    &:disabled {
      color: $grey-4 !important;
      background-color: $grey-6 !important;
      border: 0 !important;
    }
  }
}

.settings-header {
  min-height: 64px;
  height: auto !important;
  display: flex;
  align-items: center;

  .settings-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    direction: row;

    .settings-header-filters {
      display: flex;
      align-items: center;
    }
  }
}
