.common-warning-modal-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .common-warning-modal-body-description {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $grey-1;
  }

  .common-warning-modal-body-buttons {
    justify-self: end;
    align-self: flex-end;

    button {
      padding: 0.7rem 2rem !important;
      border-radius: 6px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;

      &:nth-child(2) {
        background-color: $purple-2;
        color: $white !important;

        &:hover {
          background-color: $purple-2 !important;
          color: $white !important;
        }
      }
    }
  }
}
