.base-opus-button {
  padding: 10px 16px !important;
  border: 1px solid #d4dbe2 !important;
  border-radius: 8px !important;
  background: #ffffff !important;
  box-sizing: border-box !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 24px !important;
}

.secondary-opus-button {
  color: #ffffff !important;
  background: #6664ed !important;
  border: none !important;
}

.alternative-opus-button {
  border: 1px solid #5cc597 !important;
  color: #5cc597 !important;
}

// New button design styling

.opus-primary-button {
  padding: 10px 16px !important;
  height: 40px !important;
  background: #7547dc !important;
  box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05) !important;
  border-radius: 8px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  color: #ffffff !important;

  &:disabled {
    color: #c7c4c4 !important;
  }
}

.opus-secondary-button {
  padding: 10px 16px !important;
  height: 40px !important;
  background: #ffffff !important;
  border: 1px solid #7547dc !important;
  box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05) !important;
  border-radius: 8px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  color: $purple-3 !important;
}

.opus-filter-button {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 10px 16px !important;
  border: 1px solid #ffffff !important;
  box-shadow: 0px 1px 2px rgba(198, 228, 246, 0.05) !important;
  border-radius: 6px !important;

  &:hover,
  &.active {
    background: $purple-6 !important;
  }
}

.add-filter-btn {
  margin-right: 10px !important;

  &::before {
    content: none !important;
    position: relative;
  }
}

.base-opus-text-button {
  margin-right: 10px !important;
  color: $purple-3 !important;

  svg {
    path {
      fill: $purple-3 !important;
    }
  }

  &:hover {
    background-color: transparent !important;
  }

  &:disabled {
    color: #c7c4c4 !important;
  }

  .MuiTouchRipple-root {
    display: none !important;
  }
}

.common-button {
  padding: 10px 16px !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  border-radius: 6px !important;
  display: flex;
  gap: 0.3rem;
}

.common-button-primary {
  color: var(--white, #fff) !important;
  background: var(--purple-3, #7547dc) !important;
  box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05) !important;
}

.common-button-outline-primary {
  border: 1px solid var(--purple-3, #7547dc) !important;
  color: var(--purple-3, #7547dc) !important;
}

.common-button-secondary {
  border: 1px solid var(--grey-5, #ebeaea) !important;
  background: var(--white, #fff) !important;
  box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05) !important;
}

.common-button-text {
  color: var(--purple-3, #7547dc) !important;
  padding: 0 !important;
  max-height: 1rem !important;

  &:hover {
    background: transparent !important;
  }

  .MuiTouchRipple-root {
    display: none;
  }
}

.button-primary {
  min-width: 110px !important;
  height: 40px !important;
  border-radius: 6px !important;
  background: var(--Purple-3, #7547dc) !important;
  box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05) !important;
  color: var(--White, #fff) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.button-secondary {
  min-width: 96px !important;
  height: 40px !important;
  border-radius: 6px !important;
  border: 1px solid var(--Grey-5, #ebeaea) !important;
  background: var(--White, #fff) !important;
  box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05) !important;
  color: var(--Grey-1, #3d3d3d) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}
