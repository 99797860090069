.flow-page-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .flow-page-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 24px;
    gap: 22px;

    .flow-page-body-filters {
      border-radius: 8px;
      border: 1px solid #e2e2e2;
      background: var(--white, #fff);
      padding: 12px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .flow-page-body-filters-end-buttons {
        display: flex;
        align-items: center;
        gap: 10px;

        .flow-page-body-filters-import-button {
          border-radius: 6px;
          border: 1px solid var(--Grey-5, #ebeaea);
          background: var(--White, #fff);
          box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 13px;
            width: 12px;

            path {
              fill: var(--Grey-1, #3d3d3d);
            }
          }
        }

        .flow-page-body-filters-add-button {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 10px 16px !important;

          svg {
            height: 15px;
            width: 14px;

            path {
              fill: #ffffff;
            }
          }
        }
      }
    }

    .flow-page-body-table {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 27px;

      .common-icon-cell-icon {
        margin-right: 8px;

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
      }

      .flow-page-table-actions-cell {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 10px;
        width: 100%;

        button {
          border-radius: 6px;
          border: 1px solid var(--Grey-5, #ebeaea);
          background: var(--White, #fff);
          box-shadow: 0px 1px 2px 0px rgba(198, 228, 246, 0.05);
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 13px;
            width: 12px;

            path {
              fill: var(--Grey-1, #3d3d3d);
            }
          }
        }
      }
    }
  }
}
