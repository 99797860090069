.boxMenu {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.MuiTabsScrollButtons {
  color: #ffffff80 !important;
}

.tab {
  padding: 0 16px !important;
  min-height: 0 !important;
  margin-bottom: 24px !important;
  /* background-color: pink !important; */
  height: 65px !important;
}

.profilePhoto {
  margin-bottom: 2rem !important;
  display: flex;
  justify-content: center;
}

.svgIconLink path {
  fill: #9670ef;
}

.svgIconLink {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0;
}

.iconLink {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.menuIconText {
  font-family: 'HEX Franklin v0.2';
  font-style: normal;
  font-weight: 588;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #9670ef;
  margin-top: 8px;
}

.helpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 81px;
}

.userMenuButton {
  height: 35px !important;
  width: 35px !important;
}

.selectedInfo {
  width: 4px;
  background-color: pink;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
