.opus-autocomplete-container {
  width: 100%;

  .opus-autocomplete-single-value-container {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.opus-autocomplete-container input {
  height: 8px;
}

.opus-autocomplete-body {
  padding: 0.1rem 1.5rem;

  .opus-autocomplete-search-input {
    background: #f2f7fc;
    border-radius: 7px;
    width: 100%;
  }

  .opus-autocomplete-search-input input {
    height: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #748495;
    width: 100%;
  }
}

.opus-autocomplete-option-list {
  padding: 0.5rem 0rem;

  .opus-autocomplete-option-list-item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 100ms;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
  }

  .opus-autocomplete-option-list-item:hover {
    background: #f2f7fc;
  }
}

.opus-autocomplete-input-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  .opus-autocomplete-input-label {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
}

.opus-autocomplete-chip {
  padding: 4px 8px;
  background: #ffffff !important;
  border: 1px solid #cdd7e2 !important;
  border-radius: 5px !important;
  max-width: 70px !important;
}

.opus-autocomplete-select {
  height: 46px;
  background: #ffffff;
  border: 1px solid #cdd7e2;
  border-radius: 5px;
  width: 100%;
}
