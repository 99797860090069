.campaign-widget-container {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  font-family: 'HEX Franklin v0.2';

  .campaign-widget-title {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: $grey-1;
    text-align: left;
    font-size: 16px;
  }

  .campaign-widget-body {
    height: 100%;
  }
}

#campaign-sla-breakdown-widget-legend-container,
#campaigns-tickets-per-person-legend-container {
  margin-top: 1rem;

  ul {
    li {
      span {
        border-radius: 50%;
        width: 12px !important;
        height: 12px !important;
      }

      b {
        color: rgb(102, 102, 102);
        margin: 0px;
        padding: 0px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: $grey-1;
      }
    }
  }
}
