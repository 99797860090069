.dropzone-uploader-container {
  // min-height: 300px;
  .dropzone {
    border-radius: 8px;
    border: 2px dashed var(--Grey-6, #f5f5f5);
    background: var(--Grey-7, #f9f9f9);
    padding: 20px;
    text-align: center;
    transition: background-color 0.3s ease;
    .dropzone-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .base-opus-button {
        color: $grey-1;
      }
    }
  }
  .dropzone.drag-active {
    background-color: #f0f0f0;
  }
  .files {
    list-style-type: none;
    padding: 0;
  }
  .files li {
    margin-bottom: 10px;
  }
}
