.step-control-popover-button-row {
  display: flex;
  align-items: center;
  padding: 3px;
  button {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 16px !important;
    padding: 10px 0;

    div {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}

.step-control-popover {
  width: 300px;
  position: relative;
  overflow: visible !important;
  background: #ffffff;
  border: 1px solid var(--Grey-5, #ebeaea);
  border-radius: 16px !important;
  box-shadow: none !important;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.step-control-popover-button-icon {
  border-radius: 12px;
  border: 1px solid var(--Purple-5, #c3aff2);
  background: var(--Purple-6, #ece7f9);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: var(--Purple-3, #7547dc);
    }
  }
}

.step-control-popover-button-text {
  color: var(--Grey-1, #3d3d3d);
  font-family: 'HEX Franklin v0.2';
  font-size: 16px;
  font-style: normal;
  font-weight: 588;
  line-height: 14px;
  padding: 0;
  margin: 0;
}

.step-control-popover-root {
  transform: translateY(1%);
}

.step-control-popover::after {
  content: '';
  position: absolute;
  right: 50%;
  top: -14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 14px 8px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 9998;
  transform: translateX(50%);
}
