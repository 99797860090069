.stakeholders-group {
  .icon-box-helper-text {
    display: none;
  }

  .common-avatar-group-container {
    display: flex;
    align-items: center;

    .common-avatar-container,
    .avatar-container {
      height: 32px;
      width: 32px;
    }

    .common-avatar-container {
      cursor: pointer;
      border-radius: 200px;
      border: 1px solid var(--Grey-5, #ebeaea);
      background: var(--Grey-7, #f9f9f9);

      p {
        color: var(--Grey-3, #7f7f7f);
        font-family: 'HEX Franklin v0.2';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
      }
    }
  }
}
.more-avatar-tooltip-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
}
