.criticality-chip-container {
  font-family: 'HEX Franklin v0.2';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  border: 1px solid $green-5;
  color: $green-3;
  background: $green-6;
  padding: 5px 6px;
  border-radius: 4px;
}

.criticality-chip-low {
  border: none;
  color: $green-3;
  background: $green-6;
}

.criticality-chip-medium {
  border: none;
  color: $orange-3;
  background: $orange-6;
}

.criticality-chip-high {
  border: none;
  color: $red-3;
  background: $red-6;
}

.criticality-chip-critical {
  color: $red-2;
  background: $red-6;
  border: none;
}
