.settings-user-dialog {
  .MuiDialog-container {
    min-width: 678px !important;
  }
  .mutlti-select-user-roles {
    padding: 1px !important;
  }

  .scope-selector-container {
    display: flex;
    align-items: center;
    border: 1px solid $grey-5;
    margin-top: 20px;
    border-radius: 8px;
    padding: 6px;
  }
}
