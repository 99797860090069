.business-priority-chip {
  padding: 5px 6px !important;
  max-width: fit-content !important;
  border-radius: 4px !important;
  height: 24px !important;

  .MuiChip-label {
    font-family: 'HEX Franklin v0.2';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    padding: 0 !important;
  }
}
